<template>
  <a-modal
    v-model='visible'
    :on-cancel='close'
    :title='title'
    :bodyStyle="{ padding: '0px', maxHeight: '600px', minHeight: '200px' }"
    :dialogStyle="{ maxWidth: '450px' }"
    class='news-modal'
  >
    <div class='padding-x-sm padding-top-sm'>
      <div class='row'>
        <a-input
          v-model='searchTitle'
          addon-before='제목'
          class='margin-bottom-sm col'
          @pressEnter='getNews'
        />
        <a-button
          class='col-auto margin-left-sm'
          type='primary'
          @click='getNews'
          :loading='loading'
          :disabled='loading'
        >
          검색
        </a-button>
      </div>
    </div>

    <div
      class='padding-x-sm padding-bottom-sm font-bold'
      :style="{
        borderBottom: '1px solid #d9d9d9',
        fontSize: '12.5px',
      }"
    >
      전체 {{ newsList.length }}
    </div>
    <div class='overflow-auto' :style="{ maxHeight: '340px' }">
      <a-list item-layout='horizontal' :data-source='newsList'>
        <a-list-item
          slot='renderItem'
          slot-scope='item'
          class='padding-none'
          @click='getNewsInfo(item)'
        >
          <span class='text-center padding-sm' style='width: 50px'>
            {{ item.id }}
          </span>
          <span class='text-center padding-sm'>
            <a-avatar
              :src='item.thumbnail'
              :size='50'
              shape='square'
              :style="{ height: '40px', width: '60px' }"
            />
          </span>
          <span class='padding-x-sm col padding-sm'> {{ item.title }} </span>
        </a-list-item>
      </a-list>
      <a-pagination
        :disabled='loading'
        v-model='pagination.current'
        :total='pagination.total'
        :defaultPageSize='pagination.limit'
        size='small'
        class='text-center margin-y-sm'
      />
    </div>

    <template slot='footer'>
      <a-row>
        <a-col :span='24' :style="{ textAlign: 'right' }">
          <a-button key='back' @click='close'>닫기</a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'News',
  props: {
    modal: Boolean
  },
  watch: {
    modal(v) {
      this.reset();

      this.visible = v;
      if (v) {
        this.getNews();
      }
    },
    visible(v) {
      if (!v) {
        this.$emit('close');
      }
    },
    'pagination.current'() {
      this.getNews();
    }
  },
  computed: {
    title() {
      return `TDI NEWS 선택`;
    }
  },
  data() {
    return {
      visible: false,
      newsList: [],
      searchTitle: '',
      loading: false,
      pagination: {
        current: 1,
        total: 1,
        limit: 20
      }
    };
  },
  methods: {
    reset() {
      this.newsList = [];
      this.searchTitle = '';
      this.loading = false;
      this.pagination = {
        current: 1,
        total: 1,
        limit: 20
      };
    },
    getNews() {
      const me = this;
      this.loading = true;

      let params = {
        page: me.pagination.current,
        page_rows: me.pagination.limit,
        title: me.searchTitle ?? ''
      };

      try {
        me.$axios
          .get('trends/tdi-news', {
            params: params
          })
          .then(res => {
            let rawData = res.data;

            this.pagination.total = rawData.total;
            this.pagination.current = rawData.current_page;

            this.newsList = rawData.data ?? [];
            this.loading = false;
          });
      } catch (error) {
        this.sendLoading = false;
      }
    },
    getNewsInfo(item) {
      this.$emit('set-news-info', item);
      this.close();
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.news-modal .ant-list-items > .ant-list-item {
  cursor: pointer;
}

.news-modal .ant-list-items > .ant-list-item:hover {
  background: #fff1f0;
}

.news-modal .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid rgb(240, 240, 240);
}

.news-modal .ant-list-items > .ant-list-item > span:first-child {
  border-right: 1px solid rgb(240, 240, 240);
}
</style>
