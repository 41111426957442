<template>
  <div>
    <div v-if="isMobile">
      <!-- 모바일 -->
      <a-layout-header :style="{ padding: '0 20px' }">
        <div :style="{ float: 'left' }">
          <img
            :src="require('../../assets/img/datadragon_w.png')"
            :width="130"
            alt="logo"
          />
        </div>

        <a-space :style="{ float: 'right' }">
          <a-switch
            v-model="themeToggle"
            checked-children="다크모드 해제"
            un-checked-children="다크모드"
            :style="{ marginRight: '10px' }"
          />

          <a-dropdown :trigger="['click']">
            <a-avatar icon="user" @click="e => e.preventDefault()" />
            <a-menu slot="overlay">
              <a-menu-item
                key="0"
                @click="$router.push('/config').catch(() => {})"
              >
                <span><a-icon type="user" />설정</span>
              </a-menu-item>
              <a-menu-item key="1" @click="logout">
                <span><a-icon type="logout" />로그아웃</span>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </a-space>
      </a-layout-header>
      <div :style="{ textAlign: 'center' }">
        <!-- TODO : 모바일 메뉴 -->
      </div>
    </div>

    <a-layout>
      <a-layout-sider
        v-if="!isMobile"
        width="200"
        :style="{ position: 'fixed', height: '100%', overflowY: 'scroll' }"
      >
        <div class="logo">
          <img
            :src="require('../../assets/img/' + logo + '.png')"
            :width="130"
            alt="logo"
            :style="{ marginBottom: '10px' }"
          />
          <a-tag> 관리자 </a-tag>
        </div>

        <a-menu
          v-model="menu"
          mode="inline"
          theme="dark"
          id="dd-menu"
          :default-selected-keys="['1']"
          :default-open-keys="['sales', 'data']"
        >
          <a-menu-item-group key="management">
            <span slot="title">
              <a-icon type="file-search" />
              <span class="dd-menu-group-title">관리</span>
            </span>

            <a-menu-item
              key="/user"
              @click="$router.push('/user').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">사용자 관리</span>
            </a-menu-item>

            <a-menu-item
              key="/notice"
              @click="$router.push('/notice').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">공지사항 관리</span>
            </a-menu-item>

            <a-menu-item
              key="/contact"
              @click="$router.push('/contact').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">문의사항 관리</span>
            </a-menu-item>

            <a-menu-item
              key="/trend/request"
              @click="$router.push('/trend/request').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">트렌드 요청 목록</span>
            </a-menu-item>

            <a-menu-item
              key="/trend/news"
              @click="$router.push('/trend/news').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">트렌드 게시물 관리</span>
            </a-menu-item>

            <a-menu-item
              key="/consulting"
              @click="$router.push('/consulting').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">컨설팅 신청 목록</span>
            </a-menu-item>

            <a-menu-item
              key="/subscription/reason"
              @click="$router.push('/subscription/reason').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">구독취소 사유</span>
            </a-menu-item>

            <a-menu-item
              key="/mailing"
              @click="$router.push('/mailing').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">메일링</span>
            </a-menu-item>

            <a-menu-item
              key="/account/transaction"
              @click="$router.push('/account/transaction').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">계좌거래 관리</span>
            </a-menu-item>
          </a-menu-item-group>

          <a-menu-item-group key="sales" :style="{ marginTop: '20px' }">
            <span slot="title">
              <a-icon type="line-chart" />
              <span class="dd-menu-group-title">매출</span>
            </span>

            <a-menu-item
              key="/sales"
              @click="$router.push('/sales').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">매출 통계</span>
            </a-menu-item>
            <a-menu-item
              key="/sales/detail"
              @click="$router.push('/sales/detail').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">매출 상세내역</span>
            </a-menu-item>
          </a-menu-item-group>

          <a-menu-item-group key="category" :style="{ marginTop: '20px' }">
            <span slot="title">
              <a-icon type="deployment-unit" />
              <span class="dd-menu-group-title">카테고리</span>
            </span>

            <a-menu-item
              key="/mapping/config"
              @click="$router.push('/mapping/config').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">검색 그룹 관리</span>
            </a-menu-item>

            <a-menu-item
              key="/tdi/category"
              @click="$router.push('/tdi/category').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">카테고리 관리</span>
            </a-menu-item>

            <a-menu-item
              key="/poi/group"
              @click="$router.push('/poi/group').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">POI 그룹 관리</span>
            </a-menu-item>
          </a-menu-item-group>

          <a-menu-item-group key="data" :style="{ marginTop: '20px' }">
            <span slot="title">
              <a-icon type="project" />
              <span class="dd-menu-group-title">데이터</span>
            </span>

            <a-menu-item
              key="'/brand/sales"
              @click="$router.push('/brand/sales').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">브랜드 매출 관리</span>
            </a-menu-item>

            <a-menu-item
              key="/hotplace"
              @click="$router.push('/hotplace').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">HOT플레이스 관리</span>
            </a-menu-item>

            <a-menu-item
              key="/trend/campaign"
              @click="$router.push('/trend/campaign').catch(() => {})"
            >
              <span :style="{ color: '#fff' }">트렌드 캠페인 관리</span>
            </a-menu-item>
          </a-menu-item-group>
        </a-menu>
      </a-layout-sider>

      <a-layout
        :style="{ width: '100%', padding: isMobile ? '0' : '0 0 0 200px' }"
      >
        <a-layout-header class="dd-layout-header" v-if="!isMobile">
          <div :style="{ textAlign: 'right' }">
            <a-switch
              v-model="themeToggle"
              checked-children="다크모드 해제"
              un-checked-children="다크모드"
            />

            <a-button
              type="primary"
              ghost
              size="small"
              :style="{ marginLeft: '20px' }"
              @click="$router.push('/config').catch(() => {})"
            >
              설정
            </a-button>
            <a-button
              type="primary"
              size="small"
              @click="logout"
              :style="{ marginLeft: '10px' }"
            >
              로그아웃
            </a-button>
          </div>
          <!-- <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          /> -->
        </a-layout-header>

        <a-layout-content
          :style="{
            maxWidth: wide ? '100%' : '1920px',
            width: '100%',
            margin: '0 auto',
            padding: '20px',
            minHeight: 'calc(100vh - 64px - 69px)',
          }"
        >
          <router-view />
        </a-layout-content>

        <a-layout-footer style="text-align: center">
          © TDI. All Rights Reserved.
        </a-layout-footer>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import themeUtil from '../../utils/themeUtil';

export default {
  computed: {
    ...mapState('setting', ['theme', 'palettes']),
    ...mapGetters({
      screen: 'setting/getScreen',
      isMobile: 'setting/isMobile',
      wide: 'setting/getWide',
    }),
    logo() {
      // return this.theme.mode === 'night' ? 'datadragon_w' : 'datadragon';
      return 'datadragon_w';
    },
  },
  data() {
    return {
      themeToggle: false,
      menu: [],
    };
  },
  watch: {
    themeToggle(v) {
      this.setTheme({
        color: this.theme.color,
        mode: v ? 'night' : 'dark',
      });
    },
    'theme.mode': function (val) {
      // let closeMessage = this.$message.loading('테마가 변경되었습니다.');
      themeUtil.changeThemeColor(this.theme.color, val);
      localStorage.setItem('theme-mode', val);
    },
    menu: function (val) {
      if (val[0] === '/') {
        this.menu = ['/user'];
      }
    },
  },
  mounted() {
    this.menu = [this.$route.path];
    this.themeToggle = this.theme.mode === 'dark' ? false : true;
  },
  methods: {
    ...mapMutations('setting', ['setTheme']),
    ...mapActions({
      logoutAction: 'account/logout',
    }),

    logout() {
      this.logoutAction().then(() => {
        this.$router.push('/login');
      });
    },

    setThemeColor(color) {
      this.color = color;
      this.setTheme({
        color: color,
        mode: this.themeToggle ? 'night' : 'dark',
      });
    },
  },
};
</script>

<style scoped>
.logo {
  margin: 25px;
}

.dd-menu-group-title {
  font-size: 12px;
  margin-left: 5px;
}

#dd-menu .ant-menu-item {
  font-size: 14px;
  margin-bottom: 0;
  color: #fff !important;
}

.dd-layout-header {
  background: #fff;
  padding: 0 20px;
}
</style>
