<template>
  <div>
    <a-card :title='title' class='margin-top-md'>
      <div slot='extra' />

      <div class='margin-bottom-md'>
        <custom-filter :page='page' @search='search' />

        <div class='row justify-center'>
          <div class='col-grow'>
            <a-alert
              :message="'검색결과 : ' + filters.pagination.total + ' 개'"
              type='success'
              showIcon
              :style="{ maxWidth: '300px' }"
            />
          </div>

          <div class='col-auto self-center'>
            <a-button type='primary' @click='openModal()'>
              이메일 발송
            </a-button>
          </div>
        </div>
      </div>

      <custom-table
        :table-list='data'
        :columns='columns'
        :pagination='filters.pagination'
        :loading='loading'
        :action-list="['detail']"
        @pagination-change='onPaginationChanged'
        @open-modal='openModal'
      />
    </a-card>

    <form-modal
      :modal='modal'
      :mode='mode'
      :data='modalData'
      :coalition-option='coalitionOption'
      :group-option='mailGroupOption'
      :status-option='statusOption'
      @index='getMailing'
      @close='modal = false'
    />
  </div>
</template>

<script>
import FormModal from './modals/Form/Index';
import { mapGetters } from 'vuex';
import { getOptions, getOptionByKey } from '@/utils/options';
import CustomTable from '@/views/components/CustomTable';
import CustomFilter from '@/views/components/CustomFilter';

export default {
  components: {
    FormModal,
    CustomTable,
    CustomFilter
  },
  computed: {
    ...mapGetters({
      isMobileDevice: 'setting/isMobileDevice',
      getFilters: 'filters/getStateFilters'
    }),
    title() {
      return '메일링 관리';
    },
    filters() {
      return this.getFilters(this.page);
    }
  },
  data() {
    return {
      loading: false,
      mode: '',
      modal: false,
      modalData: {},
      data: [],
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          scopedSlots: { customRender: 'id' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '서비스사',
          dataIndex: 'coalition',
          key: 'coalition',
          scopedSlots: { customRender: 'info_obj' },
          align: 'center',
          ellipsis: true,
          width: 100
        },
        {
          title: '제목',
          dataIndex: 'title',
          key: 'title',
          align: 'center',
          ellipsis: false
        },
        {
          title: '발송그룹',
          dataIndex: 'group_info',
          key: 'group_info',
          scopedSlots: { customRender: 'info_obj_text' },
          align: 'center',
          ellipsis: false,
          width: 150
        },
        {
          title: '발송상태',
          dataIndex: 'status_info',
          key: 'status_info',
          scopedSlots: { customRender: 'info_obj' },
          align: 'center',
          ellipsis: false,
          width: 150
        },
        {
          title: '발송일',
          dataIndex: 'created_at',
          key: 'created_at',
          scopedSlots: { customRender: 'created_at' },
          align: 'center',
          ellipsis: true,
          width: 150
        },
        {
          title: '기능',
          dataIndex: 'actions',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
          align: 'center',
          ellipsis: false,
          width: 150
        }
      ],
      coalitionOption: getOptions('coalitionOption', true),
      statusOption: getOptions('statusOption', true),
      mailGroupOption: getOptions('mailGroupOption'),
      page: 'mailing'
    };
  },
  mounted() {
    this.getMailing();
  },
  methods: {
    /** 페이지 변경시 **/
    onPaginationChanged(pagination) {
      this.filters.pagination.current = pagination ? pagination.current : this.filters.pagination.current;
      this.search();
    },
    /** 검색 **/
    search(filterData) {
      if (filterData && this.filters.pagination.current !== 1) {
        this.filters.pagination.current = 1;
        return;
      }

      this.getMailing();
    },
    /** 메일링 목록 조회 **/
    getMailing() {
      this.loading = true;

      let params = {
        ...this.filters,
        page: this.filters.pagination.current,
        page_rows: this.filters.pagination.limit
      };

      try {
        this.$axios.get('mailing', { params: params })
          .then((res) => {
            let rawData = res.data;

            this.filters.pagination.total = rawData.total;
            this.filters.pagination.current = rawData.current_page;

            this.data = rawData.data.map((item, index) => {
              return {
                key: index.toString(),
                ...item,
                user_email: item.user ? item.user.email : '-', // 회원 - 이메일
                coalition_name: item.user ? item.user.coalition_name : '-', // 서비스사
                coalition: getOptionByKey(this.coalitionOption, item.user.coalition_name),
                status_info: getOptionByKey(this.statusOption, item.status),
                group_info: getOptionByKey(this.mailGroupOption, item.group),
                date_format: 'YYYY년 MM월 DD일'
              };
            });
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },
    /** 모달 띄우기 **/
    openModal(record = null) {
      this.mode = record ? 'view' : 'edit';
      this.modalData = record;
      this.modal = true;
    }
  }
};
</script>
