const OptionObj = {
  statusOption: [
    {
      id: 'waiting',
      text: '대기',
      color: 'cyan'
    }, {
      id: 'pending',
      text: '보류',
      color: 'orange'
    }, {
      id: 'completed',
      text: '완료',
      color: 'green'
    }, {
      id: 'failed',
      text: '실패',
      color: 'red'
    }
  ],
  categoryOption: [
    {
      id: 'place',
      text: '플레이스',
      color: 'blue'
    }, {
      id: 'app',
      text: 'APP',
      color: 'blue'
    }
  ],
  coalitionOption: [
    {
      id: 'TDI',
      text: 'TDI',
      color: 'blue'
    }, {
      id: 'adego',
      text: 'adeGo',
      color: 'orange'
    }
  ],
  contactCategoryOption: [
    {
      id: 'payment',
      text: '결제 관련',
      color: 'volcano'
    }, {
      id: 'account',
      text: '계정 관련',
      color: 'cyan'
    }, {
      id: 'tax',
      text: '세금계산서 관련',
      color: 'orange'
    }, {
      id: 'data',
      text: '데이터 관련',
      color: 'blue'
    }, {
      id: 'other',
      text: '기타',
      color: ''
    }
  ],
  mailGroupOption: [
    {
      id: 'total',
      text: '전체',
      color: 'cyan'
    }, {
      id: 'mailing',
      text: '메일링 수신회원',
      color: 'orange'
    }, {
      id: 'subscriber',
      text: '구독자',
      color: 'green'
    }
  ],
  trendCategoryOption: [
    {
      id: 'O',
      text: 'OPEN ADS',
      color: 'orange'
    }, {
      id: 'T',
      text: 'TDI NEWS',
      color: 'cyan'
    }, {
      id: 'D',
      text: 'DATA DRAGON',
      color: 'blue'
    }
  ],
  transactionStatusOption: [
    {
      id: 'W',
      text: '잔행중',
      color: 'cyan'
    }, {
      id: 'P',
      text: '보류',
      color: 'orange'
    }, {
      id: 'S',
      text: '입금 확인(완료)',
      color: 'green'
    }, {
      id: 'C',
      text: '취소',
      color: 'red'
    }
  ],
  regionsOption: [
    { id: '서울', text: '서울' },
    { id: '경기', text: '경기' },
    { id: '부산', text: '부산' },
    { id: '인천', text: '인천' },
    { id: '강원', text: '강원' },
    { id: '경남', text: '경남' },
    { id: '경북', text: '경북' },
    { id: '울산', text: '울산' },
    { id: '전남', text: '전남' },
    { id: '전북', text: '전북' },
    { id: '제주', text: '제주' },
    { id: '충남', text: '충남' },
    { id: '충북', text: '충북' },
    { id: '대구', text: '대구' },
    { id: '광주', text: '광주' },
    { id: '전주', text: '전주' },
    { id: '세종', text: '세종' },
    { id: '대전', text: '대전' }
  ]
};

// isAll : 전체 포함 여부
function getOptions(optionName, isAll = false) {
  let res = [];
  if (isAll) {
    res.push({
      id: '',
      text: '전체'
    });
  }

  if (!OptionObj[`${optionName}`]) return res;

  return [...res, ...OptionObj[`${optionName}`]];
}

/**
 * option 목록에서 해당하는 option return
 * optionList : 조회할 object options
 * data: 검색할 data
 * key: option에서 비교할 key, 기본 'id'
 */
function getOptionByKey(optionList, findData, findKey = 'id') {
  const defaultObj = { id: '', text: '-' };

  if (!optionList) return defaultObj;

  const findObj = optionList.find(
    item => item[findKey].toUpperCase() === findData.toUpperCase()
  );
  return findObj ?? defaultObj;
}

module.exports = {
  getOptions,
  getOptionByKey
};
