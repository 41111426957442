var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"on-cancel":_vm.close,"width":'80%',"title":_vm.title,"bodyStyle":{ position: 'relative' },"dialogStyle":{ maxWidth: '1300px' }},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(_vm.modal)?_c('a-form-model',_vm._b({ref:"form",attrs:{"layout":"horizontal","model":_vm.formData,"rules":_vm.rules}},'a-form-model',_vm.formItemLayout,false),[_c('a-descriptions',{attrs:{"title":"기본 정보"}}),_c('a-row',[_c('a-col',{attrs:{"sm":{ span: 24 },"md":{ span: 14 }}},[_c('hot-place-map',{attrs:{"polygon":_vm.formData.polygon},on:{"change-polygon-path":_vm.onPolygonPathChange}})],1),_c('a-col',{staticClass:"row-full-items",attrs:{"sm":{ span: 24 },"md":{ span: 9, offset: 1 }}},[_c('a-form-model-item',{attrs:{"prop":"name"}},[_c('a-input',{style:({
              display: 'inline-block',
              width: '100%',
              maxWidth: '450px',
            }),attrs:{"addon-before":"HOT 플레이스명"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('a-form-model-item',{attrs:{"prop":"category"}},[_c('a-input-group',{staticClass:"row items-top",style:({
              display: 'inline-block',
              width: '100%',
              maxWidth: '450px',
            })},[_c('span',{staticClass:"ant-input-group-addon col-auto",style:({
                width: 'auto',
                height: '32px',
                paddingTop: '3px',
              })},[_vm._v(" 카테고리 ")]),_c('a-select',{staticClass:"col-grow",attrs:{"placeholder":"선택"},model:{value:(_vm.formData.category),callback:function ($$v) {_vm.$set(_vm.formData, "category", $$v)},expression:"formData.category"}},_vm._l((_vm.filteredCategoryOption),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)],1)],1),_c('a-form-model-item',{attrs:{"prop":"region"}},[_c('a-input-group',{staticClass:"row items-top",style:({
              display: 'inline-block',
              width: '100%',
              maxWidth: '450px',
            })},[_c('span',{staticClass:"ant-input-group-addon col-auto",style:({
                width: 'auto',
                height: '32px',
                paddingTop: '3px',
              })},[_vm._v(" 지역 ")]),_c('a-select',{staticClass:"col-grow",attrs:{"placeholder":"선택"},model:{value:(_vm.formData.area),callback:function ($$v) {_vm.$set(_vm.formData, "area", $$v)},expression:"formData.area"}},_vm._l((_vm.filteredRegionOption),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)],1)],1)],1)],1)],1):_vm._e(),_c('template',{slot:"footer"},[_c('a-row',[_c('a-col',{style:({ textAlign: 'left' }),attrs:{"span":12}},[(!!_vm.formData.id)?_c('a-popconfirm',{attrs:{"visible":_vm.confirm,"title":"해당 정보를 삭제하시겠습니까 ? 삭제시 복구가 불가능합니다.","ok-text":"삭제","cancel-text":"취소"},on:{"confirm":_vm.deletePlace,"cancel":function($event){_vm.confirm = false}}},[_c('a-button',{attrs:{"type":"danger","ghost":"","loading":_vm.loading},on:{"click":function($event){_vm.confirm = true}}},[_vm._v(" 삭제 ")])],1):_vm._e()],1),_c('a-col',{style:({ textAlign: 'right' }),attrs:{"span":12}},[_c('a-button',{key:"back",on:{"click":_vm.close}},[_vm._v("닫기")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.modeText)+" ")])],1)],1)],1),(_vm.loading)?_c('div',{staticClass:"fit dim"},[_c('a-spin')],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }