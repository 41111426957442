<template>
  <div>
    <div :style="{ marginBottom: '1rem', textAlign: 'right' }">
      <a-button :style="{ marginRight: '10px' }" @click='visible = true'>
        비밀번호 변경
      </a-button>

      <a-modal
        title='비밀번호 변경'
        :visible='visible'
        :confirm-loading='loading'
        @ok='setPasswordChange'
        @cancel='close'
      >
        <template slot='footer'>
          <a-button key='back' @click='close()'> 닫기</a-button>
          <a-button key='submit' type='primary' :loading='loading'>
            변경
          </a-button>
        </template>

        <a-input-password addon-before='변경할 비밀번호' v-model='password' />
      </a-modal>

      <a-popconfirm
        title='이메일 인증메일을 재전송 하시겠습니까?'
        ok-text='전송'
        cancel-text='취소'
        @confirm='sendEmailVerify'
      >
        <a-button :loading='emailVerifyLoading'>
          이메일 인증메일 재발송
        </a-button>
      </a-popconfirm>
    </div>

    <a-descriptions title='기본정보' bordered>
      <a-descriptions-item label='권한'>
        {{ user.permission }}
      </a-descriptions-item>

      <a-descriptions-item label='가입일' :span='2'>
        {{ createdAt }}
      </a-descriptions-item>

      <a-descriptions-item label='이메일'>
        {{ user.email }}
      </a-descriptions-item>

      <a-descriptions-item label='이름' :span='2'>
        {{ user.name }}
      </a-descriptions-item>

      <a-descriptions-item label='회사명'>
        {{ user.company_name }}
      </a-descriptions-item>

      <a-descriptions-item label='사업자등록번호' :span='2'>
        {{ user.company_license }}
      </a-descriptions-item>

      <a-descriptions-item label='담당자명'>
        {{ user.manager_name }}
      </a-descriptions-item>

      <a-descriptions-item label='담당자 연락처' :span='2'>
        {{ user.manager_phone }}
      </a-descriptions-item>
    </a-descriptions>

    <a-descriptions title='메모' :style="{ marginTop: '3rem' }" />

    <a-textarea
      v-model='user.memo'
      placeholder='메모를 입력하세요.'
      :rows='4'
    />

    <a-button
      :style="{ marginTop: '10px' }"
      @click='setMemo'
      :loading='loading'
    >
      메모 저장
    </a-button>
  </div>
</template>

<script>
export default {
  props: {
    user: Object
  },
  computed: {
    createdAt() {
      return this.$moment(this.user.created_at).format('YYYY년 MM월 DD일');
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      emailVerifyLoading: false,
      password: '',
      memo: '',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 }
    };
  },
  watch: {
    user(v) {
      this.memo = v.memo;
    }
  },
  methods: {
    close() {
      this.visible = false;
    },
    setMemo() {
      let params = {
        user_id: this.user.id,
        memo: this.user.memo
      };

      this.loading = true;

      try {
        this.$axios.put('memo', params).then(res => {
          if (res.status === 200) {
            this.$notification['success']({
              message: '메모 저장 완료',
              description: res.data.message
            });
          }

          this.loading = false;
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    setPasswordChange() {
      let params = {
        id: this.user.id,
        password: this.password
      };

      this.loading = true;

      try {
        this.$axios.put('passwordChange', params).then(res => {
          if (res.status === 200) {
            this.$notification['success']({
              message: '비밀번호 변경 완료',
              description: res.data.message
            });
          }

          this.loading = false;
          this.close();
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    sendEmailVerify() {
      let params = {
        user_id: this.user.id
      };

      this.emailVerifyLoading = true;

      try {
        this.$axios.post('user/mail/verify', params).then(res => {
          if (res.status === 200) {
            this.$notification['success']({
              message: '이메일 인증메일 전송 완료',
              description: res.data.message
            });
          }

          this.emailVerifyLoading = false;
        });
      } catch (error) {
        this.emailVerifyLoading = false;
        console.log(error);
      }
    }
  }
};
</script>
