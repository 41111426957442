<template>
  <div>
    <a-card :title='title' :style="{ marginTop: '20px' }">
      <div slot='extra'></div>

      <div class='margin-bottom-md'>
        <custom-filter :page='page' @search='search' />

        <a-row>
          <a-col :span='12'>
            <a-alert
              :message="'검색결과 : ' + filters.pagination.total + ' 개'"
              type='success'
              showIcon
              :style="{ width: '300px' }"
            />
          </a-col>
          <a-col :span='12' :style="{ textAlign: 'right' }">
            <a-button type='primary' @click='openModal()'>
              등록
            </a-button>
          </a-col>
        </a-row>
      </div>

      <custom-table
        :table-list='data'
        :columns='columns'
        :pagination='filters.pagination'
        :loading='loading'
        :action-list="['detail']"
        @pagination-change='onPaginationChanged'
        @open-modal='openModal'
        @send-maile='sendMail'
      />
    </a-card>

    <form-modal
      :modal='modal'
      :data='modalData'
      :mode='mode'
      @index='getNotices'
      @close='modal = false'
    />
  </div>
</template>

<script>
import FormModal from './modals/Form/Index';
import { mapGetters } from 'vuex';
import CustomTable from '../components/CustomTable';
import CustomFilter from '@/views/components/CustomFilter';

export default {
  props: {
    type: String
  },
  components: { FormModal, CustomTable, CustomFilter },
  computed: {
    ...mapGetters({
      isMobileDevice: 'setting/isMobileDevice',
      getFilters: 'filters/getStateFilters'
    }),
    title() {
      return '공지사항 관리';
    },
    filters() {
      return this.getFilters(this.page);
    }
  },
  data() {
    return {
      loading: false,
      sendLoading: false,
      modal: false,
      data: [],
      modalData: {},
      mode: '',
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          align: 'center',
          width: 50,
          ellipsis: true
        },
        {
          title: '서비스사',
          dataIndex: 'company',
          key: 'company',
          align: 'center',
          width: 250,
          scopedSlots: { customRender: 'company' },
          ellipsis: true
        },
        {
          title: '제목',
          dataIndex: 'title',
          key: 'title',
          align: 'center',
          width: 250,
          ellipsis: true
        },
        {
          title: '부제목',
          dataIndex: 'sub_title',
          key: 'sub_title',
          align: 'center',
          width: 250,
          ellipsis: true
        },
        {
          title: '사용여부',
          dataIndex: 'use',
          key: 'use',
          align: 'center',
          scopedSlots: { customRender: 'use' },
          ellipsis: true
        },
        {
          title: '등록일',
          dataIndex: 'created_at',
          key: 'created_at',
          align: 'center',
          width: 300,
          ellipsis: true
        },
        {
          title: '기능',
          dataIndex: 'actions',
          key: 'actions',
          align: 'center',
          scopedSlots: { customRender: 'actions' },
          width: 150,
          ellipsis: true
        },
        {
          title: '구독자 메일전송',
          dataIndex: 'mail',
          key: 'mail',
          align: 'center',
          scopedSlots: { customRender: 'mail' },
          width: 150,
          ellipsis: true
        }
      ],
      page: 'notice'
    };
  },
  mounted() {
    this.getNotices();
  },
  methods: {
    /** 페이지 변경시 **/
    onPaginationChanged(pagination) {
      this.filters.pagination.current = pagination ? pagination.current : this.filters.pagination.current;
      this.search();
    },
    /** 검색 **/
    search(filterData) {
      if (filterData && this.filters.pagination.current !== 1) {
        this.filters.pagination.current = 1;
        return;
      }

      this.getNotices();
    },
    getNotices() {
      this.loading = true;

      let params = {
        ...this.filters,
        page: this.filters.pagination.current,
        page_rows: this.filters.pagination.limit
      };

      try {
        this.$axios.get('notice', { params: params }).then(res => {
          let rawData = res.data;

          this.filters.pagination.total = rawData.total;
          this.filters.pagination.current = rawData.current_page;

          this.data = rawData.data.map((item, index) => {
            return {
              key: index.toString(),
              id: item.id,
              company: item.company,
              icon_title: item.icon_title,
              name: item.name,
              contents: item.contents,
              sub_title: item.sub_title,
              title: item.title,
              type: item.type,
              use: item.use,
              user_id: item.user_id,
              created_at: this.$moment(item.created_at).format(
                'YYYY년 MM월 DD일'
              )
            };
          });

          this.loading = false;
        });
      } catch (error) {
        console.log(error);
      }
    },

    openModal(record = null) {
      this.mode = record ? 'edit' : 'store';
      this.modalData = record;
      this.modal = true;
    },

    sendMail(record) {
      this.sendLoading = true;

      let params = {
        title: record.title,
        sub_title: record.sub_title
      };

      try {
        this.$axios.get('notice/mail', { params: params }).then(res => {
          let rawData = res.data;

          let type = 'error';
          let message = '전송 실패';
          let description = '메일 전송이 실패하였습니다.';

          if (rawData.success) {
            type = 'success';
            message = '전송 완료';
            description = '메일 전송이 완료되었습니다.';
          }

          this.$notification[type]({
            message: message,
            description: description
          });

          this.sendLoading = false;
        });
      } catch (error) {
        this.sendLoading = false;
      }
    }
  }
};
</script>
