import Vue from 'vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import App from './App.vue';
import axios from 'axios';
import moment from 'moment';
import router from './router';
import store from './store';
import _ from 'lodash';
import 'moment/locale/ko';

Vue.config.productionTip = false;

Vue.use(Antd);

Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios;
Vue.prototype._ = _;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common['Authorization'] =
  'Bearer ' + localStorage.getItem('token');

store.dispatch('account/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
});
