<template>
  <div class='column sales-calendar'>
    <div class='row'>
      <a-calendar
        v-model='selectDate'
        :mode='calendarMode'
        :bodyStyle="{
          overflow: 'auto'
        }"
        format='YYYY-MM-DD'
        @select='onSelect(null)'
        @panelChange='onPanelChange'
      >
        <!-- Header 표시 -->
        <div class='row justify-center items-center' slot='headerRender'>
          <div class='col'>
            <a-button @click='onSelect(today)'>오늘</a-button>
          </div>

          <div class='col-auto'>
            <a-button type='link' icon='left' @click='onChangeMonth(true)'></a-button>
          </div>

          <div class='col-grow text-center padding-x-md'>
            <h1 class='title-text margin-bottom-none'>{{ getCalendarTitle().month }}</h1>
            <b class='sub-title-text'>{{ getCalendarTitle().year }}</b>
          </div>

          <div class='col-auto'>
            <a-button type='link' icon='right' @click='onChangeMonth(false)'></a-button>
          </div>

          <div class='col text-right'>
            <a-radio-group v-model='calendarMode'>
              <a-radio-button value='month'>Month</a-radio-button>
              <a-radio-button value='year'>Year</a-radio-button>
            </a-radio-group>
          </div>
        </div>
        <!-- 일별 표시 -->
        <ul class='list-style-none' slot='dateCellRender' slot-scope='value'>
          <li v-for='(item, index) in getListDateData(value)' :key='index'>
            <a-tooltip
              placement='right'
              :overlayClassName='`calendar-tooltip-${item.color}`'
            >
              <template slot='title'>
                <span>{{ item.content }}</span>
              </template>

              <a-tag :color='item.color' class='full-width txt_line'>
                {{ item.content }}
              </a-tag>
            </a-tooltip>
          </li>
        </ul>

        <!-- 월별 표시 -->
        <ul class='list-style-none' slot='monthCellRender' slot-scope='value'>
          <li v-for='(item, index) in getListMonthData(value)' :key='index'>
            <a-tooltip
              placement='right'
              :overlayClassName='`calendar-tooltip-${item.color}`'
            >
              <template slot='title'>
                <span>
                  <b>{{ item.day }}일 : </b>
                  {{ item.content }}
                </span>
              </template>

              <a-tag :color='item.color' class='full-width txt_line'>
                <b>{{ item.day }}일 : </b>
                {{ item.content }}
              </a-tag>
            </a-tooltip>
          </li>
        </ul>
      </a-calendar>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    productList: Array
  },
  data() {
    return {
      type: '',
      calendarMode: 'month',
      today: this.$moment(new Date()),
      selectDate: this.$moment(new Date()),
      monthlyData: []
    };
  },
  created() {
    const me = this;
    me.getSalesCalendarData();
  },
  mounted() {
    const me = this;

    me.$root.$on('get-calendar', function(data) {
      me.type = data.type;
      me.getSalesCalendarData();
    });
  },
  beforeDestroy() {
    const me = this;

    me.$root.$off('get-calendar');
  },
  methods: {
    initData() {
      this.monthlyData = [];
      this.selectDate = this.$moment(new Date());
    },
    getCalendarTitle() {
      const curDate = this.$moment(this.selectDate);
      return {
        month: curDate.format('M월'),
        year: curDate.format('YYYY')
      };
    },
    onSelect(value) {
      if (!value || value === this.selectDate) return;
      this.selectDate = value;
    },
    /**
     * 이전, 다음 달로 이동
     * isPrev : 이전 달로 이동하는지 여부
     * */
    onChangeMonth(isPrev) {
      const changeDate = isPrev ? this.$moment(this.selectDate).subtract(1, 'months') : this.$moment(this.selectDate).add(1, 'months');
      this.selectDate = changeDate;
    },
    onPanelChange(value) {
      this.selectDate = value;
    },
    // 달력에 날짜별 표시할 데이터 목록
    getListDateData(value) {
      const date = this.$moment(value).format('YYYY-MM-DD');
      return this.monthlyData.filter((item) => item.date === date);
    },
    // 달력에 월별 표시할 데이터 목록
    getListMonthData(value) {
      const month = this.$moment(value).format('YYYY-MM');
      return this.monthlyData.filter((item) => this.$moment(item.date).format('YYYY-MM') === month);
    },
    getSalesCalendarData() {
      this.initData();

      try {
        this.$axios.get('/sales/calendar', { params: { type: this.type } })
          .then((response) => {
            let calendarList = response.data;

            if (!calendarList) return;

            for (let idx in calendarList) {
              const listItem = calendarList[idx];

              if (this.type !== 'product') {
                // 전체
                this.setMonthlyData({
                  ...listItem,
                  name: `매출합계 : ${(listItem.amount * 1).toLocaleString()} 원`
                });
              } else {
                // 상품별
                listItem.forEach(item => {
                  this.setMonthlyData({
                    ...item,
                    name: `${item.name} : ${(item.amount * 1).toLocaleString()} 원`
                  });
                });
              }
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    // 달력에 표시할 데이터 가공
    setMonthlyData(data) {
      let itemIdx = this.productList.findIndex(item => item === data.name);
      const statusEnum = ['blue', 'red', 'orange', 'purple', 'green'];
      itemIdx = itemIdx < 0 ? 0 : itemIdx += 1;

      this.monthlyData.push({
        color: statusEnum[itemIdx],
        content: data.name,
        date: data.payed_date,
        day: this.$moment(data.payed_date).format('DD')
      });
    }
  }
};
</script>

<style>
.sales-calendar td .ant-fullcalendar-content {
  height: 74% !important;
}

.sales-calendar td .ant-fullcalendar-date {
  height: 83px !important;
}

.calendar-tooltip-pink .ant-tooltip-inner {
  color: #eb2f96 !important;
  background: #fff0f6 !important;
  border: 1px solid #ffadd2;
}

.calendar-tooltip-pink .ant-tooltip-arrow::before {
  background: #ffadd2 !important;
  border: 1px solid #ffadd2;
}

.calendar-tooltip-blue .ant-tooltip-inner {
  color: #1890ff !important;
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff;
}

.calendar-tooltip-blue .ant-tooltip-arrow::before {
  background-color: #91d5ff !important;
  border: 1px solid #91d5ff;
}

.calendar-tooltip-purple .ant-tooltip-inner {
  color: #722ed1;
  background: #f9f0ff;
  border: 1px solid #d3adf7;
}

.calendar-tooltip-purple .ant-tooltip-arrow::before {
  background: #d3adf7 !important;
  border: 1px solid #d3adf7;
}

.calendar-tooltip-orange .ant-tooltip-inner {
  color: #fa8c16 !important;
  background: #fff7e6 !important;
  border: 1px solid #ffd591;
}

.calendar-tooltip-orange .ant-tooltip-arrow::before {
  background: #ffd591 !important;
  border: 1px solid #ffd591;
}

.calendar-tooltip-green .ant-tooltip-inner {
  color: #52c41a;
  background: #f6ffed;
  border: 1px solid #b7eb8f;
}

.calendar-tooltip-green .ant-tooltip-arrow::before {
  background: #b7eb8f !important;
  border: 1px solid #b7eb8f;
}
</style>