export default {
  namespaced: true,
  state: {
    theme: {
      color: '#f5222d',
      mode: 'dark',
    },
    palettes: ['#f5222d', '#1890ff'],
    screen: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    wide: false,
  },
  getters: {
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    isMobile(state) {
      return state.screen.width < 1500;
    },
    getTheme(state) {
      return state.theme;
    },
    getScreen(state) {
      return state.screen;
    },
    getWide(state) {
      return state.wide;
    },
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme;
    },
    setScreen(state, screen) {
      state.screen = screen;
    },
    setWide(state, wide) {
      state.wide = wide;
    },
  },
};
