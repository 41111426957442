<template>
  <div>
    <a-card :title='title' :style="{ marginTop: '20px' }">
      <div slot='extra'></div>

      <div class='margin-bottom-md'>
        <custom-filter :page='page' @search='search' />

        <a-alert
          :message="'검색결과 : ' + filters.pagination.total + ' 개'"
          type='success'
          showIcon
          :style="{ width: '300px' }"
        />
      </div>

      <custom-table
        :table-list='data'
        :columns='columns'
        :pagination='filters.pagination'
        :loading='loading'
        :action-list="['detail']"
        @pagination-change='onPaginationChanged'
        @open-modal='detail'
      />
    </a-card>

    <detail-modal
      :modal='modal'
      :user='modalData'
      @close='modal = false'
      @index='getUsers()'
    />
  </div>
</template>

<script>
import DetailModal from './modals/detail/Index';
import { mapGetters } from 'vuex';
import CustomTable from '@/views/components/CustomTable';
import CustomFilter from '@/views/components/CustomFilter';

export default {
  props: {
    type: String
  },
  components: { DetailModal, CustomTable, CustomFilter },
  computed: {
    ...mapGetters({
      isMobileDevice: 'setting/isMobileDevice',
      getFilters: 'filters/getStateFilters'
    }),
    title() {
      return '사용자 관리';
    },
    filters() {
      return this.getFilters(this.page);
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      modal: false,
      modalData: {},
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          align: 'center',
          width: 50,
          ellipsis: true
        },
        {
          title: '서비스사',
          dataIndex: 'coalition_name',
          key: 'coalition_name',
          align: 'center',
          scopedSlots: { customRender: 'company' },
          width: 80,
          ellipsis: true
        },
        {
          title: '상태',
          dataIndex: 'status',
          key: 'status',
          align: 'center',
          scopedSlots: { customRender: 'user_status' },
          width: 80,
          ellipsis: true
        },
        {
          title: '이메일',
          dataIndex: 'email',
          key: 'email',
          align: 'left',
          width: 250,
          ellipsis: true
        },
        {
          title: '이름',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
          width: 200,
          ellipsis: true
        },
        {
          title: '회사명',
          dataIndex: 'company_name',
          key: 'company_name',
          align: 'center',
          width: 100,
          ellipsis: true
        },
        {
          title: '가입일',
          dataIndex: 'created_at',
          key: 'created_at',
          align: 'center',
          width: 100,
          ellipsis: true
        },
        {
          title: '로그인 횟수',
          dataIndex: 'login_count',
          key: 'login_count',
          align: 'center',
          width: 100,
          ellipsis: true
        },
        {
          title: '마지막 접속일',
          dataIndex: 'last_login',
          key: 'last_login',
          align: 'center',
          width: 100,
          ellipsis: true
        },
        {
          title: '기능',
          dataIndex: 'actions',
          key: 'actions',
          align: 'center',
          scopedSlots: { customRender: 'actions' },
          width: 100,
          ellipsis: true
        }
      ],
      page: 'user'
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    /** 페이지 변경시 **/
    onPaginationChanged(pagination) {
      this.filters.pagination.current = pagination ? pagination.current : this.filters.pagination.current;
      this.search();
    },
    /** 검색 **/
    search(filterData) {
      if (filterData && this.filters.pagination.current !== 1) {
        this.filters.pagination.current = 1;
        return;
      }

      this.getUsers();
    },
    getUsers() {
      this.loading = true;

      let params = {
        page: this.filters.pagination.current,
        page_rows: this.filters.pagination.limit,
        ...this.filters
      };

      try {
        this.$axios.get('users', { params: params }).then(res => {
          let rawData = res.data.data;

          this.filters.pagination.total = rawData.total;
          this.filters.pagination.current = rawData.current_page;

          this.data = this.setData(rawData.data);

          this.loading = false;
        });
      } catch (error) {
        console.log(error);
      }
    },

    setData(data) {
      return data.map((item, index) => {
        return {
          key: index,
          coalition_name: item.coalition_name,
          company_name: item.company ? item.company.name : '-',
          created_at: this.$moment(item.created_at).format('YYYY-MM-DD'),
          email: item.email,
          id: item.id,
          parent_id: item.parent_id,
          memo: item.memo,
          name: item.name,
          permission: item.permission,
          ends_at: item.subscriptions
            ? item.subscriptions.length > 0
              ? this.$moment(item.subscriptions[0].ends_at).format('YYYY-MM-DD')
              : ''
            : '-',
          status: item.subscriptions
            ? item.subscriptions.length > 0
              ? item.subscriptions[0].status
              : '무료'
            : '무료',
          subscription_name: item.subscriptions
            ? item.subscriptions.length > 0
              ? item.subscriptions[0].name
              : '-'
            : '-',
          place: item.subscriptions
            .filter(item => {
              let code = [3, 4, 8, 9];
              return code.includes(item.subscription_item_id);
            })
            .pop(),
          app: item.subscriptions
            .filter(item => {
              let code = [6, 7, 8, 9];
              return code.includes(item.subscription_item_id);
            })
            .pop(),
          manager_phone: item.company ? item.company.manager_phone : '-',
          manager_name: item.company ? item.company.manager_name : '-',
          license_number: item.company ? item.company.license_number : '-',
          subscriptions: item.subscriptions,
          last_login:
            item.user_log.length > 0
              ? this.$moment(item.user_log[0].created_at).format('YYYY-MM-DD')
              : '-',
          login_count: item.user_log.length > 0 ? item.user_log.length : '-'
        };
      });
    },

    detail(data) {
      this.modalData = data;
      this.modal = true;
    }
  }
};
</script>
