<template>
  <div>
    <a-card :title='title' class='margin-top-md'>
      <div slot='extra' />

      <div class='margin-bottom-md'>
        <custom-filter :page='page' @search='search' />

        <a-alert
          :message='`검색결과 : ${filters.pagination.total} 개`'
          type='success'
          showIcon
          :style="{ width: '100%', maxWidth: '300px'}"
        />
      </div>

      <custom-table
        :table-list='data'
        :columns='columns'
        :pagination='filters.pagination'
        :loading='loading'
        :action-list="['detail']"
        @pagination-change='onPaginationChanged'
        @open-modal='openModal'
      />
    </a-card>

    <form-modal
      :modal='modal'
      :data='modalData'
      :mode='mode'
      @index='getContact'
      @close='modal = false'
    />
  </div>
</template>

<script>
import FormModal from './modals/Form/Index';
import { mapGetters } from 'vuex';
import CustomTable from '../components/CustomTable';
import CustomFilter from '@/views/components/CustomFilter';

export default {
  components: {
    FormModal,
    CustomTable,
    CustomFilter
  },
  computed: {
    ...mapGetters({
      isMobileDevice: 'setting/isMobileDevice',
      getFilters: 'filters/getStateFilters'
    }),
    title() {
      return '문의사항 관리';
    },
    filters() {
      return this.getFilters(this.page);
    }
  },
  data() {
    return {
      loading: false,
      mode: '',
      modal: false,
      modalData: {},
      data: [],
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          scopedSlots: { customRender: 'id' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '서비스사',
          dataIndex: 'coalition_name',
          key: 'coalition_name',
          scopedSlots: { customRender: 'company' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '제목',
          dataIndex: 'title',
          key: 'title',
          scopedSlots: { customRender: 'title' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '카테고리',
          dataIndex: 'category_info',
          key: 'category_info',
          scopedSlots: { customRender: 'info_obj' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '회원',
          dataIndex: 'user_email',
          key: 'user_email',
          scopedSlots: { customRender: 'user_email' },
          align: 'center',
          ellipsis: false
        },
        {
          title: '요청일',
          dataIndex: 'created_at',
          key: 'created_at',
          scopedSlots: { customRender: 'created_at' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '답변여부',
          dataIndex: 'is_answer',
          key: 'is_answer',
          scopedSlots: { customRender: 'use' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '기능',
          dataIndex: 'actions',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
          align: 'center',
          ellipsis: false
        }
      ],
      categoryOptions: [
        { id: '', text: '전체', color: '' },
        { id: 'payment', text: '결제 관련', color: 'volcano' },
        { id: 'account', text: '계정 관련', color: 'cyan' },
        { id: 'tax', text: '세금계산서 관련', color: 'orange' },
        { id: 'data', text: '데이터 관련', color: 'blue' },
        { id: 'other', text: '기타', color: '' }
      ],
      page: 'contact'
    };
  },
  mounted() {
    this.getContact();
  },
  methods: {
    /** 페이지 변경시 **/
    onPaginationChanged(pagination) {
      this.filters.pagination.current = pagination ? pagination.current : this.filters.pagination.current;
      this.search();
    },
    /** 검색 **/
    search(filterData) {
      if (filterData && this.filters.pagination.current !== 1) {
        this.filters.pagination.current = 1;
        return;
      }

      this.getContact();
    },
    /** 문의사항 목록 조회 **/
    getContact() {
      this.loading = true;

      let params = {
        ...this.filters,
        page: this.filters.pagination.current,
        page_rows: this.filters.pagination.limit
      };

      try {
        this.$axios.get('inquire', { params: params })
          .then((res) => {
            let rawData = res.data;

            this.filters.pagination.total = rawData.total;
            this.filters.pagination.current = rawData.current_page;

            this.data = rawData.data.map((item, index) => {
              const category = this.getCategoryInfo(item.category);

              return {
                key: index.toString(),
                ...item,
                user_email: item.user ? item.user.email : '-', // 회원 - 이메일
                coalition_name: item.user ? item.user.coalition_name : '-', // 서비스사
                category_info: category, // 카테고리
                date_format: 'YYYY년 MM월 DD일'
              };
            });
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 카테고리 정보
     * return: { id: string, code: string, text: string }
     */
    getCategoryInfo(code) {
      const idx = this.categoryOptions.findIndex((category) => category.id === code);
      // 기본 '기타'
      return idx > 0 ? this.categoryOptions[idx] : this.categoryOptions[this.categoryOptions.length - 1];
    },
    /** 모달 띄우기 **/
    openModal(record = null) {
      this.mode = !record.is_answer ? 'edit' : 'view';
      this.modalData = record;
      this.modal = true;
    }
  }
};
</script>
