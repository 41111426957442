<template>
  <a-layout class="fit row justify-center">
    <a-result
      status="404"
      title="404"
      sub-title="Sorry, we couldn't find the page you are looking for."
    >
      <template #extra>
        <a-button type="primary" @click="$router.replace('/')">
          Back Home
        </a-button>
      </template>
    </a-result>
  </a-layout>
</template>

<script>
export default {
  computed: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped></style>
