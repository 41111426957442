module.exports = {
  lang: 'KR',
  theme: {
    color: '#f5222d',
    mode: 'dark',
    success: '#52c41a',
    warning: '#faad14',
    error: '#f5222f',
  }
}
