<template>
  <a-modal
    v-model='visible'
    :on-cancel='close'
    :width="'80%'"
    :title='title'
    :bodyStyle="{ position: 'relative' }"
    :dialogStyle="{ maxWidth: '1300px' }"
  >
    <a-form-model
      v-if='modal'
      layout='horizontal'
      ref='form'
      :model='formData'
      :rules='rules'
      v-bind='formItemLayout'
    >
      <a-descriptions title='기본 정보' />
      <a-row>
        <a-col :sm='{ span: 24 }' :md='{ span: 14 }'>
          <!-- 지도 표시 -->
          <hot-place-map
            :polygon='formData.polygon'
            @change-polygon-path='onPolygonPathChange'
          />
        </a-col>

        <a-col
          :sm='{ span: 24 }'
          :md='{ span: 9, offset: 1 }'
          class='row-full-items'
        >
          <a-form-model-item prop='name'>
            <a-input
              addon-before='HOT 플레이스명'
              v-model='formData.name'
              :style="{
                display: 'inline-block',
                width: '100%',
                maxWidth: '450px',
              }"
            />
          </a-form-model-item>

          <a-form-model-item prop='category'>
            <a-input-group
              class='row items-top'
              :style="{
                display: 'inline-block',
                width: '100%',
                maxWidth: '450px',
              }"
            >
              <span
                class='ant-input-group-addon col-auto'
                :style="{
                  width: 'auto',
                  height: '32px',
                  paddingTop: '3px',
                }"
              >
                카테고리
              </span>
              <a-select
                class='col-grow'
                placeholder='선택'
                v-model='formData.category'
              >
                <a-select-option
                  v-for='(item, index) in filteredCategoryOption'
                  :key='index'
                  :value='item.id'
                >
                  {{ item.text }}
                </a-select-option>
              </a-select>
            </a-input-group>
          </a-form-model-item>

          <a-form-model-item prop='region'>
            <a-input-group
              class='row items-top'
              :style="{
                display: 'inline-block',
                width: '100%',
                maxWidth: '450px',
              }"
            >
              <span
                class='ant-input-group-addon col-auto'
                :style="{
                  width: 'auto',
                  height: '32px',
                  paddingTop: '3px',
                }"
              >
                지역
              </span>
              <a-select
                v-model='formData.area'
                class='col-grow'
                placeholder='선택'
              >
                <a-select-option
                  v-for='(item, index) in filteredRegionOption'
                  :key='index'
                  :value='item.id'
                >
                  {{ item.text }}
                </a-select-option>
              </a-select>
            </a-input-group>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <template slot='footer'>
      <a-row>
        <a-col :span='12' :style="{ textAlign: 'left' }">
          <a-popconfirm
            v-if='!!formData.id'
            :visible='confirm'
            title='해당 정보를 삭제하시겠습니까 ? 삭제시 복구가 불가능합니다.'
            ok-text='삭제'
            cancel-text='취소'
            @confirm='deletePlace'
            @cancel='confirm = false'
          >
            <a-button
              type='danger'
              @click='confirm = true'
              ghost
              :loading='loading'
            >
              삭제
            </a-button>
          </a-popconfirm>
        </a-col>
        <a-col :span='12' :style="{ textAlign: 'right' }">
          <a-button key='back' @click='close'>닫기</a-button>
          <a-button type='primary' :loading='loading' @click='submit'>
            {{ modeText }}
          </a-button>
        </a-col>
      </a-row>
    </template>

    <div v-if='loading' class='fit dim'>
      <a-spin />
    </div>
  </a-modal>
</template>

<script type='text/javascript'
        src='//dapi.kakao.com/v2/maps/sdk.js?appkey=d21e58b1071c7114a01367eeec4b6539&libraries=services,drawing'></script>
<script>
import HotPlaceMap from './Map.vue';

export default {
  props: {
    modal: Boolean,
    mode: String,
    data: Object,
    hotPlaceCategoryOption: Array,
    regionOption: Array
  },
  components: {
    hotPlaceMap: HotPlaceMap
  },
  data() {
    return {
      loading: false,
      visible: false,
      confirm: false,
      deposit_date: null,
      formData: {
        id: '',
        name: '',
        category: undefined,
        area: undefined,
        polygon: []
      },
      formItemLayout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 10 }
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Hot 플레이스명을 입력해주세요.',
            trigger: 'change'
          }
        ],
        category: [
          {
            required: true,
            message: '카테고리를 선택해주세요.',
            trigger: 'change'
          }
        ],
        area: [
          {
            required: true,
            message: '지역을 선택해주세요.',
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    modal(v) {
      this.reset();

      if (!this._.isEmpty(this.data)) {
        this.formData = {
          id: this.data.id,
          name: this.data.place_name,
          category: this.data.location_category_id,
          area: this.data.area_name,
          polygon: this.data.polygon ?? []
        };
      }
      this.visible = v;
    },
    visible(v) {
      if (!v) {
        this.$emit('close');
      }
    }
  },
  computed: {
    title() {
      return `플레이스 ${this.mode === 'edit' ? '상세' : '등록'}`;
    },
    modeText() {
      return this.mode === 'edit' ? '변경' : '등록';
    },
    filteredCategoryOption() {
      return this.hotPlaceCategoryOption
        ? this.hotPlaceCategoryOption.filter(item => !!item.id)
        : [];
    },
    filteredRegionOption() {
      return this.regionOption
        ? this.regionOption.filter(item => !!item.id)
        : [];
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    reset() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }

      this.formData = {
        id: '',
        name: '',
        category: undefined,
        area: undefined,
        polygon: []
      };
    },
    getFormatDate(date) {
      return date ? this.$moment(date).format('YYYY-MM-DD') : '-';
    },
    // 지도에서 다각형 그린 후 이벤트
    onPolygonPathChange(polygon) {
      this.formData.polygon = polygon;
    },
    // 플레이스 저장
    saveHotPlace() {
      this.loading = true;

      try {
        const url = `hotplace/${this.formData.id ? 'update' : 'store'}`;
        const methods = this.formData.id ? 'put' : 'post';

        this.$axios[methods](url, this.formData).then(res => {
          let rawData = res.data;

          const type = rawData.success ? 'success' : 'error';

          this.$notification[type]({
            message: `${this.modeText} ${rawData.success ? '성공' : '실패'}`,
            description: rawData.message
          });

          if (rawData.success) {
            this.$emit('index');
            this.close();
          }
          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.'
        });
      }
    },
    submit() {
      if (!this.formData.polygon || this.formData.polygon.length === 0) {
        this.$notification['error']({
          message: '필수값',
          description: 'Hot플레이스 영역을 선택해주세요.'
        });

        return;
      }

      this.$refs['form'].validate(valid => {
        if (!valid) return;

        this.saveHotPlace();
      });
    },
    // 플레이스 삭제
    deletePlace() {
      this.loading = true;
      this.confirm = false;

      try {
        this.$axios.delete(`hotplace/destroy/${this.formData.id}`).then(res => {
          let rawData = res.data;

          const type = rawData.success ? 'success' : 'error';

          this.$notification[type]({
            message: `삭제 ${rawData.success ? '성공' : '실페'}`,
            description: rawData.message
          });

          if (rawData.success) {
            this.$emit('index');
            this.close();
          }
          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.'
        });
      }
    }
  }
};
</script>
<style>
.row-full-items .ant-form-item-control-wrapper {
  width: 100% !important;
}

.dim {
  position: absolute;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  top: 0;
  left: 0;
  z-index: 999;
}

.dim > * {
  position: absolute;
  top: calc(50% - 25px);
}
</style>
