<template>
  <div>
    <a-card :title='title' class='margin-top-md'>
      <div slot='extra' />

      <div class='margin-bottom-md'>
        <custom-filter :page='page' @search='search' />

        <div class='row justify-center'>
          <div class='col-grow'>
            <a-alert
              :message="'검색결과 : ' + filters.pagination.total + ' 개'"
              type='success'
              showIcon
              :style="{ maxWidth: '300px' }"
            />
          </div>

          <div class='col-auto self-center'>
            <a-button type='primary' @click='openModal()'>
              등록
            </a-button>
          </div>
        </div>
      </div>

      <custom-table
        :table-list='data'
        :columns='columns'
        :pagination='filters.pagination'
        :loading='loading'
        :action-list="['detail']"
        @pagination-change='onPaginationChanged'
        @open-modal='openModal'
        @reload-data='reloadData'
      />
    </a-card>

    <form-modal
      :modal='modal'
      :data='modalData'
      :mode='mode'
      :trendTypeOption='trendCategoryOption'
      @index='getTrendNews'
      @close='modal = false'
    />
  </div>
</template>

<script>
import FormModal from './modals/Form/Index';
import { mapGetters } from 'vuex';
import { getOptions, getOptionByKey } from '@/utils/options';
import CustomTable from '@/views/components/CustomTable';
import CustomFilter from '@/views/components/CustomFilter';

export default {
  components: {
    FormModal,
    CustomTable,
    CustomFilter
  },
  computed: {
    ...mapGetters({
      getFilters: 'filters/getStateFilters'
    }),
    title() {
      return '트렌드 게시물 관리';
    },
    filters() {
      return this.getFilters(this.page);
    }
  },
  data() {
    return {
      loading: false,
      mode: '',
      modal: false,
      modalData: {},
      data: [],
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          scopedSlots: { customRender: 'id' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '구분',
          dataIndex: 'type_info',
          key: 'type_info',
          scopedSlots: { customRender: 'info_obj' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '제목',
          dataIndex: 'title',
          key: 'title',
          scopedSlots: { customRender: 'title' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '부제목',
          dataIndex: 'sub_title',
          key: 'sub_title',
          scopedSlots: { customRender: 'sub_title' },
          align: 'center',
          ellipsis: false
        },
        {
          title: '사용 여부',
          dataIndex: 'is_delete',
          key: 'is_delete',
          scopedSlots: { customRender: 'is_delete' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '등록일',
          dataIndex: 'created_at',
          key: 'created_at',
          align: 'center',
          ellipsis: true
        },
        {
          title: '기능',
          dataIndex: 'actions',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
          align: 'center',
          ellipsis: false
        }
      ],
      trendCategoryOption: getOptions('trendCategoryOption'),
      page: 'trendNews'
    };
  },
  mounted() {
    this.getTrendNews();
  },
  methods: {
    /** 페이지 변경시 **/
    onPaginationChanged(pagination) {
      this.filters.pagination.current = pagination ? pagination.current : this.filters.pagination.current;
      this.search();
    },
    /** 검색 **/
    search(filterData) {
      if (filterData && this.filters.pagination.current !== 1) {
        this.filters.pagination.current = 1;
        return;
      }

      this.getTrendNews();
    },
    /** 트렌드 Ads & News 목록 조회 **/
    getTrendNews() {
      this.loading = true;

      let params = {
        ...this.filters,
        page: this.filters.pagination.current,
        page_rows: this.filters.pagination.limit
      };

      params[`${this.filters.search_key}`] = this.filters.search;

      try {
        this.$axios.get('trends/news', { params: params }).then(res => {
          let rawData = res.data;

          this.filters.pagination.total = rawData.total;
          this.filters.pagination.current = rawData.current_page;

          this.data = rawData.data.map((item, index) => {
            return {
              key: index.toString(),
              ...item,
              type_info: getOptionByKey(
                this.trendCategoryOption,
                item.news_type
              ),
              created_at: this.$moment(item.created_at).format(
                'YYYY년 MM월 DD일'
              )
            };
          });
          this.loading = false;
        });
      } catch (error) {
        console.log(error);
      }
    },
    /** 모달 띄우기 **/
    openModal(record = null) {
      this.modalData = record;
      this.modal = true;
      this.mode = record ? 'edit' : 'store';
    },
    /** 갱신 **/
    reloadData(record) {
      if (!record || record.type !== 'T') return;

      this.loading = true;

      try {
        this.$axios.post('trends/tdi-news/' + record.id, { _method: 'PUT' })
          .then(res => {
            this.getTrendNews();
            let rawData = res.data;

            let type = rawData.success ? 'success' : 'error';
            let message = rawData.success ? '성공' : '실패';
            let description = rawData.success ? '완료되었습니다' : '실패하였습니다.';

            this.$notification[type]({
              message: `갱신 ${message}`,
              description: `갱신이 ${description}`
            });

            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
