<template>
  <a-modal
    v-model='visible'
    :on-cancel='close'
    :width="'80%'"
    :title='title'
  >
    <div v-if='modal'>
      <a-descriptions bordered title='기본 정보'>
        <a-descriptions-item label='서비스사' :span='3'>
          <a-tag :color='data.coalition.color'>
            {{ data.coalition.text }}
          </a-tag>
        </a-descriptions-item>

        <a-descriptions-item label='회원 계정'>
          {{ data.user_email }}
        </a-descriptions-item>

        <a-descriptions-item label='회원 이름' :span='2'>
          {{ data.user.name }}
        </a-descriptions-item>
      </a-descriptions>

      <a-descriptions bordered class='margin-top-md' title='취소 정보'>
        <a-descriptions-item label='주문번호'>
          {{ data.order_no }}
        </a-descriptions-item>

        <a-descriptions-item label='해지일' :span='2'>
          {{ dateFormat }}
        </a-descriptions-item>

        <a-descriptions-item label='카테고리' :span='3'>
          <a-tag
            v-for='(item, idx) in data.category_list'
            :key='idx'
            class='margin-right-md'
          >
            {{ item }}
          </a-tag>
        </a-descriptions-item>

        <a-descriptions-item label='개선 내용'>
          <a-textarea v-model='data.description' autoSize disabled class='readonly border-top-none' />
        </a-descriptions-item>
      </a-descriptions>
    </div>

    <template slot='footer'>
      <a-row>
        <a-col :span='24' :style="{ textAlign: 'right' }">
          <a-button key='back' @click='close'>닫기</a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>

export default {
  props: {
    modal: Boolean,
    data: Object
  },
  data() {
    return {
      loading: false,
      visible: false
    };
  },
  watch: {
    modal(v) {
      this.visible = v;
    },
    visible(v) {
      if (!v) {
        this.$emit('close');
      }
    }
  },
  computed: {
    title() {
      return '구독취소 사유 상세';
    },
    dateFormat() {
      return !this._.isEmpty(this.data) ? this.$moment(this.data.created_at).format(this.data.date_format) : '-';
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>
