<template>
  <a-modal v-model="visible" :title="title" :on-cancel="close" :width="'80%'">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item
        label="그룹명"
        prop="name"
        :colon="false"
        :style="{ maxWidth: '500px' }"
      >
        <a-input v-model="form.name" />
      </a-form-model-item>
    </a-form-model>

    <label
      title="POI 설정"
      class="ant-form-item-required ant-form-item-no-colon"
      :style="{
        color: '#000000d9',
      }"
    >
      POI 설정
    </label>

    <poi-transfer
      :filters="poiFilters"
      :modal="modal"
      :style="{ marginTop: '15px' }"
    />

    <template slot="footer">
      <a-row>
        <a-col :span="12" :style="{ textAlign: 'left' }">
          <a-popconfirm
            v-if="mode === 'edit'"
            :visible="confirm"
            title="해당 그룹을 삭제하시겠습니까 ? 삭제시 복구가 불가능합니다."
            ok-text="삭제"
            cancel-text="취소"
            @confirm="deleteGroupPoi"
            @cancel="confirm = false"
          >
            <a-button
              type="danger"
              @click="confirm = true"
              ghost
              :loading="loading"
            >
              삭제
            </a-button>
          </a-popconfirm>
        </a-col>
        <a-col :span="12" :style="{ textAlign: 'right' }">
          <a-button key="back" @click="close"> 닫기 </a-button>
          <a-button type="primary" @click="submit()" :loading="loading">
            {{ modeText }}
          </a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
import PoiTransfer from '@/views/mapping/config/modals/Form/Poi';

export default {
  props: {
    modal: Boolean,
    mode: String,
    data: Object,
  },
  components: { PoiTransfer },
  data() {
    return {
      loading: false,
      visible: false,
      confirm: false,
      form: {
        name: '',
      },
      poiFilters: {},
      params: {
        name: '',
        pois: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: '그룹명은 필수입니다.',
            trigger: 'change',
          },
        ],
      },
    };
  },
  watch: {
    modal(v) {
      this.reset();

      this.visible = v;

      if (this.mode === 'edit') {
        this.form.name = this.data.name;
        this.poiFilters.target = this.data.pois.map((poi, index) => {
          return {
            key: index,
            id: poi.id,
            poi_id: poi.poi_id,
            name1: poi.poi.name1,
            name2: poi.poi.name2,
            class_a: poi.poi.category.class_a,
            class_b: poi.poi.category.class_b,
            class_c: poi.poi.category.class_c,
            class_d: poi.poi.category.class_d,
            class_a_name: poi.poi.category.class_a_name,
            class_b_name: poi.poi.category.class_b_name,
            class_c_name: poi.poi.category.class_c_name,
            class_d_name: poi.poi.category.class_d_name,
          };
        });
      }
    },
    visible(v) {
      if (!v) {
        this.$emit('close');
      }
    },
  },

  computed: {
    title() {
      return 'POI 그룹 ' + this.modeText;
    },
    modeText() {
      return this.mode === 'edit' ? '변경' : '등록';
    },
  },
  mounted() {},
  methods: {
    close() {
      this.$emit('close');
    },
    reset() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }

      this.confirm = false;

      this.form = {
        name: '',
      };

      this.poiFilters = {
        source: [],
        target: [],
        pagination: {
          current: 1,
          total: 1,
          limit: 10,
        },
      };
    },
    setGroupPoi() {
      this.loading = true;

      try {
        this.$axios.post('poi/group', this.params).then(res => {
          if (res.status === 200) {
            if (res.data.success) {
              this.$notification['success']({
                message: '등록 완료',
                description: res.data.message,
              });

              this.$emit('index');
              this.close();
            } else {
              this.$notification['error']({
                message: '등록 실패',
                description: res.data.message,
              });
            }
          }

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.',
        });
      }
    },
    updateGroupPoi() {
      this.loading = true;

      try {
        this.$axios.put('poi/group/' + this.data.id, this.params).then(res => {
          if (res.status === 200) {
            if (res.data.success) {
              this.$notification['success']({
                message: '변경 완료',
                description: res.data.message,
              });

              this.$emit('index');
              this.close();
            } else {
              this.$notification['error']({
                message: '변경 실패',
                description: res.data.message,
              });
            }
          }

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.',
        });
      }
    },
    deleteGroupPoi() {
      this.loading = true;

      try {
        this.$axios.delete('poi/group/' + this.data.id).then(res => {
          if (res.status === 200) {
            if (res.data.success) {
              this.$notification['success']({
                message: '삭제 완료',
                description: res.data.message,
              });

              this.$emit('index');
              this.close();
            } else {
              this.$notification['error']({
                message: '삭제 실패',
                description: res.data.message,
              });
            }
          }

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.',
        });
      }
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.poiFilters.target.length === 0) {
            this.$notification['error']({
              message: '필수값',
              description: 'POI는 1개 이상 선택해야 합니다.',
            });

            return;
          }

          this.params = {
            name: this.form.name,
            pois: this.poiFilters.target,
          };

          if (this.mode === 'edit') {
            this.updateGroupPoi();
          } else {
            this.setGroupPoi();
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
