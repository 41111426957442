var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout',{class:_vm.isMobileDevice ? 'dd-layout-mobile' : 'dd-layout',staticStyle:{"justify-content":"center","background":"#001529"}},[_c('a-card',{staticClass:"dd-login"},[_c('template',{slot:"title"},[_c('div',{style:({ textAlign: 'center' })},[_c('img',{attrs:{"src":require('../../assets/img/' + _vm.logo + '.png'),"width":150,"alt":"logo"}}),_c('div',{style:({ marginTop: '10px' })},[_c('a-tag',[_vm._v(" 관리자 ")])],1)])]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [{ required: true, message: '이메일을 입력해주세요.' }],
            } ]),expression:"[\n            'email',\n            {\n              rules: [{ required: true, message: '이메일을 입력해주세요.' }],\n            },\n          ]"}],attrs:{"size":"large","placeholder":"이메일"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                { required: true, message: '비밀번호를 입력해주세요.' } ],
            } ]),expression:"[\n            'password',\n            {\n              rules: [\n                { required: true, message: '비밀번호를 입력해주세요.' },\n              ],\n            },\n          ]"}],attrs:{"type":"password","size":"large","placeholder":"비밀번호"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary","size":"large","html-type":"submit","loading":_vm.loading}},[_vm._v(" 로그인 ")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }