<template>
  <div>
    <a-table
      :columns='columns'
      :data-source='tableList'
      :loading='loading'
      bordered
      :scroll='{ x: true }'
      scrollToFirstRowOnChange
      size='small'
      :pagination='false'
    >
      <span slot='key_index' slot-scope='text, record, index'>
        {{
          pagination.limit * pagination.current - pagination.limit + index + 1
        }}
      </span>

      <!--  회사    -->
      <span slot='company' slot-scope='text'>
        <a-tag :color="text === 'TDI' ? 'blue' : 'orange'">
          {{ text }}
        </a-tag>
      </span>

      <span slot='tag_text' slot-scope='text'>
        <a-tag>
          {{ text }}
        </a-tag>
      </span>

      <!--  사용자 사용여부    -->
      <span slot='user_status' slot-scope='text'>
        <a-tag :color="text === 'OK' ? 'pink' : ''">
          {{ text === 'OK' ? '구독중' : '무료' }}
        </a-tag>
      </span>

      <!--  사용여부    -->
      <span slot='use' slot-scope='text'>
        <a-icon
          :type="`${ text ? 'check' : 'close'}-circle`"
          theme='filled'
          :style="{
          color: text ? '#52c41a' : '',
          fontSize: '1.2rem',
        }"
        />
      </span>

      <span slot='use_tag' slot-scope='text'>
       <a-tag :color="text === 'Y' ? 'green' : 'red'">
          {{ text === 'Y' ? '사용' : '미사용' }}
        </a-tag>
      </span>

      <!--  사용여부    -->
      <span slot='is_delete' slot-scope='text'>
        <a-icon
          :type="`${ !text ? 'check' : 'close'}-circle`"
          theme='filled'
          :style="{
          color: !text ? '#52c41a' : '',
          fontSize: '1.2rem',
        }"
        />
      </span>

      <!--  id, text, color 로 이루어진 obj   -->
      <span slot='info_obj' slot-scope='text'>
        <a-tag :color='text.color'>
          {{ text.text }}
        </a-tag>
      </span>

      <!--  id, text, color 로 이루어진 obj   -->
      <span slot='info_obj_text' slot-scope='text'>
        {{ text.text }}
      </span>

      <!--  이메일 수신 여부  -->
      <span slot='notice' slot-scope='text'>
        <a-tag :color="text ? 'blue' : 'red'">
          {{ text ? '수신' : '거부' }}
        </a-tag>
      </span>

      <!--  금액    -->
      <div slot='price' slot-scope='text' class='text-right'>
        {{ text }} 원
      </div>

      <!--  금액    -->
      <div slot='count' slot-scope='text' class='text-right'>
        {{ text }} 개
      </div>

      <!--  category-depth   -->
      <span slot='depth1' slot-scope='text'>
        <a-tag :color="text === '-' ? '' : 'pink'">
          {{ text }}
        </a-tag>
      </span>

      <span slot='depth2' slot-scope='text'>
        <a-tag :color="text === '-' ? '' : 'blue'">
          {{ text }}
        </a-tag>
      </span>

      <span slot='depth3' slot-scope='text'>
        <a-tag :color="text === '-' ? '' : 'green'">
          {{ text }}
        </a-tag>
      </span>

      <!--  등록일    -->
      <span slot='created_at' slot-scope='text, record'>
        {{ getDateFormat(text, record.date_format) }}
      </span>

      <!--  충전일    -->
      <span slot='deposit_date' slot-scope='text, record'>
        {{ getDateFormat(text, record.date_format) }}
      </span>

      <!--  기능    -->
      <span slot='actions' slot-scope='text, record'>
        <template v-for='(item, idx) in actionButtonList'>
          <a-button
            :key='idx'
            size='small'
            :style="{ margin: 'auto 5px', visibility:  (item.key === 'newsReload' &&  !record.news_id) ? 'hidden' : 'visible'}"
            @click='item.click(record)'
          >
            {{ item.label }}
          </a-button>
        </template>
      </span>

      <!--  메일 전송 기능    -->
      <span slot='mail' slot-scope='text, record'>
        <a-popconfirm
          v-if="record.company === 'TDI'"
          title='구독자에게 공지사항 업로드 안내 메일을 전송하시겠습니까?'
          ok-text='전송'
          cancel-text='취소'
          @confirm='sendMail(record)'
          @cancel='confirm = false'
        >
          <a-button
            size='small'
            :loading='loading'
            :style="{ marginRight: '10px' }"
          >
            전송하기
          </a-button>
        </a-popconfirm>
      </span>
    </a-table>

    <a-pagination
      :disabled='loading'
      v-model='pagination.current'
      :total='pagination.total'
      :defaultPageSize='pagination.limit'
      :style="{ textAlign: 'center', marginTop: '20px' }"
    />
  </div>
</template>

<script>

export default {
  props: {
    columns: {
      type: Array,
      require: true,
      default: () => {
        return [];
      }
    },
    tableList: {
      type: Array,
      require: true,
      default: () => {
        return [];
      }
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          current: 1,
          total: 1
        };
      }
    },
    actionList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    loading: Boolean,
    btnDisabled: Boolean
  },
  computed: {
    actionButtonList() {
      return this.actionList.map(item => {
        return this.actionOptions[item];
      });
    }
  },
  watch: {
    'pagination.current'() {
      this.initSelectedList();
      this.$emit('pagination-change', this.pagination);
    },
    selectedList(list) {
      this.$emit('selected', list);
    }
  },
  data() {
    return {
      search: '',
      selectedList: [],
      default_date_format: 'YYYY년 MM월 DD일',
      actionOptions: {
        detail: {
          key: 'detail',
          label: '상세',
          click: (rowData) => this.$emit('open-modal', rowData)
        },
        newsReload: {
          key: 'newsReload',
          label: '갱신',
          click: (rowData) => this.$emit('reload-data', rowData)
        }
      }
    };
  },
  mounted() {
    this.setCheckbox();
  },
  methods: {
    setCheckbox() {
      const root = this;

      this._.forEach(this.tableList, (item) => {
        const labelEl = document.createElement('label');
        labelEl.setAttribute('for', `checkboxLabel-${item.id}`);
        const checkBoxEl = document.querySelector(`#checkbox-${item.id} input[type='checkbox']`);
        if (checkBoxEl) {
          checkBoxEl.setAttribute('id', `checkboxLabel-${item.id}`);
          checkBoxEl.appendChild(labelEl);
        } else {
          setTimeout(function() {
            root.setCheckbox();
          }, 1500);
          return false;
        }
      });
    },
    initSelectedList() {
      this.selectedList = [];
    },
    // 날짜 포맷
    getDateFormat(date, text) {
      let formatText = text || this.default_date_format;
      return date ? this.$moment(date).format(formatText) : '-';
    },
    // 복사
    copyToClipboard(text) {
      let msg = '클립보드에 복사를 실패하였습니다.';
      let type = 'error';
      const textArea = document.createElement('textarea');
      try {
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        const successful = document.execCommand('copy');

        if (successful) {
          msg = '클립보드에 복사되었습니다.';
          type = 'success';
        }
      } catch (err) {
        console.error(err);
      } finally {
        if (textArea.parentNode) textArea.parentNode.removeChild(textArea);

        this.$notification[type]({
          message: '클립보드 복사',
          description: msg
        });
      }
    },
    openModal(rowData) {
      this.$emit('open-modal', rowData);
    },
    sendMail(rowData) {
      this.$emit('send-mail', rowData);
    }
  }
};
</script>
