const ADMIN = {
  theme: {
    mode: {
      DARK: 'dark',
      LIGHT: 'light',
      NIGHT: 'night'
    }
  }
};

module.exports = ADMIN;
