<template>
  <a-modal
    v-model='visible'
    :on-cancel='close'
    :width="'80%'"
    :title='title'
    :bodyStyle="{ position: 'relative' }"
  >
    <a-form-model
      v-if='modal'
      layout='horizontal'
      ref='form'
      :model='form'
      :rules='rules'
      v-bind='formItemLayout'
    >
      <!--   발송한 이메일   -->
      <template v-if="mode !== 'edit'">
        <a-descriptions
          title='발송 정보'
          bordered
        >
          <a-descriptions-item label='제목' :span='3'>
            {{ form.title }}
          </a-descriptions-item>

          <a-descriptions-item label='서비스사' :span='2'>
            <a-tag :color='form.coalition.color'>
              {{ form.coalition.text }}
            </a-tag>
          </a-descriptions-item>

          <a-descriptions-item label='발송 그룹'>
            <div class='row items-center'>
              {{ form.group_info.text }}
              <div class='col-grow text-right'>
                <a-button
                  type='primary'
                  :loading='loading'
                  @click='openModal()'
                >
                  회원 목록
                </a-button>
              </div>
            </div>
          </a-descriptions-item>

          <a-descriptions-item label='발송 상태' :span='2'>
            <a-tag :color='form.status_info.color'>
              {{ form.status_info.text }}
            </a-tag>
          </a-descriptions-item>

          <a-descriptions-item label='발송일'>
            {{ dateFormat }}
          </a-descriptions-item>


          <a-descriptions-item
            label='내용'
            :span='3'
          >
            <div :style="{
              minHeight: '100px'
            }">
              <viewer
                :initialValue='editorText'
                height='500px'
              />
            </div>
          </a-descriptions-item>
        </a-descriptions>

      </template>
      <!--   이메일 발송   -->
      <template v-else>
        <a-descriptions title='서비스사' />

        <a-form-model-item>
          <a-radio-group
            v-model='form.service'
            :disabled="mode !== 'edit'"
          >
            <a-radio-button
              v-for='(item, index) in filterdCoalitionOption'
              :key='index'
              :value='item.id'
            >
              {{ item.text }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>

        <a-descriptions title='발송 그룹' class='margin-top-md' />

        <a-radio-group v-model='form.group'>
          <a-radio-button
            v-for='(item, index) in groupOption'
            :key='index'
            :value='item.id'
          >
            {{ item.text }}
          </a-radio-button>
        </a-radio-group>

        <a-button type='primary' @click='openModal()' class='margin-left-md'>
          회원 목록
        </a-button>

        <a-descriptions title='기본 정보' class='margin-top-md' />

        <a-form-model-item prop='title'>
          <a-input addon-before='제목' v-model='form.title' />
        </a-form-model-item>

        <editor
          v-if='visible'
          ref='noticeEditor'
          :initialValue='editorText'
          :options='editorOptions'
          height='500px'
          initialEditType='wysiwyg'
          previewStyle='vertical'
          class='margin-top-md'
        />
      </template>
    </a-form-model>


    <template slot='footer'>
      <a-row>
        <a-col :span='24' :style="{ textAlign: 'right' }">
          <a-button key='back' @click='close'>닫기</a-button>
          <a-popconfirm
            v-if="mode === 'edit'"
            :visible='confirm'
            :title='`해당 그룹의 회원에게 이메일 전송을 하게 됩니다.\n 서비스사를 한번 더 확인하시기 바랍니다.`'
            ok-text='발송'
            cancel-text='취소'
            @confirm='submit'
            @cancel='confirm = false'
          >
            <a-button
              type='primary'
              :loading='loading'
              @click='confirm = true'
            >
              발송
            </a-button>
          </a-popconfirm>
        </a-col>
      </a-row>
    </template>

    <div v-if='loading' class='fit dim'>
      <a-spin />
    </div>

    <groupDialog
      :modal='showGroupModal'
      :group='form.group'
      :service='form.service'
      @close='showGroupModal = false'
    />
  </a-modal>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';

import { Editor, Viewer } from '@toast-ui/vue-editor';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import GroupDialog from './Group';

export default {
  props: {
    modal: Boolean,
    mode: String,
    data: Object,
    coalitionOption: Array,
    groupOption: Array
  },
  components: {
    editor: Editor,
    viewer: Viewer,
    groupDialog: GroupDialog
  },
  data() {
    return {
      loading: false,
      visible: false,
      confirm: false,
      showGroupModal: false,
      form: {},
      formItemLayout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 10 }
      },
      editorText: '',
      editorOptions: {
        plugins: [colorSyntax],
        hideModeSwitch: true
      },
      rules: {
        title: [
          {
            required: true,
            message: '제목은 필수입니다.',
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    modal(v) {
      this.reset();

      if (this.mode !== 'edit') {
        this.form = this._.cloneDeep(this.data);
        this.editorText = this.data.content;
      }

      this.visible = v;
    },
    visible(v) {
      if (!v) {
        this.$emit('close');
      }
    }
  },
  computed: {
    title() {
      return `이메일 발송`;
    },
    filterdCoalitionOption() {
      return this.coalitionOption.filter(item => !!item.id);
    },
    dateFormat() {
      return !this._.isEmpty(this.data) ? this.$moment(this.data.created_at).format(this.data.date_format) : '-';
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    /** 답변처리 **/
    reset() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }

      this.confirm = false;
      this.editorText = '';

      this.form = {
        title: '',
        contents: '',
        service: 'TDI',
        group: 'total',
        status: '',
        created_at: this.$moment().format('YYYY-MM-DD')
      };
    },
    /** 발송 **/
    sendEmail() {

      this.loading = true;
      this.form.contents = this.$refs.noticeEditor.invoke('getHTML');

      try {
        if (!this.confirm) return;

        this.$axios.post('mailing/send', this.form).then(res => {
          let rawData = res.data;

          let type = 'error';
          let message = '전송 실패';
          let description = '메일 전송이 실패하였습니다.';

          if (rawData.success) {
            type = 'success';
            message = '전송 완료';
            description = '메일 전송이 완료되었습니다.';
          }

          this.$notification[type]({
            message: message,
            description: description
          });

          if (rawData.success) {
            this.$emit('index');
            this.close();
          }
          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.'
        });
      }
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (!valid) {
          this.confirm = false;
          return;
        }

        this.sendEmail();
      });
    },
    openModal() {
      this.showGroupModal = true;
    }
  }
};
</script>
<style scoped>
.dim {
  position: absolute;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  top: 0;
  left: 0;
  z-index: 999;
}

.dim > * {
  position: absolute;
  top: calc(50% - 25px);
}
</style>
