<template>
  <div>
    <a-card :title='title' :style="{ marginTop: '20px' }">
      <div slot='extra'></div>
      
      <div class='margin-bottom-md'>
        <custom-filter :page='page' @search='search' />

        <a-row>
          <a-col :span='12'>
            <a-alert
              :message="'검색결과 : ' + filters.pagination.total + ' 개'"
              type='success'
              showIcon
              :style="{ width: '300px' }"
            />
          </a-col>
        </a-row>
      </div>

      <custom-table
        :table-list='data'
        :columns='columns'
        :pagination='filters.pagination'
        :loading='loading'
        :action-list="['detail']"
        @pagination-change='onPaginationChanged'
        @open-modal='openModal'
      />
    </a-card>

    <form-modal
      :modal='modal'
      :data='modalData'
      :mode='mode'
      :transactionStatusOption='transactionStatusOption'
      @index='getAccountTransaction'
      @close='modal = false'
    />
  </div>
</template>

<script>
import FormModal from './modals/Form/Index';
import { mapGetters } from 'vuex';
import { getOptions, getOptionByKey } from '@/utils/options';
import CustomTable from '@/views/components/CustomTable';
import CustomFilter from '@/views/components/CustomFilter';

export default {
  props: {
    type: String
  },
  components: { FormModal, CustomTable, CustomFilter },
  computed: {
    ...mapGetters({
      isMobileDevice: 'setting/isMobileDevice',
      getFilters: 'filters/getStateFilters'
    }),
    title() {
      return '계좌거래 관리';
    },
    filters() {
      return this.getFilters(this.page);
    }
  },
  data() {
    return {
      loading: false,
      modal: false,
      data: [],
      modalData: {},
      mode: '',
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          align: 'center',
          width: 50,
          ellipsis: true
        },
        {
          title: '회원',
          dataIndex: 'user_email',
          key: 'user_email',
          scopedSlots: { customRender: 'user_email' },
          align: 'center',
          ellipsis: false
        },
        {
          title: '상품명',
          dataIndex: 'product_name',
          key: 'product_name',
          align: 'center',
          ellipsis: false
        },
        {
          title: '상호명',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
          ellipsis: false
        },
        {
          title: '신청일',
          dataIndex: 'created_at',
          key: 'created_at',
          align: 'center',
          scopedSlots: { customRender: 'created_at' },
          ellipsis: false,
          width: 150
        },
        {
          title: '상태',
          dataIndex: 'status_info',
          key: 'status_info',
          scopedSlots: { customRender: 'info_obj' },
          align: 'center',
          ellipsis: false,
          width: 150
        },
        {
          title: '입금 확인일',
          dataIndex: 'deposit_date',
          key: 'deposit_date',
          scopedSlots: { customRender: 'deposit_date' },
          align: 'center',
          ellipsis: false,
          width: 150
        },
        {
          title: '세금계산서 발행여부',
          dataIndex: 'tax_bill',
          key: 'tax_bill',
          scopedSlots: { customRender: 'use' },
          align: 'center',
          ellipsis: false,
          width: 150
        },
        {
          title: '기능',
          dataIndex: 'actions',
          key: 'actions',
          align: 'center',
          scopedSlots: { customRender: 'actions' },
          width: 150,
          ellipsis: true
        }
      ],
      transactionStatusOption: getOptions('transactionStatusOption', true),
      page: 'transaction'
    };
  },
  mounted() {
    this.getAccountTransaction();
  },
  methods: {
    /** 페이지 변경시 **/
    onPaginationChanged(pagination) {
      this.filters.pagination.current = pagination ? pagination.current : this.filters.pagination.current;
      this.search();
    },
    /** 검색 **/
    search(filterData) {
      if (filterData && this.filters.pagination.current !== 1) {
        this.filters.pagination.current = 1;
        return;
      }

      this.getAccountTransaction();
    },
    getAccountTransaction() {
      this.loading = true;

      let params = {
        ...this.filters,
        page: this.filters.pagination.current,
        page_rows: this.filters.pagination.limit
      };

      try {
        this.$axios.get('account/transaction', { params: params }).then(res => {
          let rawData = res.data;

          this.filters.pagination.total = rawData.total;
          this.filters.pagination.current = rawData.current_page;

          this.data = rawData.data.map((item, index) => {
            return {
              key: index.toString(),
              ...item,
              id: item.id,
              status_info: getOptionByKey(
                this.transactionStatusOption,
                item.status
              ),
              product_name: item.product ? item.product.name : '-',
              user_email: item.user ? item.user.email : '-', // 회원 - 이메일
              date_format: 'YYYY년 MM월 DD일'
            };
          });

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
      }
    },
    openModal(record = null) {
      this.mode = record ? 'edit' : 'store';
      this.modalData = record;
      this.modal = true;
    }
  }
};
</script>
