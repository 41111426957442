<template>
  <a-layout :class="isMobileDevice ? 'dd-layout-mobile' : 'dd-layout'">
    <a-row type="flex" justify="center">
      <a-col :span="24">
        <BlankLayout v-if="!authenticated" />
        <ConsoleLayout v-else />
      </a-col>
    </a-row>
  </a-layout>
</template>

<script>
import ConsoleLayout from './views/layouts/Console';
import BlankLayout from './views/layouts/Blank';
import { mapGetters, mapMutations } from 'vuex';
import themeUtil from '@/utils/themeUtil';

export default {
  components: { ConsoleLayout, BlankLayout },
  computed: {
    ...mapGetters({
      authenticated: 'account/authenticated',
      isMobileDevice: 'setting/isMobileDevice',
      isMobile: 'setting/isMobile',
      theme: 'setting/getTheme',
      wide: 'setting/getWide',
    }),
  },
  data() {
    return {};
  },
  watch: {},
  mounted() {
    //스크린 사이즈
    window.addEventListener('resize', () => {
      this.setScreen({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });

    //테마 로컬세팅
    let color = localStorage.getItem('theme-color');
    let mode = localStorage.getItem('theme-mode');
    let wide = localStorage.getItem('theme-wide');

    if (!color) {
      color = this.theme.color;
    }

    if (!mode) {
      mode = this.theme.mode;
    }

    if (wide) {
      this.setWide(JSON.parse(wide));
    }

    this.setTheme({
      color: color,
      mode: mode,
    });

    themeUtil.changeThemeColor(color, mode);
  },
  methods: {
    ...mapMutations('setting', ['setScreen', 'setTheme', 'setWide']),
  },
};
</script>

<style>
@import './assets/css/index.css';
</style>
