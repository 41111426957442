<template>
  <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules">
    <a-form-model-item has-feedback label="현재 비밀번호" prop="password">
      <a-input v-model.number="ruleForm.password" autocomplete="off" />
    </a-form-model-item>
    <a-form-model-item has-feedback label="새 비밀번호" prop="newPassword">
      <a-input
        v-model="ruleForm.newPassword"
        type="password"
        autocomplete="off"
      />
    </a-form-model-item>
    <a-form-model-item
      has-feedback
      label="새 비밀번호 확인"
      prop="newPasswordConfirm"
    >
      <a-input
        v-model="ruleForm.newPasswordConfirm"
        type="password"
        autocomplete="off"
      />
    </a-form-model-item>
    <a-form-model-item :style="{ marginTop: '20px' }">
      <a-button type="primary" size="small" @click="submit('ruleForm')">
        변경하기
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
export default {
  data() {
    let validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('비밀번호를 입력해주세요.'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    let validatePasswordConfirm = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('비밀번호를 입력해주세요.'));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error('새 비밀번호와 맞지 않습니다.'));
      } else {
        callback();
      }
    };

    return {
      ruleForm: {
        password: '',
        newPassword: '',
        newPasswordConfirm: ''
      },
      rules: {
        password: [{ validator: validatePassword, trigger: 'change' }],
        newPassword: [
          {
            validator: validatePassword,
            trigger: 'change'
          }
        ],
        newPasswordConfirm: [
          { validator: validatePasswordConfirm, trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    submit(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          //TODO
          //axios
        } else {
          return false;
        }
      });
    }
  }
};
</script>
