<template>
  <div class='filter-wrap'>
    <template v-for='(item, index) in localFilterList'>
      <!-- type : select     -->
      <div
        :key='`${item.type}-${index}`'
        v-if="item.type === 'select'"
        class='margin-right-sm margin-bottom-sm'
        :style="{
            display: 'inline-block',
            width: '100%',
            maxWidth: '300px',
            ...item.style
          }"
      >
        <a-input-group class='row items-top'>
          <span
            class='ant-input-group-addon col-auto'
            :style="{
              width: 'auto',
              height: '32px',
              paddingTop: '3px',
            }"
          >
            {{ item.name }}
          </span>
          <a-select
            class='col-grow'
            placeholder='선택'
            v-model='filters[item.key]'
            @input='onSearch'
          >
            <a-select-option
              v-for='(selectItem, idx) in item.options'
              :key='`${item.type}-select${index}-${idx}`'
              :value='selectItem.id'
            >
              {{ selectItem.text }}
            </a-select-option>
          </a-select>
        </a-input-group>
      </div>
      <!-- type : input     -->
      <template v-if="item.type === 'input'">
        <!-- addon type : input     -->
        <template v-if="item.addon !== 'select'">
          <a-input
            :key='`${item.type}-${index}`'
            v-model='filters[item.key]'
            @pressEnter='onSearch'
            :addon-before='item.name'
            :style="{
                width: '300px',
                marginRight: '10px',
                marginBottom: '10px',
                display: 'inline-block',
                ...item.style
              }"
          />
        </template>
        <!-- addon type : select     -->
        <a-input-group
          v-else
          :key='`${item.type}-select${index}`'
          compact
          class='margin-right-sm margin-bottom-sm'
          :style="{
            display: 'inline-block',
            maxWidth: '350px',
            width: '100%',
            verticalAlign: '-webkit-baseline-middle',
            ...item.style
          }"
        >
          <a-select v-model='item.addonValue' :style="{ width: '80px' }">
            <a-select-option
              v-for='(addonItem, idx) in item.addonOptions'
              :key='`${item.type}-addonSelect${index}-${idx}`'
              :value='addonItem.id'
            >
              {{ addonItem.text }}
            </a-select-option>

          </a-select>

          <a-input
            v-model='filters[item.key]'
            @pressEnter='onSearch'
            :style="{
              display: 'inline-block',
              width: 'calc(100% - 80px)',
              ...item.style
            }"
          />
        </a-input-group>

      </template>

      <!-- type : radio     -->
      <template v-if="item.type === 'radio'">
        <a-radio-group
          :key='`${item.type}-${index}`'
          v-model='filters[item.key]'
          class='margin-right-sm'
        >
          <a-radio-button
            v-for='(radioItem, idx) in item.options'
            :key='idx'
            :value='radioItem.id'
          >
            {{ radioItem.text }}
          </a-radio-button>
        </a-radio-group>
      </template>

      <!-- type : dateRange     -->
      <template v-if="item.type === 'dateRange'">
        <a-range-picker
          :key='`${item.type}-${index}`'
          v-model='filters[item.key]'
          class='margin-right-sm margin-bottom-sm'
          :style="{
            display: 'inline-block',
            maxWidth: '400px',
            verticalAlign: 'middle',
            width: '100%',
          }"
          :format='item.format'
          :ranges="{
            '오늘': [currentDate, currentDate],
            '한달': [subtractMonthDate, currentDate] ,
            '일년': [subtractYearDate, currentDate ] ,
          }"
        >
          <a-input-group compact>
            <a-input
              :value='getFormatDate(filters[item.key][0], item.format)'
              class='readonly'
              :addon-before='item.name'
              placeholder='조회 사적알'
              :style="{
                display: 'inline-block',
                maxWidth: '210px',
              }"
              disabled
            />
            <a-input
              :style="{
                borderLeft: 0,
                pointerEvents: 'none',
                display: 'inline-block',
                maxWidth: '30px',
              }"
              :value='`~`'
              disabled
            />
            <a-input
              :value='getFormatDate(filters[item.key][1], item.format)'
              class='readonly'
              placeholder='조회 종료일'
              :style="{
                borderLeft: 0,
                display: 'inline-block',
                maxWidth: '160px',
              }"
              disabled
            />
          </a-input-group>
        </a-range-picker>

      </template>

      <!-- type : date     -->
      <template v-if="item.type === 'date'"></template>

      <template v-if="item.type === 'range'">
        <a-input-group
          :key='`${item.type}-${index}`'
          compact
          class='margin-right-sm margin-bottom-sm'
          :style="{
            display: 'inline-block',
            maxWidth: '400px',
            verticalAlign: 'middle',
            width: '100%',
          }"
        >
          <a-input
            v-model='filters[item.key][0]'
            :addon-before='item.name'
            :style="{
              display: 'inline-block',
              maxWidth: '210px',
            }"
            :placeholder='`최소 ${item.name}`'
            type='number'
          />
          <a-input
            :style="{
              borderLeft: 0,
              pointerEvents: 'none',
              display: 'inline-block',
              maxWidth: '30px',
            }"
            :value='`~`'
            disabled
          />
          <a-input
            v-model='filters[item.key][1]'
            :style="{
              borderLeft: 0,
              display: 'inline-block',
              maxWidth: '160px',
            }"
            :placeholder='`최대 ${item.name}`'
            type='number'
          />
        </a-input-group>
      </template>
    </template>
    <a-button
      type='primary'
      @click='onSearch()'
      :loading='loading'
      :disabled='loading'
    >
      검색
    </a-button>
  </div>
</template>

<script>
import { CustomFilterItem } from '@/model/CustomFilterItem';
import { mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    page: String,
    paginationLimit: {
      type: Number,
      require: false,
      default: () => {
        return 20;
      }
    },
    loading: Boolean
  },
  computed: {
    ...mapGetters({
      getFilters: 'filters/getStateFilters',
      getFilterList: 'filters/getStateFilterList'
    }),
    title() {
      return '사용자 관리';
    },
    filterList() {
      const root = this;
      const list = root.getFilterList(root.page);
      const filtersData = {
        ...root.payload
      };

      root._.forEach(list, item => {
        const convData = new CustomFilterItem(item);
        const key = convData.addonValue ? convData.addonValue : convData.key;
        filtersData[key] = convData.value;
      });

      root.setStateFilters({ key: root.page, value: filtersData });

      return list;
    },
    filters() {
      return this.getFilters(this.page);
    },
    localFilterList() {
      return this.filterList.map(item => new CustomFilterItem(item));
    }
  },
  data() {
    return {
      payload: {
        pagination: {
          current: 1,
          total: 0,
          limit: 20
        }
      },
      currentDate: this.$moment(new Date()),
      subtractYearDate: this.$moment(new Date()).subtract(1, 'year'),
      subtractMonthDate: this.$moment(new Date()).subtract(1, 'month')
    };
  },
  created() {
    this.payload.pagination.limit = this.paginationLimit;
  },
  mounted() {
  },
  methods: {
    ...mapMutations('filters', ['setStateFilters', 'setStateFilterList']),
    onSearch() {
      const filtersData = this._.cloneDeep(this.filters);

      this._.forEach(this.localFilterList, item => {
        const key = item.addonValue ? item.addonValue : item.key;
        let val = filtersData[item.key];

        if (['date', 'dateRange'].includes(item.type)) {
          if (item.type === 'date') {
            val = this.getFormatDate(val, item.format);
          }

          if (item.type === 'dateRange') {
            val = val.map(data => this.getFormatDate(data, item.format));
          }
        }

        filtersData[key] = val;
      });

      this.setStateFilters({ key: this.page, value: filtersData });

      this.$emit('search', {
        ...this.filters,
        ...this.payload
      });
    },
    getFormatDate(date, dateFormat) {
      return this.$moment(date).format(dateFormat);
    }
  }
};
</script>


<style>
.filter-wrap input::-webkit-outer-spin-button,
.filter-wrap input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.filter-wrap input[type=number] {
  -moz-appearance: textfield;
}
</style>
