<template>
  <a-modal v-model="visible" :title="title" :on-cancel="close" :width="1300">
    <template slot="footer">
      <a-button key="back" @click="close"> 닫기 </a-button>
    </template>

    <a-tabs v-model="tab" :tab-position="isMobile ? 'top' : 'left'">
      <a-tab-pane key="account" tab="기본정보">
        <div :style="{ padding: '0 20px' }">
          <account-component :user="user" @index="$emit('index')" />
        </div>
      </a-tab-pane>

      <a-tab-pane key="subscription" tab="구독 및 결제">
        <div :style="{ padding: '0 20px' }">
          <subscription-component
            :user="user"
            :tab="tab"
            @index="$emit('index')"
          />
        </div>
      </a-tab-pane>

      <a-tab-pane key="send" tab="메일 보내기"> </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import AccountComponent from './Account.vue';
import SubscriptionComponent from './Subscription.vue';
import { mapGetters } from 'vuex';

export default {
  props: {
    modal: Boolean,
    user: Object,
  },
  components: {
    AccountComponent,
    SubscriptionComponent,
  },
  computed: {
    ...mapGetters({
      isMobile: 'setting/isMobile',
    }),
    title() {
      return this.user.name + ' (' + this.user.email + ') 정보';
    },
  },
  watch: {
    modal(v) {
      this.visible = v;
    },
    visible(v) {
      if (!v) {
        this.$emit('close');
      }
    },
    user() {
      this.tab = 'account';
    },
  },
  data() {
    return {
      tab: 'account',
      visible: false,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style>
#config .ant-form-item {
  margin-bottom: 0;
}
</style>
