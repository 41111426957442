<template>
  <a-layout
    :class="isMobileDevice ? 'dd-layout-mobile' : 'dd-layout'"
    style="justify-content: center; background: #001529"
  >
    <a-card class="dd-login">
      <template slot="title">
        <div :style="{ textAlign: 'center' }">
          <img
            :src="require('../../assets/img/' + logo + '.png')"
            :width="150"
            alt="logo"
          />

          <div :style="{ marginTop: '10px' }">
            <a-tag> 관리자 </a-tag>
          </div>
        </div>
      </template>
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-input
            v-decorator="[
              'email',
              {
                rules: [{ required: true, message: '이메일을 입력해주세요.' }],
              },
            ]"
            size="large"
            placeholder="이메일"
          >
            <a-icon
              slot="prefix"
              type="user"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            v-decorator="[
              'password',
              {
                rules: [
                  { required: true, message: '비밀번호를 입력해주세요.' },
                ],
              },
            ]"
            type="password"
            size="large"
            placeholder="비밀번호"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            size="large"
            style="width: 100%"
            html-type="submit"
            :loading="loading"
          >
            로그인
          </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </a-layout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState('setting', ['theme']),
    ...mapGetters({
      screen: 'setting/getScreen',
      isMobile: 'setting/isMobile',
      isMobileDevice: 'setting/isMobileDevice',
      wide: 'setting/getWide',
    }),
    logo() {
      return this.theme.mode === 'night' ? 'datadragon_w' : 'datadragon';
    },
  },
  data() {
    return {
      loading: false,
      form: this.$form.createForm(this, { name: 'login' }),
    };
  },
  methods: {
    ...mapActions({
      login: 'account/login',
    }),
    check() {
      this.form.validateFields(err => {
        if (!err) {
          console.info('success');
        }
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, form) => {
        if (!err) {
          this.loading = true;

          this.login(form)
            .then(res => {
              if (res.data.access_token) {
                this.$router.push('/user');
              } else {
                this.$notification.open({
                  message: '로그인 실패',
                  description: '이메일과 비밀번호를 확인해주세요.',
                  icon: <a-icon type="issues-close" style="color: #ff5f5f" />,
                });
              }
              this.loading = false;
            })
            .catch(() => {
              this.$notification.open({
                message: '로그인 실패',
                description: '이메일과 비밀번호를 확인해주세요.',
                icon: <a-icon type="issues-close" style="color: #ff5f5f" />,
              });

              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
<style>
.dd-login {
  max-width: 500px;
  width: 90%;
  margin: 0 auto;
}
</style>
