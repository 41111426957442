var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{style:({ marginBottom: '20px' })},[_c('div',{style:({
        color: 'inherit',
        fontSize: '14px',
        marginTop: '10px',
        marginBottom: '10px',
      })},[_vm._v(" 테마 색상 ")]),_c('div',{style:({ display: 'flex' })},_vm._l((_vm.palettes),function(palette,index){return _c('div',{key:index,staticClass:"theme-color",style:({
          backgroundColor: palette,
          marginBottom: '5px',
        }),on:{"click":function($event){return _vm.setThemeColor(palette)}}},[(_vm.color === palette)?_c('a-icon',{attrs:{"type":"check"}}):_vm._e()],1)}),0)]),_c('div',[_c('div',{style:({
        color: 'inherit',
        fontSize: '14px',
        marginTop: '10px',
        marginBottom: '10px',
      })},[_vm._v(" 와이드 활성화 (PC만 가능) ")]),_c('a-switch',{attrs:{"default-checked":_vm.wide},on:{"change":_vm.wideChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }