<template>
  <div style='position: relative'>
    <div
      ref='hotPlaceMap'
      id='hotPlaceMap'
      style='width: 100%; height: 500px'
    />
    <div style='position: absolute; top: 20px; right: 20px; z-index: 1'>
      <a-auto-complete
        :loading='loading'
        @search='search'
        @select='selectedPlace'
        label='위치 검색'
        background-color='#fff'
        clearable
        class='autocomplete-search'
        style='width: 280px'
      >
        <template slot='dataSource'>
          <a-select-option
            v-for='(item, index) in searchedPlaces'
            :key='index'
            :value='item.value'
          >
            {{ item.text }}
          </a-select-option>
        </template>

        <a-input :allowClear='true'>
          <a-icon slot='suffix' type='search' class='certain-category-icon' />
        </a-input>
      </a-auto-complete>
    </div>
    <div style='position: absolute; right: 20px; bottom: 20px; z-index: 1'>
      <a-button
        :disabled='drawingFlag'
        small
        depressed
        type='primary'
        icon='gateway'
        @click='startPolygon()'
      >
        그리기
      </a-button>
      <a-button
        small
        depressed
        icon='redo'
        class='margin-left-sm'
        @click='resetPolygon()'
      >
        초기화
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['polygon'],
  components: {},
  data() {
    return {
      loading: false,
      map: '',
      manager: '',
      path: '',
      searchText: '',
      searchedPlace: null,
      searchedPlaces: [],
      polygons: [],
      drawingFlag: false
    };
  },
  mounted() {
    if (window.kakao && window.kakao.maps) {
      this.init();
    } else {
      const script = document.createElement('script');
      /* global kakao */
      script.onload = () => {
        kakao.maps.load(this.init);
      };
      script.src = `//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=d21e58b1071c7114a01367eeec4b6539&libraries=services,drawing`;
      document.head.appendChild(script);
    }
  },
  methods: {
    init() {
      const container = this.$refs.hotPlaceMap;
      if (!container) return;

      let center = [37.4881783441919, 127.01159107405267]; // 회사를 중심

      let options = {
        center: new kakao.maps.LatLng(center[0], center[1]),
        minLevel: 1,
        maxLevel: 13,
        level: 5
      };

      this.map = new kakao.maps.Map(container, options);

      let zoomControl = new kakao.maps.ZoomControl();

      this.map.addControl(zoomControl, kakao.maps.ControlPosition.TOPLEFT);

      let drawingPolygon;

      this.manager = new kakao.maps.Drawing.DrawingManager({
        map: this.map,
        drawingMode: [
          // Drawing Manager로 제공할 그리기 요소 모드입니다
          kakao.maps.Drawing.OverlayType.POLYGON
        ],
        markerOptions: {
          // 마커 옵션입니다
          draggable: true, // 마커를 그리고 나서 드래그 가능하게 합니다
          removable: true // 마커를 삭제 할 수 있도록 x 버튼이 표시됩니다
        },
        guideTooltip: ['draw', 'drag', 'edit'],
        polygonOptions: {
          draggable: true,
          removable: true,
          editable: true,
          strokeWeight: 3,
          strokeColor: '#00a0e9',
          strokeOpacity: 1,
          strokeStyle: 'solid',
          fillColor: '#00a0e9',
          fillOpacity: 0.2,
          hintStrokeStyle: 'dash',
          hintStrokeOpacity: 0.5
        }
      });

      kakao.maps.event.addListener(this.map, 'startPolygon', () => {
        if (this.drawingFlag) return;

        this.drawingFlag = true;

        if (drawingPolygon) {
          const polygon = this.manager.getOverlays(
            kakao.maps.Drawing.OverlayType['POLYGON']
          )['polygon'];

          this.manager.remove(polygon[0]);

          drawingPolygon = null;
        }

        this.manager.select(kakao.maps.Drawing.OverlayType['POLYGON']);
      });

      // 다각형 초기화
      kakao.maps.event.addListener(this.map, 'resetPolygon', () => {
        this.manager.cancel(); // 그리기 취소

        if (drawingPolygon) {
          const polygon = this.manager.getOverlays(
            kakao.maps.Drawing.OverlayType['POLYGON']
          )['polygon'];

          this.manager.remove(polygon[0]);
          drawingPolygon = null;
        }

        this.path = [];
        this.drawingFlag = false;
      });

      kakao.maps.event.addListener(this.manager, 'state_changed', () => {
        const polygon = this.manager.getData(
          kakao.maps.Drawing.OverlayType['POLYGON']
        )['polygon'];

        drawingPolygon = polygon.length > 0 ? polygon[0] : null;
        this.path = drawingPolygon
          ? drawingPolygon.points.map(item => {
            return {
              lat: item.y, // 왜인지.. y값이 들어가야 맞음
              lon: item.x // 왜인지.. x값이 들어가야 맞음
            };
          })
          : [];
        this.drawingFlag = false;

        this.$emit('change-polygon-path', this.path);
      });

      if (!!this.polygon && this.polygon.length > 0) {
        this.showPolygon();
      }
    },
    /** 위치검색 **/
    search(val) {
      this.searchedPlaces = [];

      if (!val || val.length < 2) return false;

      this.loading = true;

      let ps = new kakao.maps.services.Places();

      ps.keywordSearch(val, (results, status) => {
        if (status === kakao.maps.services.Status.OK) {
          results.map(result => {
            this.searchedPlaces.push({
              text: result.place_name + ' (' + result.address_name + ')',
              value: `${result.y},${result.x}`
            });
          });
        }
      });

      this.loading = false;
    },
    /** 위치검색 - 선택 **/
    selectedPlace(val) {
      if (!val) return;

      const poi = val.split(',');

      if (!poi[0] || !poi[1]) return;

      this.map.setCenter(new kakao.maps.LatLng(poi[0], poi[1]));
    },
    startPolygon() {
      kakao.maps.event.trigger(this.map, 'startPolygon', '다각형 그리기');
    },
    /** 다각형 리셋 **/
    resetPolygon() {
      kakao.maps.event.trigger(this.map, 'resetPolygon', '다각형 리셋');
    },
    /** 다각형 표시 **/
    showPolygon() {
      if (!this.polygon) return;

      let polygonPath = [];
      let points = [];

      this.polygon.forEach(item => {
        let latlng = new kakao.maps.LatLng(item.lat, item.lon);

        polygonPath.push(latlng);

        points.push({
          x: item.lat * 1,
          y: item.lon * 1
        });
      });

      this.map.setCenter(this.centroid(points));

      this.manager.put(kakao.maps.Drawing.OverlayType['POLYGON'], polygonPath);
    },
    /** 다각형 중심좌표 구하기 **/
    centroid(points) {
      let i, j, len, p1, p2, f, area, x, y;

      area = x = y = 0;

      for (i = 0, len = points.length, j = len - 1; i < len; j = i++) {
        p1 = points[i];
        p2 = points[j];

        f = p1.y * p2.x - p2.y * p1.x;
        x += (p1.x + p2.x) * f;
        y += (p1.y + p2.y) * f;
        area += f * 3;
      }
      return new kakao.maps.LatLng(x / area, y / area);
    }
  }
};
</script>
