<template>
  <a-modal
    v-model='visible'
    :on-cancel='close'
    :width="'80%'"
    :title='title'
    :dialogStyle="{maxWidth: '900px'}"
  >
    <a-form-model
      v-if='modal'
      layout='horizontal'
      ref='form'
      v-bind='formItemLayout'
    >
      <a-descriptions bordered title='기본정보'>
        <a-descriptions-item label='회원 계정' :span='2'>
          {{ data.user.email }}
        </a-descriptions-item>

        <a-descriptions-item label='회원 이름'>
          {{ data.user.name }}
        </a-descriptions-item>
      </a-descriptions>

      <a-descriptions bordered class='margin-top-md' title='요청 정보'>
        <a-descriptions-item label='요청일' :span='2'>
          {{ data.created_at }}
        </a-descriptions-item>

        <a-descriptions-item label='이메일 수신 여부'>
          <a-tag :color="data.notice ? 'blue' : 'red'">
            {{ data.notice ? '수신' : '거부' }}
          </a-tag>
        </a-descriptions-item>

        <a-descriptions-item label='제목' :span='2'>
          {{ data.title }}
        </a-descriptions-item>

        <a-descriptions-item label='카테고리'>
          {{ data.category.name }}
        </a-descriptions-item>

        <a-descriptions-item label='내용'>
          <a-textarea v-model='data.description' autoSize disabled class='readonly border-top-none' />
        </a-descriptions-item>
      </a-descriptions>
    </a-form-model>

    <template slot='footer'>
      <a-row>
        <a-col :span='24' :style="{ textAlign: 'right' }">
          <a-button key='back' @click='close'>닫기</a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';

export default {
  props: {
    modal: Boolean,
    mode: String,
    data: Object
  },
  data() {
    return {
      loading: false,
      visible: false,
      formItemLayout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 10 }
      }
    };
  },
  watch: {
    modal(v) {
      this.visible = v;
    },
    visible(v) {
      if (!v) {
        this.$emit('close');
      }
    }
  },
  computed: {
    title() {
      return '트렌드 요청 상세';
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>
