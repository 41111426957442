<template>
  <a-card title="설정" id="config">
    <a-tabs
      default-active-key="account"
      :tab-position="isMobile ? 'top' : 'left'"
    >
      <a-tab-pane key="account" tab="기본정보">
        <AccountComponent />
      </a-tab-pane>

      <a-tab-pane key="setting" tab="설정">
        <SettingComponent />
      </a-tab-pane>

      <a-tab-pane key="password" tab="관리자 비밀번호 변경">
        <PasswordChangeComponent />
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>

<script>
import AccountComponent from './Account.vue';
import PasswordChangeComponent from './PasswordChange.vue';
import SettingComponent from './Setting.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    AccountComponent,
    PasswordChangeComponent,
    SettingComponent,
  },
  computed: {
    ...mapGetters({
      isMobile: 'setting/isMobile',
    }),
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
#config .ant-form-item {
  margin-bottom: 0;
}
</style>
