<template>
  <a-modal
    v-model="visible"
    :title="title"
    :on-cancel="close"
    :width="'80%'"
    :dialog-style="{ top: '20px' }"
  >
    <a-form-model
      layout="horizontal"
      ref="form"
      :model="form"
      :rules="rules"
      v-bind="formItemLayout"
    >
      <a-descriptions title="사용여부" />
      <a-form-model-item>
        <a-radio-group v-model="form.use">
          <a-radio-button value="Y"> 사용 </a-radio-button>
          <a-radio-button value="N"> 사용안함 </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-descriptions title="기본정보" :style="{ marginTop: '20px' }" />

      <a-form-model-item>
        <a-radio-group v-model="form.company">
          <a-radio-button value="TDI"> TDI </a-radio-button>
          <a-radio-button value="adego"> adeGO </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item>
        <a-input addon-before="아이콘 타이틀" v-model="form.icon_title" />
      </a-form-model-item>

      <a-form-model-item>
        <a-input addon-before="제목" v-model="form.title" />
      </a-form-model-item>

      <a-form-model-item>
        <a-input addon-before="부제목" v-model="form.sub_title" />
      </a-form-model-item>
    </a-form-model>

    <div :style="{ marginTop: '20px' }">
      <a-descriptions title="내용" />

      <editor
        v-if="visible"
        ref="noticeEditor"
        :initialValue="editorText"
        :options="editorOptions"
        height="650px"
        initialEditType="wysiwyg"
        previewStyle="vertical"
      />
    </div>

    <template slot="footer">
      <a-row>
        <a-col :span="12" :style="{ textAlign: 'left' }">
          <!--
          <a-popconfirm
            v-if="mode === 'edit'"
            :visible="confirm"
            title="해당 공지를 삭제하시겠습니까 ? 삭제시 복구가 불가능합니다."
            ok-text="삭제"
            cancel-text="취소"
            @confirm="deleteNotice"
            @cancel="confirm = false"
          >
            <a-button
              type="danger"
              @click="confirm = true"
              ghost
              :loading="loading"
            >
              삭제
            </a-button>
          </a-popconfirm>
          -->
        </a-col>
        <a-col :span="12" :style="{ textAlign: 'right' }">
          <a-button key="back" @click="close"> 닫기 </a-button>
          <a-button type="primary" @click="submit()" :loading="loading">
            {{ modeText }}
          </a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';

import { Editor } from '@toast-ui/vue-editor';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';

export default {
  props: {
    modal: Boolean,
    mode: String,
    data: Object,
  },
  components: {
    editor: Editor,
  },
  data() {
    return {
      loading: false,
      visible: false,
      confirm: false,
      editorText: '',
      editorOptions: {
        plugins: [colorSyntax],
        hideModeSwitch: true,
      },
      form: {},
      formItemLayout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 10 },
      },
      rules: {
        icon_title: [
          {
            required: true,
            message: '아이콘 타이틀은 필수입니다.',
            trigger: 'change',
          },
        ],
        title: [
          {
            required: true,
            message: '제목은 필수입니다.',
            trigger: 'change',
          },
        ],
        sub_title: [
          {
            required: true,
            message: '부제목은 필수입니다.',
            trigger: 'change',
          },
        ],
      },
    };
  },
  watch: {
    modal(v) {
      this.reset();

      if (this.mode === 'edit') {
        this.form = {
          notice_id: this.data.id,
          icon_title: this.data.icon_title,
          title: this.data.title,
          sub_title: this.data.sub_title,
          use: this.data.use,
          company: this.data.company,
        };
        this.editorText = this.data.contents;
      }
      this.visible = v;
    },
    visible(v) {
      if (!v) {
        this.$emit('close');
      }
    },
  },

  computed: {
    title() {
      return '공지사항 ' + this.modeText;
    },
    modeText() {
      return this.mode === 'edit' ? '변경' : '등록';
    },
  },
  mounted() {},
  methods: {
    close() {
      this.$emit('close');
    },
    reset() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }

      this.confirm = false;
      this.editorText = '';

      this.form = {
        icon_title: '',
        title: '',
        sub_title: '',
        use: 'N',
        contents: '',
        company: 'TDI',
      };
    },
    setNotice() {
      this.loading = true;

      this.form.contents = this.$refs.noticeEditor.invoke('getHTML');

      try {
        this.$axios.post('notice/submit', this.form).then(res => {
          if (res.status === 200) {
            if (res.data.success) {
              this.$notification['success']({
                message: '등록 완료',
                description: res.data.message,
              });

              this.$emit('index');
              this.close();
            } else {
              this.$notification['error']({
                message: '등록 실패',
                description: res.data.message,
              });
            }
          }

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.',
        });
      }
    },
    deleteNotice() {
      this.loading = true;

      try {
        this.$axios.delete('notice' + this.data.id).then(res => {
          if (res.status === 200) {
            if (res.data.success) {
              this.$notification['success']({
                message: '삭제 완료',
                description: res.data.message,
              });

              this.$emit('index');
              this.close();
            } else {
              this.$notification['error']({
                message: '삭제 실패',
                description: res.data.message,
              });
            }
          }

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.',
        });
      }
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.setNotice();
        } else {
          return false;
        }
      });
    },
  },
};
</script>
