<template>
  <a-descriptions title="기본정보" bordered>
    <a-descriptions-item label="권한">
      {{ user.permission }}
    </a-descriptions-item>

    <a-descriptions-item label="가입일" :span="2">
      {{ createdAt }}
    </a-descriptions-item>

    <a-descriptions-item label="이메일">
      {{ user.email }}
    </a-descriptions-item>

    <a-descriptions-item label="이름" :span="2">
      {{ user.name }}
    </a-descriptions-item>

    <a-descriptions-item label="회사명">
      {{ user.company_name }}
    </a-descriptions-item>

    <a-descriptions-item label="사업자등록번호" :span="2">
      {{ user.company_license }}
    </a-descriptions-item>

    <a-descriptions-item label="담당자명">
      {{ user.company_name }}
    </a-descriptions-item>

    <a-descriptions-item label="담당자 연락처" :span="2">
      {{ user.company_license }}
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      user: 'account/getUser',
    }),
    createdAt() {
      return this.$moment(this.user.created_at).format('YYYY년 MM월 DD일');
    },
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
    };
  },
  methods: {},
};
</script>
