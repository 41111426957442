<template>
  <a-modal
    v-model='visible'
    :on-cancel='close'
    :width="'80%'"
    :title='title'
  >
    <a-form-model
      v-if='modal'
      layout='horizontal'
      ref='form'
      :model='form'
      :rules='rules'
      v-bind='formItemLayout'
    >
      <a-descriptions bordered>
        <template slot='title'>
          <div>
            기본정보
            <a-tag :color="data.coalition_name === 'TDI' ? 'blue' : 'orange'" class='margin-left-md'>
              {{ data.coalition_name }}
            </a-tag>
          </div>
        </template>

        <a-descriptions-item label='회원 계정'>
          {{ data.user_email }}
        </a-descriptions-item>

        <a-descriptions-item label='회원 이름' :span='2'>
          {{ data.user.name }}
        </a-descriptions-item>
      </a-descriptions>

      <a-descriptions bordered class='margin-top-md'>
        <template slot='title'>
          <div>
            문의 정보
          </div>
        </template>

        <a-descriptions-item :label="`답변 ${ mode === 'edit' ? '받을' : '받은' } 이메일`" :span='2'>
          <a-row>
            {{ form.to_mail }}
            <a-popconfirm
              v-if="mode === 'edit'"
              :visible='confirm_mail'
              ok-text='저장'
              cancel-text='취소'
              @confirm='onCloseConfirm(true)'
              :okButtonProps='{
                  props: { disabled: !temp_mail }
                }'
              @cancel='onCloseConfirm(false)'
            >
              <template slot='title'>
                <a-row>
                  <a-col :span='24'>답변 받을 이메일을 입력해 주세요.</a-col>
                  <a-col :span='24' class='margin-top-sm'>
                    <a-input v-model='temp_mail' />
                  </a-col>
                </a-row>
              </template>
              <a-button :loading='loading' @click='confirm_mail = true' class='margin-left-sm'>
                변경
              </a-button>
            </a-popconfirm>
          </a-row>
        </a-descriptions-item>

        <a-descriptions-item label='요청일'>
          {{ dateFormat }}
        </a-descriptions-item>

        <a-descriptions-item label='제목' :span='2'>
          {{ data.title }}
        </a-descriptions-item>

        <a-descriptions-item label='카테고리'>
          {{ data.category_info.text }}
        </a-descriptions-item>

        <a-descriptions-item label='내용'>
          <a-textarea v-model='data.description' autoSize disabled class='readonly border-top-none' />
        </a-descriptions-item>
      </a-descriptions>

      <div v-if="mode === 'edit'">
        <a-descriptions title='문의 답변' class='margin-top-md' />

        <a-row class='row'>
          <a-col :span='24' class='margin-bottom-sm'>
            <a-radio-group v-model='form.intro'>
              <a-radio-button value='Y'> 서비스 소개서 사용</a-radio-button>
              <a-radio-button value='N'> 서비스 소개서 미사용</a-radio-button>
            </a-radio-group>
          </a-col>

          <a-col :span='24'>
            <a-textarea v-model='form.answer_description' rows='5' />
          </a-col>
        </a-row>
      </div>
      <a-descriptions
        v-else
        title='문의 답변'
        class='margin-top-md'
        bordered
      >
        <a-descriptions-item label='서비스 소개서' :span='3'>
          {{ form.intro === 'Y' ? '포함' : '미포함' }}
        </a-descriptions-item>

        <a-descriptions-item label='내용' :span='3'>
          <a-textarea
            v-model='form.answer_description'
            autoSize
            disabled
            class='readonly border-top-none padding-none'
          />
        </a-descriptions-item>
      </a-descriptions>
    </a-form-model>


    <template slot='footer'>
      <a-row>
        <a-col :span='24' :style="{ textAlign: 'right' }">
          <a-button key='back' @click='close'>닫기</a-button>
          <a-popconfirm
            v-if="mode === 'edit'"
            :visible='confirm'
            title='답변을 보내시겠습니까?'
            ok-text='저장'
            cancel-text='취소'
            @confirm='submit'
            @cancel='confirm = false'
          >
            <a-button
              type='primary'
              :loading='loading'
              @click='confirm = true'
            >
              답변 처리
            </a-button>
          </a-popconfirm>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';

export default {
  props: {
    modal: Boolean,
    mode: String,
    data: Object
  },
  data() {
    return {
      loading: false,
      visible: false,
      confirm: false,
      confirm_mail: false,
      temp_mail: '',
      form: {},
      formItemLayout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 10 }
      },
      rules: {
        answer_description: [
          {
            required: true,
            message: '답변 내용은 필수입니다.',
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    modal(v) {
      this.reset();

      this.form = {
        inquire_id: this.data.id,
        answer_description: this.data.answer_description,
        to_mail: this.data.to_mail || this.data.email,
        intro: this.data.is_intro || 'N'
      };

      this.temp_mail = this.data.email;
      this.visible = v;
    },
    visible(v) {
      if (!v) {
        this.$emit('close');
      }
    }
  },
  computed: {
    title() {
      return '문의사항 상세';
    },
    dateFormat() {
      return !this._.isEmpty(this.data) ? this.$moment(this.data.created_at).format(this.data.date_format) : '-';
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    /** 답변처리 **/
    reset() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }

      this.confirm = false;
      this.confirm_mail = false;
      this.temp_mail = '';
      this.form = {};
    },
    onCloseConfirm(is_save) {
      this.confirm_mail = false;
      const mail = is_save ? this.temp_mail : this.form.to_mail;
      this.form.to_mail = this.temp_mail = mail;
    },
    /** 답변처리 **/
    setAnswer() {
      this.loading = true;

      try {
        this.$axios.put('inquire/answer', this.form).then(res => {
          this.loading = false;

          if (res.status !== 200) return;

          if (res.data.success) {
            this.$notification['success']({
              message: '답변 처리 완료',
              description: res.data.message
            });

            this.$emit('index');
            this.close();
          } else {
            this.$notification['error']({
              message: '답변 처리 실패',
              description: res.data.message
            });
          }
        });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.'
        });
      }
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;

        this.setAnswer();
      });
    }
  }
};
</script>
