import moment from 'moment';

export class CustomFilterItem {
  name = ''; // addon label
  key = '';  // payload시 key값
  value = ''; // payload 값
  type = 'input'; // [input, radio, select, date, dateRange], 기본 "input"
  options = []; // type이 "select" or "radio일" 경우 option 목록, [{ id:, text: '' }]

  addon = 'string';// string or select, 기본 "string"
  addonOptions = [];  // addon이 "select"인 경우 option 목록, [{ id:, text: '' }]
  addonValue = ''; // addon이 "select"인 경우 addon 값
  style = {};
  format = '';

  constructor(obj) {
    if (!obj) return;

    for (let key in obj) {
      this[`${key}`] = obj[`${key}`];
    }

    if (['date', 'dateRange'].includes(this.type)) {
      this.format = this.format || 'YYYY-MM-DD';

      const currentDate = moment(new Date());
      const subtractYearDate = moment(new Date()).subtract(1, 'year');

      if (this.type === 'date') {
        this.value = this.value || currentDate;
      }
      if (this.type === 'dateRange') {
        this.value = (!this.value || this.value.length > 2) ? [subtractYearDate, currentDate] : this.value;
      }
    }

    if (this.type === 'range') {
      this.value = (!this.value || this.value.length > 2) ? ['', ''] : this.value;
    }
  }
}

