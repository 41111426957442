<template>
  <a-modal v-model="visible" :title="title" :on-cancel="close" :width="'80%'">
    <a-alert
      message="카테고리 등록전 맵핑 설정 등록이 선행되어야 합니다."
      type="info"
      show-icon
    />

    <a-descriptions title="기본 정보" :style="{ marginTop: '2rem' }" />

    <a-form-model ref="form" :model="form" :rules="rules" layout="inline">
      <a-form-model-item label="구분" prop="depth" :colon="false">
        <a-radio-group v-model="form.depth" :disabled="mode === 'edit'">
          <a-radio-button :value="1"> 1차 </a-radio-button>
          <a-radio-button :value="2"> 2차 </a-radio-button>
          <a-radio-button :value="3"> 3차 </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item
        label="1차 분류"
        prop="parent1"
        :colon="false"
        v-if="form.depth === 2"
      >
        <a-select
          placeholder="선택"
          v-model="form.parent1"
          :disabled="mode === 'edit'"
          :style="{ width: '200px' }"
        >
          <template v-for="item in depth['1']">
            <a-select-option :value="item.id" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </template>
        </a-select>
      </a-form-model-item>

      <a-form-model-item
        label="1차/2차 분류"
        prop="parent2"
        :colon="false"
        v-if="form.depth === 3"
      >
        <a-select
          placeholder="선택"
          v-model="form.parent2"
          :disabled="mode === 'edit'"
          :style="{ width: '200px' }"
        >
          <a-select-option
            v-for="item in depth['2']"
            :value="item.id"
            :key="item.id"
          >
            <a-tag>
              {{ item.parent.name }}
            </a-tag>
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="사용여부" prop="is_use" :colon="false">
        <a-radio-group v-model="form.is_use">
          <a-radio-button :value="1"> 사용 </a-radio-button>
          <a-radio-button :value="0"> 미사용 </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item
        label="업종 사용여부"
        prop="is_industry"
        :colon="false"
      >
        <a-radio-group v-model="form.is_industry">
          <a-radio-button :value="1"> 사용 </a-radio-button>
          <a-radio-button :value="0"> 미사용 </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item
        label="업종지수 사용여부"
        prop="is_indicators"
        :colon="false"
      >
        <a-radio-group v-model="form.is_indicators">
          <a-radio-button :value="1"> 사용 </a-radio-button>
          <a-radio-button :value="0"> 미사용 </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="카테고리명" prop="name" :colon="false">
        <a-input v-model="form.name" :style="{ width: '300px' }" />
      </a-form-model-item>
    </a-form-model>

    <a-descriptions title="맵핑 설정" :style="{ marginTop: '2rem' }" />

    <a-transfer
      :data-source="configs"
      :target-keys="targetKeys"
      :titles="['조건 검색', '선택된 값']"
      show-search
      :filter-option="
        (inputValue, item) => item.name.indexOf(inputValue) !== -1
      "
      :show-select-all="false"
      :style="{ marginTop: '15px' }"
      @change="onChange"
      :locale="{
        itemUnit: '개',
        itemsUnit: '개',
        notFoundContent: '데이터가 없습니다.',
        searchPlaceholder: '결과 내 검색',
      }"
    >
      <template
        slot="children"
        slot-scope="{
          props: {
            direction,
            filteredItems,
            selectedKeys,
            disabled: listDisabled,
          },
          on: { itemSelectAll, itemSelect },
        }"
      >
        <a-table
          :row-selection="
            getRowSelection({
              disabled: listDisabled,
              selectedKeys,
              itemSelectAll,
              itemSelect,
            })
          "
          :columns="columns"
          :data-source="filteredItems"
          size="small"
          :custom-row="
            ({ key, disabled: itemDisabled }) => ({
              on: {
                click: () => {
                  itemSelect(key, !selectedKeys.includes(key));
                },
              },
            })
          "
        />
      </template>
    </a-transfer>

    <template slot="footer">
      <a-row>
        <a-col :span="12" :style="{ textAlign: 'left' }">
          <a-popconfirm
            v-if="mode === 'edit'"
            :visible="confirm"
            title="해당 카테고리를 삭제하시겠습니까 ? 삭제시 복구가 불가능합니다."
            ok-text="삭제"
            cancel-text="취소"
            @confirm="deleteCategory"
            @cancel="confirm = false"
          >
            <a-button
              type="danger"
              @click="confirm = true"
              ghost
              :loading="loading"
            >
              삭제
            </a-button>
          </a-popconfirm>
        </a-col>
        <a-col :span="12" :style="{ textAlign: 'right' }">
          <a-button key="back" @click="close"> 닫기 </a-button>
          <a-button type="primary" @click="submit()" :loading="loading">
            {{ modeText }}
          </a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
import difference from 'lodash/difference';
import _ from 'lodash';

export default {
  props: {
    modal: Boolean,
    mode: String,
    data: Object,
  },
  data() {
    return {
      loading: false,
      visible: false,
      confirm: false,
      form: {},
      targetKeys: [],
      columns: [
        {
          dataIndex: 'id',
          title: '#',
        },
        {
          dataIndex: 'name',
          title: '설정 이름',
        },
      ],
      depth: {},
      configs: [],
      params: {
        depth: '',
        parent_code: '',
        name: '',
      },
      rules: {
        depth: [
          {
            required: true,
            message: '구분 선택은 필수입니다.',
            trigger: 'change',
          },
        ],
        parent1: [
          {
            required: true,
            message: '1차 카테고리는 필수입니다.',
            trigger: 'change',
          },
        ],
        parent2: [
          {
            required: true,
            message: '2차 카테고리는 필수입니다.',
            trigger: 'change',
          },
        ],
        is_use: [
          {
            required: true,
            message: '사용여부는 필수입니다.',
            trigger: 'change',
          },
        ],
        is_industry: [
          {
            required: true,
            message: '업종 사용여부는 필수입니다.',
            trigger: 'change',
          },
        ],
        is_indicators: [
          {
            required: true,
            message: '업종지수 사용여부는 필수입니다.',
            trigger: 'change',
          },
        ],
        name: [
          {
            required: true,
            message: '카테고리명은 필수입니다.',
            trigger: 'change',
          },
        ],
        config: [
          {
            required: true,
            message: '설정은 필수입니다.',
            trigger: 'change',
          },
        ],
      },
    };
  },
  watch: {
    modal(v) {
      this.reset();

      this.visible = v;

      if (this.mode === 'edit') {
        this.form.depth = this.data.type;
        this.form.parent1 = this.data.parent;
        this.form.parent2 = this.data.middle;
        this.form.name = this.data.name;
        this.form.is_industry = this.data.is_industry;
        this.form.is_indicators = this.data.is_indicators;
        this.form.is_use = this.data.is_use;

        let targetKeys = [];

        this.data.configs.forEach(item => {
          let index = _.findIndex(this.configs, function (o) {
            return o.id === item;
          });

          targetKeys.push(index.toString());
        });

        this.targetKeys = targetKeys;
      }
    },
    visible(v) {
      if (!v) {
        this.$emit('close');
      }
    },
  },

  computed: {
    title() {
      return '카테고리 ' + this.modeText;
    },
    modeText() {
      return this.mode === 'edit' ? '변경' : '등록';
    },
  },
  mounted() {
    this.getDepth();
    this.getConfigs();
  },
  methods: {
    getDepth() {
      this.loading = true;

      try {
        this.$axios.get('tdi/categories/depth').then(res => {
          let rawData = res.data;
          this.depth = rawData;

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
      }
    },
    getConfigs() {
      this.loading = true;

      let params = {
        page: 1,
        page_rows: 100000,
        use: 'Y',
      };

      try {
        this.$axios.get('mapping/config', { params: params }).then(res => {
          let rawData = res.data;

          this.configs = rawData.data.map((item, index) => {
            return {
              key: index.toString(),
              id: item.id,
              name: item.name,
              use: item.use,
              created_at: item.created_at,
            };
          });

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
      }
    },
    close() {
      this.$emit('close');
    },
    reset() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }

      this.confirm = false;

      this.form = {
        depth: 1,
        name: '',
        parent1: '',
        parent2: '',
        is_industry: 0,
        is_indicators: 0,
        is_use: 0,
      };
    },
    setCategory() {
      this.loading = true;

      try {
        this.$axios.post('tdi/categories', this.params).then(res => {
          if (res.status === 200) {
            if (res.data.success) {
              this.$notification['success']({
                message: '등록 완료',
                description: res.data.message,
              });

              this.$emit('index');
              this.close();
            } else {
              this.$notification['error']({
                message: '등록 실패',
                description: res.data.message,
              });
            }
          }

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.',
        });
      }
    },
    updateCategory() {
      this.loading = true;
      let params = {
        name: this.form.name,
        configs: this.targetKeys.map(key => {
          return this.configs[key].id;
        }),
        is_industry: this.form.is_industry,
        is_indicators: this.form.is_indicators,
        is_use: this.form.is_use,
      };

      try {
        this.$axios.put('tdi/categories/' + this.data.id, params).then(res => {
          if (res.status === 200) {
            if (res.data.success) {
              this.$notification['success']({
                message: '변경 완료',
                description: res.data.message,
              });

              this.$emit('index');
              this.close();
            } else {
              this.$notification['error']({
                message: '변경 실패',
                description: res.data.message,
              });
            }
          }

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.',
        });
      }
    },
    deleteCategory() {
      this.loading = true;

      try {
        this.$axios.delete('tdi/categories/' + this.data.id).then(res => {
          if (res.status === 200) {
            if (res.data.success) {
              this.$notification['success']({
                message: '삭제 완료',
                description: res.data.message,
              });

              this.$emit('index');
              this.close();
            } else {
              this.$notification['error']({
                message: '삭제 실패',
                description: res.data.message,
              });
            }
          }

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.',
        });
      }
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.targetKeys.length === 0) {
            this.$notification['error']({
              message: '필수값',
              description: '설정은 1개 이상 선택해야 합니다.',
            });

            return;
          }

          this.params = {
            depth: this.form.depth,
            parent_code: null,
            name: this.form.name,
            is_industry: this.form.is_industry,
            is_indicators: this.form.is_indicators,
            is_use: this.form.is_use,
            configs: this.targetKeys.map(key => {
              return key * 1;
            }),
          };

          if (this.form.depth === 2) {
            this.params.parent_code = this.form.parent1;
          }

          if (this.form.depth === 3) {
            this.params.parent_code = this.form.parent2;
          }

          if (this.mode === 'edit') {
            this.updateCategory();
          } else {
            this.setCategory();
          }
        } else {
          return false;
        }
      });
    },
    onChange(nextTargetKeys) {
      this.targetKeys = nextTargetKeys;
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: item => ({
          props: { disabled: disabled || item.disabled },
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows.map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },
  },
};
</script>
