<template>
  <div>
    <a-card :title='title' :style="{ marginTop: '20px' }">
      <div slot='extra'></div>

      <div class='margin-bottom-md'>
        <custom-filter :page='page' @search='search' />

        <a-row>
          <a-col :span='12'>
            <a-alert
              :message="'검색결과 : ' + filters.pagination.total + ' 개'"
              type='success'
              showIcon
              :style="{ width: '300px' }"
            />
          </a-col>
          <a-col :span='12' :style="{ textAlign: 'right' }">
            <a-button type='primary' @click='openModal()'>
              등록
            </a-button>
          </a-col>
        </a-row>
      </div>

      <custom-table
        :table-list='data'
        :columns='columns'
        :pagination='filters.pagination'
        :loading='loading'
        :action-list="['detail']"
        @pagination-change='onPaginationChanged'
        @open-modal='openModal'
      />

    </a-card>

    <form-modal
      :modal='modal'
      :data='modalData'
      :mode='mode'
      @index='getConfigs'
      @close='modal = false'
    />
  </div>
</template>

<script>
import FormModal from './modals/Form/Index';
import { mapGetters } from 'vuex';
import CustomTable from '@/views/components/CustomTable';
import CustomFilter from '@/views/components/CustomFilter';

export default {
  props: {
    type: String
  },
  components: { FormModal, CustomTable, CustomFilter },
  computed: {
    ...mapGetters({
      isMobileDevice: 'setting/isMobileDevice',
      getFilters: 'filters/getStateFilters'
    }),
    title() {
      return '검색 그룹 관리';
    },
    filters() {
      return this.getFilters(this.page);
    }
  },
  data() {
    return {
      loading: false,
      modal: false,
      data: [],
      modalData: {},
      mode: '',
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          align: 'center',
          width: 50,
          ellipsis: true
        },
        {
          title: '설정 이름',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
          width: 300,
          ellipsis: true
        },
        {
          title: '사용여부',
          dataIndex: 'use',
          key: 'use',
          align: 'center',
          width: 300,
          scopedSlots: { customRender: 'use_tag' },
          ellipsis: true
        },
        {
          title: '등록일',
          dataIndex: 'created_at',
          key: 'created_at',
          align: 'center',
          scopedSlots: { customRender: 'created_at' },
          width: 300,
          ellipsis: true
        },
        {
          title: '기능',
          dataIndex: 'actions',
          key: 'actions',
          align: 'center',
          scopedSlots: { customRender: 'actions' },
          width: 150,
          ellipsis: true
        }
      ],
      page: 'mappingConfig'
    };
  },
  mounted() {
    this.getConfigs();
  },
  methods: {
    /** 페이지 변경시 **/
    onPaginationChanged(pagination) {
      this.filters.pagination.current = pagination ? pagination.current : this.filters.pagination.current;
      this.search();
    },
    /** 검색 **/
    search(filterData) {
      if (filterData && this.filters.pagination.current !== 1) {
        this.filters.pagination.current = 1;
        return;
      }

      this.getConfigs();
    },
    getConfigs() {
      this.loading = true;

      let params = {
        page: this.filters.pagination.current,
        page_rows: this.filters.pagination.limit,
        name: this.filters.name
      };

      try {
        this.$axios.get('mapping/config', { params: params }).then(res => {
          let rawData = res.data;

          this.filters.pagination.total = rawData.total;
          this.filters.pagination.current = rawData.current_page;

          this.data = rawData.data.map((item, index) => {
            return {
              key: index,
              id: item.id,
              name: item.name,
              use: item.use,
              categories: item.categories.map((category, categoryIndex) => {
                let temp = {
                  key: categoryIndex,
                  id: this.generateCategoryId(category),
                  class_a: category.class_a,
                  class_b: null,
                  class_c: null,
                  class_d: null,
                  class_a_name: category.class_a_name,
                  class_b_name: null,
                  class_c_name: null,
                  class_d_name: null,
                  except: category.except
                };

                if (category.class_b) {
                  temp.class_b = category.class_b;
                  temp.class_b_name = category.class_b_name;
                }

                if (category.class_c) {
                  temp.class_c = category.class_c;
                  temp.class_c_name = category.class_c_name;
                }

                if (category.class_d) {
                  temp.class_d = category.class_d;
                  temp.class_d_name = category.class_d_name;
                }

                return temp;
              }),
              pois: item.pois.map((poi, poiIndex) => {
                return {
                  key: poiIndex,
                  except: poi.except,
                  poi_id: poi.poi_id,
                  name1: poi.poi.name1,
                  name2: poi.poi.name2,
                  address: poi.poi.address
                };
              }),
              regions: item.regions.map((region, regionIndex) => {
                return {
                  key: regionIndex
                };
              }),
              created_at: item.created_at
            };
          });

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
      }
    },
    generateCategoryId(item) {
      let id = 'class' + item.class_a;

      if (item.class_b) {
        id += item.class_b;
      }

      if (item.class_c) {
        id += item.class_c;
      }

      if (item.class_d) {
        id += item.class_d;
      }

      return id;
    },

    openModal(record = null) {
      this.mode = record ? 'edit' : 'store';
      this.modalData = record;
      this.modal = true;
    }
  }
};
</script>
