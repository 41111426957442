<template>
  <a-modal
    v-model='visible'
    :on-cancel='close'
    :width="'80%'"
    :title='title'
  >
    <a-form-model
      v-if='modal'
      layout='horizontal'
      ref='form'
      v-bind='formItemLayout'
    >
      <a-descriptions bordered>
        <template slot='title'>
          <div>
            구독정보
          </div>

        </template>

        <a-descriptions-item label='주문번호' :span='3'>
          {{ data.order_no }}
        </a-descriptions-item>

        <a-descriptions-item label='싱품명'>
          {{ data.name }}
        </a-descriptions-item>

        <a-descriptions-item label='상품 금액' :span='2'>
          <div class='font-bold text-right'>
            {{ data.price }}원
          </div>
        </a-descriptions-item>
      </a-descriptions>

      <a-descriptions bordered class='margin-top-md'>
        <template slot='title'>
          <div>
            결제정보
          </div>
        </template>

        <a-descriptions-item label='결제 회원 계정' :span='3'>
          <a-tag :color="data.coalition_name === 'TDI' ? 'blue' : 'orange'" style='font-size: 0.8rem'>
            {{ data.coalition_name }}
          </a-tag>
          {{ data.user_email }}
        </a-descriptions-item>

        <a-descriptions-item label='결제일'>
          {{ data.payed_date }}
        </a-descriptions-item>

        <a-descriptions-item label='결제 금액' :span='2'>
          <div class='font-bold text-right'>
            {{ data.price }}원
          </div>
        </a-descriptions-item>


      </a-descriptions>
    </a-form-model>

    <template slot='footer'>
      <a-row>
        <a-col :span='24' :style="{ textAlign: 'right' }">
          <a-button key='back' @click='close'>닫기</a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>

export default {
  props: {
    modal: Boolean,
    data: Object
  },
  data() {
    return {
      loading: false,
      visible: false,
      formItemLayout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 10 }
      }
    };
  },
  watch: {
    modal(v) {
      this.visible = v;
    },
    visible(v) {
      if (!v) {
        this.$emit('close');
      }
    }
  },
  computed: {
    title() {
      return '매출 상세내역';
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>
