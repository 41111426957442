<template>
  <div>
    <custom-transfer
      :columns="columns"
      :source="filters.source"
      :target="filters.target"
      :pagination="filters.pagination"
      :loading="loading"
      :except="except"
      @delete="deleteTarget"
      @transfer="transfer"
    >
      <template v-slot:search>
        <a-row :gutter="[24, 24]">
          <a-col :span="12">
            <a-input-group compact>
              <!--
              <a-select v-model="categoryType" :style="{ width: '20%' }">
                <a-select-option value="a"> Class A </a-select-option>
                <a-select-option value="b"> Class B </a-select-option>
                <a-select-option value="c"> Class C </a-select-option>
                <a-select-option value="d"> Class D </a-select-option>
              </a-select>
              -->

              <span
                class="ant-input-group-addon"
                :style="{
                  width: '20%',
                  height: '32px',
                  paddingTop: '3px',
                }"
              >
                Class A
              </span>

              <a-select
                placeholder="선택"
                v-model="category.a"
                :style="{ width: '80%' }"
              >
                <a-select-option
                  v-for="(item, index) in classBucket.a"
                  :key="index"
                  :value="item.class_a"
                >
                  {{ item.class_a_name }}
                  <span v-if="item.class_a">({{ item.class_a }})</span>
                </a-select-option>
              </a-select>
            </a-input-group>
          </a-col>

          <a-col :span="12">
            <a-input-group compact>
              <span
                class="ant-input-group-addon"
                :style="{
                  width: '20%',
                  height: '32px',
                  paddingTop: '3px',
                }"
              >
                Class B
              </span>

              <a-select
                placeholder="선택"
                v-model="category.b"
                :style="{ width: '80%' }"
              >
                <a-select-option
                  v-for="(item, index) in classBucket.b"
                  :key="index"
                  :value="item.class_b"
                >
                  {{ item.class_b_name }}
                  <span v-if="item.class_b">({{ item.class_b }})</span>
                </a-select-option>
              </a-select>
            </a-input-group>
          </a-col>
        </a-row>

        <a-row :gutter="[24]">
          <a-col :span="12">
            <a-input-group compact>
              <span
                class="ant-input-group-addon"
                :style="{
                  width: '20%',
                  height: '32px',
                  paddingTop: '3px',
                }"
              >
                Class C
              </span>

              <a-select
                placeholder="선택"
                v-model="category.c"
                :style="{ width: '80%' }"
              >
                <a-select-option
                  v-for="(item, index) in classBucket.c"
                  :key="index"
                  :value="item.class_c"
                >
                  {{ item.class_c_name }}
                  <span v-if="item.class_c">({{ item.class_c }})</span>
                </a-select-option>
              </a-select>
            </a-input-group>
          </a-col>

          <a-col :span="12">
            <a-input-group compact>
              <span
                class="ant-input-group-addon"
                :style="{
                  width: '20%',
                  height: '32px',
                  paddingTop: '3px',
                }"
              >
                Class D
              </span>

              <a-select
                placeholder="선택"
                v-model="category.d"
                :style="{ width: '80%' }"
              >
                <a-select-option
                  v-for="(item, index) in classBucket.d"
                  :key="index"
                  :value="item.class_d"
                >
                  {{ item.class_d_name }}
                  <span v-if="item.class_d">({{ item.class_d }})</span>
                </a-select-option>
              </a-select>
            </a-input-group>
          </a-col>
        </a-row>

        <a-row :gutter="[24, 12]">
          <a-col :span="12">
            <a-input-search
              v-model="name1"
              :style="{ margin: '10px 0 0 0' }"
              @pressEnter="getPois()"
            >
              <template slot="addonBefore">
                Name1

                <a-tag :style="{ marginLeft: '5px' }">
                  <a-select placeholder="선택" v-model="name1LikeType">
                    <a-select-option value="N"> 일치 </a-select-option>
                    <a-select-option value="Y"> 포함 </a-select-option>
                  </a-select>
                </a-tag>
              </template>
            </a-input-search>
          </a-col>

          <a-col :span="12">
            <a-input-search
              v-model="name2"
              :style="{ margin: '10px 0 0 0' }"
              @pressEnter="getPois()"
            >
              <template slot="addonBefore">
                Name2

                <a-tag :style="{ marginLeft: '5px' }">
                  <a-select placeholder="선택" v-model="name2LikeType">
                    <a-select-option value="N"> 일치 </a-select-option>
                    <a-select-option value="Y"> 포함 </a-select-option>
                  </a-select>
                </a-tag>
              </template>
            </a-input-search>
          </a-col>
        </a-row>

        <div
          :style="{
            textAlign: 'center',
            marginTop: '20px',
            marginBottom: '20px',
          }"
        >
          <a-button type="primary" @click="getPois()" :loading="loading">
            검색
          </a-button>
        </div>
      </template>
    </custom-transfer>
  </div>
</template>
<script>
import CustomTransfer from '@/views/components/CustomTransfer';
import _ from 'lodash';

export default {
  props: {
    filters: Object,
    modal: Boolean,
    except: {
      type: Boolean,
      default: false,
    },
  },
  components: { CustomTransfer },
  data() {
    return {
      loading: false,
      category: {
        a: '',
        b: '',
        c: '',
        d: '',
      },
      categories: {
        a: [],
        b: [],
        c: [],
        d: [],
      },
      classBucket: {
        a: [],
        b: [
          {
            class_b: '',
            class_b_name: '전체',
          },
        ],
        c: [
          {
            class_c: '',
            class_c_name: '전체',
          },
        ],
        d: [
          {
            class_d: '',
            class_d_name: '전체',
          },
        ],
      },
      name1: '',
      name1LikeType: 'Y',
      name2: '',
      name2LikeType: 'Y',
      columns: [
        {
          dataIndex: 'poi_id',
          title: 'Poi ID',
        },
        {
          dataIndex: 'name1',
          title: 'Name1',
        },
        {
          dataIndex: 'name2',
          title: 'Name2',
        },
        {
          dataIndex: 'class_a',
          title: 'Class A',
          scopedSlots: { customRender: 'classA' },
        },
        {
          dataIndex: 'class_b',
          title: 'Class B',
          scopedSlots: { customRender: 'classB' },
        },
        {
          dataIndex: 'class_c',
          title: 'Class C',
          scopedSlots: { customRender: 'classC' },
        },
        {
          dataIndex: 'class_d',
          title: 'Class D',
          scopedSlots: { customRender: 'classD' },
        },
        {
          dataIndex: 'address',
          title: '주소',
        },
      ],
    };
  },
  watch: {
    modal(v) {
      if (v) {
        this.name1 = '';
        this.name2 = '';
        this.name1LikeType = 'Y';
        this.name2LikeType = 'Y';
        this.filters.source = [];
      }
    },
    'filters.pagination.current'() {
      this.getPois();
    },
    'category.a'(v) {
      this.classBucket.b = this.categories.b.filter(
        item => item.class_a === v * 1
      );

      this.classBucket.b.unshift({
        class_b: '',
        class_b_name: '전체',
      });

      this.category.b = '';
      this.category.c = '';
      this.category.d = '';

      this.classBucket.c = [
        {
          class_c: '',
          class_c_name: '전체',
        },
      ];
      this.classBucket.d = [
        {
          class_d: '',
          class_d_name: '전체',
        },
      ];
    },
    'category.b'(v) {
      if (v) {
        this.classBucket.c = this.categories.c.filter(
          item => item.class_a === this.category.a * 1 && item.class_b === v * 1
        );

        this.classBucket.c.unshift({
          class_c: '',
          class_c_name: '전체',
        });

        this.category.c = '';
        this.category.d = '';
        this.classBucket.d = [
          {
            class_d: '',
            class_d_name: '전체',
          },
        ];
      }
    },
    'category.c'(v) {
      if (v) {
        this.classBucket.d = this.categories.d.filter(
          item =>
            item.class_a === this.category.a * 1 &&
            item.class_b === this.category.b * 1 &&
            item.class_c === v * 1
        );

        this.classBucket.d.unshift({
          class_d: '',
          class_d_name: '전체',
        });

        this.category.d = '';
      }
    },
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    transfer(selected) {
      let sourceItems = _.cloneDeep(this.filters.source);
      let targetItems = _.cloneDeep(this.filters.target);
      let diffItems = _.differenceBy(selected, targetItems, 'poi_id');

      selected.forEach(select => {
        let index = _.findIndex(sourceItems, function (o) {
          return o.id == select.id;
        });

        sourceItems[index].disabled = 'Y';
      });

      this.filters.source = sourceItems;

      diffItems.forEach(item => {
        targetItems.push(item);
      });

      this.filters.target = targetItems.map((target, index) => {
        return {
          key: index,
          id: target.id,
          poi_id: target.poi_id,
          name1: target.name1,
          name2: target.name2,
          class_a: target.class_a,
          class_b: target.class_b,
          class_c: target.class_c,
          class_d: target.class_d,
          class_a_name: target.class_a_name,
          class_b_name: target.class_b_name,
          class_c_name: target.class_c_name,
          class_d_name: target.class_d_name,
          address: target.address,
          except: target.except,
        };
      });
    },
    deleteTarget(id) {
      let sourceItems = _.cloneDeep(this.filters.source);

      sourceItems.forEach(soruce => {
        if (soruce.id === id) {
          soruce.disabled = 'N';
        }
      });

      this.filters.source = sourceItems;
      this.filters.target = this.filters.target.filter(item => item.id !== id);
    },
    getCategories() {
      this.loading = true;

      try {
        this.$axios.get('poi/categories').then(res => {
          if (res.status === 200) {
            let rawData = res.data;

            this.categories = {
              a: rawData.classA,
              b: rawData.classB,
              c: rawData.classC,
              d: rawData.classD,
            };

            this.classBucket.a = this.categories.a;

            this.classBucket.a.unshift({
              class_a: '',
              class_a_name: '전체',
            });
          }

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
      }
    },
    getPois() {
      if (!this.category.a && !this.category.b) {
        this.$notification['error']({
          message: '필수값',
          description: 'Name 또는 카테고리를 선택해주세요.',
        });

        return false;
      }

      // if (!this.name1 && !this.name2) {
      //   this.$notification['error']({
      //     message: '필수값',
      //     description: 'name1 또는 name2 를 입력해주세요.',
      //   });

      //   return false;
      // }

      this.loading = true;

      let params = {
        params: {
          name1: this.name1,
          name1_like_type: this.name1LikeType,
          name2: this.name2,
          name2_like_type: this.name2LikeType,
          page: this.filters.pagination.current,
          page_rows: this.filters.pagination.limit,
          class_a: this.category.a,
          class_b: this.category.b,
          class_c: this.category.c,
          class_d: this.category.d,
        },
      };

      try {
        this.$axios.get('poi', params).then(res => {
          if (res.status === 200) {
            let rawData = res.data;

            this.filters.pagination.total = rawData.total;
            this.filters.pagination.current = rawData.current_page;

            this.filters.source = rawData.data.map((item, index) => {
              let disabled = 'N';

              this.filters.target.forEach(target => {
                if (item.poi_id === target.poi_id) {
                  disabled = 'Y';
                }
              });

              return {
                key: index.toString(),
                id: item.id,
                poi_id: item.poi_id,
                name1: item.name1,
                name2: item.name2,
                class_a: item.category.class_a,
                class_b: item.category.class_b,
                class_c: item.category.class_c,
                class_d: item.category.class_d,
                class_a_name: item.category.class_a_name,
                class_b_name: item.category.class_b_name,
                class_c_name: item.category.class_c_name,
                class_d_name: item.category.class_d_name,
                address: item.address,
                except: 'N',
                disabled: disabled,
              };
            });
          }

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
