<template>
  <a-modal
    v-model='visible'
    :on-cancel='close'
    :width="'80%'"
    :title='title'
    :bodyStyle="{ position: 'relative' }"
    :dialogStyle="{ maxWidth: '1300px' }"
  >
    <a-form-model
      v-if='modal'
      layout='horizontal'
      ref='form'
      :model='form'
      :rules='rules'
      v-bind='formItemLayout'
    >
      <a-descriptions title='기본 정보' bordered>
        <a-descriptions-item label='상품명' :span='2'>
          {{ data.product_name }}
        </a-descriptions-item>

        <a-descriptions-item label='상품금액'>
          {{
            data.product
              ? `${(data.product.price * 1).toLocaleString()} 원`
              : '-'
          }}
        </a-descriptions-item>

        <a-descriptions-item label='신청일' :span='3'>
          {{ getFormatDate(data.created_at) }}
        </a-descriptions-item>

        <a-descriptions-item label='세금계산서 발행여부' :span='3'>
          <a-radio-group v-model='form.tax_bill'>
            <a-radio-button :value='1'> 발행</a-radio-button>
            <a-radio-button :value='0'> 미발행</a-radio-button>
          </a-radio-group>
        </a-descriptions-item>

        <a-descriptions-item label='상태' :span='2'>
          <div class='row items-center'>
            <a-select
              class='col-grow'
              placeholder='선택'
              v-model='form.status'
              @change='onStatusChange'
            >
              <a-select-option
                v-for='(item, index) in filterdStatusOption'
                :key='index'
                :value='item.id'
              >
                {{ item.text }}
              </a-select-option>
            </a-select>
          </div>
        </a-descriptions-item>

        <a-descriptions-item label='입금 확인일' :span='1'>
          <a-date-picker
            v-if="form.status === 'S'"
            placeholder='입금 확인일 선택'
            v-model='deposit_date'
            :format="'YYYY-MM-DD'"
          />
        </a-descriptions-item>
      </a-descriptions>

      <a-descriptions title='신청자 정보' bordered class='margin-top-md'>
        <a-descriptions-item label='회원' :span='2'>
          {{ data.user_email }}
        </a-descriptions-item>

        <a-descriptions-item label='연락처'>
          {{ data.contact }}
        </a-descriptions-item>

        <a-descriptions-item label='상호명' :span='2'>
          {{ data.name }}
        </a-descriptions-item>

        <a-descriptions-item label='사업자번호'>
          {{ data.business_license }}
        </a-descriptions-item>

        <a-descriptions-item label='업종' :span='2'>
          <a-tag> {{ data.business_category }}</a-tag>
        </a-descriptions-item>

        <a-descriptions-item label='업태'>
          <a-tag> {{ data.business_type }}</a-tag>
        </a-descriptions-item>

        <a-descriptions-item label='세금계산서 발행 요청일' :span='2'>
          {{ getFormatDate(data.request_date) }}
        </a-descriptions-item>

        <a-descriptions-item label='세금계산서 받을 이메일'>
          {{ data.receive_email }}
        </a-descriptions-item>
      </a-descriptions>
    </a-form-model>

    <template slot='footer'>
      <a-row>
        <a-col :span='24' :style="{ textAlign: 'right' }">
          <a-button key='back' @click='close'>닫기</a-button>
          <a-button type='primary' :loading='loading' @click='submit'>
            변경
          </a-button>
        </a-col>
      </a-row>
    </template>

    <div v-if='loading' class='fit dim'>
      <a-spin />
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    modal: Boolean,
    mode: String,
    data: Object,
    transactionStatusOption: Array
  },
  components: {},
  data() {
    return {
      loading: false,
      visible: false,
      deposit_date: null,
      form: {},
      formItemLayout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 10 }
      },
      rules: {
        status: [
          {
            required: true,
            message: '상태값을 선택해주세요.',
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    modal(v) {
      this.reset();
      this.deposit_date = this.data
        ? this.$moment(this.data.deposit_date)
        : null;

      this.form = {
        id: this.data.id,
        status: this.data.status,
        tax_bill: this.data.tax_bill
      };
      this.visible = v;
    },
    visible(v) {
      if (!v) {
        this.$emit('close');
      }
    }
  },
  computed: {
    title() {
      return `계좌거래 신청정보`;
    },
    filterdStatusOption() {
      return this.transactionStatusOption.filter(item => !!item.id);
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    reset() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
      this.deposit_date = null;
      this.form = {
        status: '',
        tax_bill: 0
      };
    },
    getFormatDate(date) {
      return date ? this.$moment(date).format('YYYY-MM-DD') : '-';
    },
    onStatusChange(val) {
      this.deposit_date = null;

      if (val === 'S' && !!this.data.deposit_date) {
        this.deposit_date = this.$moment(this.data.deposit_date);
      }
    },
    updateStatus() {
      this.loading = true;

      try {
        this.$axios
          .post('account/transaction/update', {
            ...this.form,
            deposit_date: this.$moment(this.deposit_date).format('YYYY-MM-DD')
          })
          .then(res => {
            let rawData = res.data;

            const type = rawData.success ? 'success' : 'error';
            const message = `변경 ${rawData.success ? '성공' : '실페'}`;
            const description = rawData.message;

            this.$notification[type]({
              message: message,
              description: description
            });

            if (rawData.success) {
              this.$emit('index');
              this.close();
            }
            this.loading = false;
          });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.'
        });
      }
    },
    submit() {
      if (this.form.status === 'S' && !this.deposit_date) {
        this.$notification['error']({
          message: '필수값',
          description: '입금 확인일을 입력해주세요'
        });

        return;
      }

      this.$refs['form'].validate(valid => {
        if (!valid) return;

        this.updateStatus();
      });
    }
  }
};
</script>
<style scoped>
.dim {
  position: absolute;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  top: 0;
  left: 0;
  z-index: 999;
}

.dim > * {
  position: absolute;
  top: calc(50% - 25px);
}
</style>
