<template>
  <div>
    <a-card :title='title' :style="{ marginTop: '20px' }">
      <div slot='extra'></div>

      <div class='margin-bottom-md'>
        <custom-filter :page='page' @search='search' />

        <a-row>
          <a-col :span='12'>
            <a-alert
              :message="'검색결과 : ' + filters.pagination.total + ' 개'"
              type='success'
              showIcon
              :style="{ width: '300px' }"
            />
          </a-col>

          <a-col :span='12' :style="{ textAlign: 'right' }">
            <a-button type='primary' @click='openModal()'>
              등록
            </a-button>
          </a-col>
        </a-row>
      </div>

      <custom-table
        :table-list='data'
        :columns='columns'
        :pagination='filters.pagination'
        :loading='loading'
        :action-list="['detail']"
        @pagination-change='onPaginationChanged'
        @open-modal='openModal'
      />
    </a-card>

    <form-modal
      :modal='modal'
      :data='modalData'
      :mode='mode'
      :hot-place-category-option='hotPlaceCategoryOption'
      :region-option='regionOption'
      @index='getHotPlace'
      @close='modal = false'
    />
  </div>
</template>

<script>
import FormModal from './modals/Form/Index';
import { mapActions, mapGetters } from 'vuex';
import { getOptions } from '@/utils/options';
import CustomTable from '../components/CustomTable';
import CustomFilter from '@/views/components/CustomFilter';

export default {
  props: {
    type: String
  },
  components: { FormModal, CustomTable, CustomFilter },
  computed: {
    ...mapGetters({
      isMobileDevice: 'setting/isMobileDevice',
      getFilters: 'filters/getStateFilters'
    }),
    title() {
      return 'HOT플레이스 관리';
    },
    total() {
      return this.filters.pagination.total ?? 0;
    },
    filters() {
      return this.getFilters(this.page);
    }
  },
  data() {
    return {
      loading: false,
      modal: false,
      data: [],
      modalData: {},
      mode: '',
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          align: 'center',
          width: 100,
          ellipsis: true
        },
        {
          title: '플레이스명',
          dataIndex: 'place_name',
          key: 'place_name',
          align: 'center',
          ellipsis: false
        },
        {
          title: '지역',
          dataIndex: 'area_name',
          key: 'area_name',
          align: 'center',
          ellipsis: false
        },
        {
          title: '카테고리',
          dataIndex: 'category_name',
          key: 'category_name',
          scopedSlots: { customRender: 'tag_text' },
          align: 'center',
          ellipsis: false
        },
        {
          title: '등록일',
          dataIndex: 'created_at',
          key: 'created_at',
          align: 'center',
          scopedSlots: { customRender: 'created_at' },
          ellipsis: false,
          width: 150
        },
        {
          title: '기능',
          dataIndex: 'actions',
          key: 'actions',
          align: 'center',
          scopedSlots: { customRender: 'actions' },
          width: 150,
          ellipsis: true
        }
      ],
      hotPlaceCategoryOption: [],
      regionOption: getOptions('regionsOption', true),
      page: 'hotPlace'
    };
  },
  created() {
    this.getHotPlaceCategory(0).then(data => this.hotPlaceCategoryOption = data);
    this.getHotPlace();
  },
  methods: {
    ...mapActions({
      getHotPlaceCategory: 'filters/getHotPlaceCategory'
    }),
    /** 페이지 변경시 **/
    onPaginationChanged(pagination) {
      this.filters.pagination.current = pagination ? pagination.current : this.filters.pagination.current;
      this.search();
    },
    /** 검색 **/
    search(filterData) {
      if (filterData && this.filters.pagination.current !== 1) {
        this.filters.pagination.current = 1;
        return;
      }

      this.getHotPlace();
    },
    getHotPlace() {
      this.loading = true;
      let params = {
        ...this.filters,
        page: this.filters.pagination.current,
        page_rows: this.filters.pagination.limit
      };
      try {
        this.$axios.get('hotplace', { params: params }).then(res => {
          let rawData = res.data;

          this.filters.pagination.total = rawData.total;
          this.filters.pagination.current = rawData.current_page;

          this.data = rawData.data.map((item, index) => {
            return {
              key: index.toString(),
              ...item,
              id: item.id
            };
          });
          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
      }
    },
    openModal(record = null) {
      this.mode = record ? 'edit' : 'store';
      this.modalData = record;
      this.modal = true;
    }
  }
};
</script>
