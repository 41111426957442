<template>
  <div>
    <custom-transfer
      :columns="columns"
      :source="categories"
      :target="target"
      :loading="loading"
      except
      @transfer="transfer"
    >
      <template v-slot:search>
        <a-row :gutter="[24]">
          <a-col :span="12">
            <a-input-search
              v-model="depth1"
              addon-before="Depth 1"
              :style="{ margin: '10px 0 0 0' }"
              @pressEnter="getRegions()"
            />
          </a-col>

          <a-col :span="12">
            <a-input-search
              v-model="depth2"
              addon-before="Depth 2"
              :style="{ margin: '10px 0 0 0' }"
              @pressEnter="getRegions()"
            />
          </a-col>

          <a-col :span="12">
            <a-input-search
              v-model="depth3"
              addon-before="Depth 3"
              :style="{ margin: '10px 0 0 0' }"
              @pressEnter="getRegions()"
            />
          </a-col>
        </a-row>

        <div :style="{ textAlign: 'center', margin: '20px 0' }">
          <a-button type="primary" @click="getRegions()" :loading="loading">
            검색
          </a-button>
        </div>
      </template>
    </custom-transfer>
  </div>
</template>
<script>
import CustomTransfer from '@/views/components/CustomTransfer';
import _ from 'lodash';

export default {
  components: { CustomTransfer },
  data() {
    return {
      loading: false,
      categories: [],
      target: [],
      searchText: '',
      depth1: '',
      depth2: '',
      depth3: '',
      columns: [
        {
          dataIndex: 'key',
          title: 'No.',
          align: 'center',
          width: 100,
          scopedSlots: { customRender: 'index' },
        },
        {
          dataIndex: 'depth1',
          title: '시',
        },
        {
          dataIndex: 'depth2',
          title: '군/구',
        },
        {
          dataIndex: 'depth3',
          title: '동',
        },
      ],
    };
  },
  watch: {},
  mounted() {
    this.getRegions();
  },
  methods: {
    transfer(selected) {
      let sourceItems = _.cloneDeep(selected);
      let targetItems = _.cloneDeep(this.target);

      sourceItems.forEach(source => {
        let exist = false;

        targetItems.forEach(target => {
          if (source.id === target.id) {
            exist = true;
          }
        });

        if (!exist) {
          targetItems.push(source);
        }
      });

      targetItems.forEach(target => {
        delete target.key;
      });

      this.target = targetItems.map((target, index) => {
        return {
          key: index,
          id: target.id,
          class_a_name: target.class_a_name,
          class_b_name: target.class_b_name,
          class_c_name: target.class_c_name,
          class_d_name: target.class_d_name,
        };
      });
    },
    getRegions() {
      this.loading = true;

      let params = {
        params: {
          name: this.searchText,
        },
      };

      try {
        this.$axios.get('poi/categories', params).then(res => {
          if (res.status === 200) {
            let rawData = res.data;

            this.categories = rawData.map((item, index) => {
              return {
                key: index.toString(),
                id: item.id,
                class_a: item.class_a,
                class_b: item.class_b,
                class_c: item.class_c,
                class_d: item.class_d,
                class_a_name: item.class_a_name + '(' + item.class_a + ')',
                class_b_name: item.class_b_name + '(' + item.class_b + ')',
                class_c_name: item.class_c_name + '(' + item.class_c + ')',
                class_d_name: item.class_d_name + '(' + item.class_d + ')',
                disabled: false,
              };
            });
          }

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
};
</script>
