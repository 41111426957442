<template>
  <div>
    <div :style="{ marginBottom: '20px' }">
      <div
        :style="{
          color: 'inherit',
          fontSize: '14px',
          marginTop: '10px',
          marginBottom: '10px',
        }"
      >
        테마 색상
      </div>
      <div :style="{ display: 'flex' }">
        <div
          v-for="(palette, index) in palettes"
          :key="index"
          class="theme-color"
          :style="{
            backgroundColor: palette,
            marginBottom: '5px',
          }"
          @click="setThemeColor(palette)"
        >
          <a-icon type="check" v-if="color === palette" />
        </div>
      </div>
    </div>

    <div>
      <div
        :style="{
          color: 'inherit',
          fontSize: '14px',
          marginTop: '10px',
          marginBottom: '10px',
        }"
      >
        와이드 활성화 (PC만 가능)
      </div>

      <a-switch :default-checked="wide" @change="wideChange" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import themeUtil from '@/utils/themeUtil';

export default {
  computed: {
    ...mapState('setting', ['theme', 'palettes']),
    ...mapGetters({
      wide: 'setting/getWide',
    }),
    createdAt() {
      return this.$moment(this.user.created_at).format('YYYY년 MM월 DD일');
    },
  },
  watch: {
    'theme.color': function (val) {
      let closeMessage = this.$message.loading('테마색이 변경되었습니다.');
      themeUtil.changeThemeColor(val, this.theme.mode).then(closeMessage);
      localStorage.setItem('theme-color', val);
    },
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      color: '#f5222d',
      themeToggle: false,
    };
  },
  mounted() {
    let color = localStorage.getItem('theme-color');
    let wide = localStorage.getItem('theme-wide');

    if (color) {
      this.color = color;
    }

    if (wide) {
      this.setWide(JSON.parse(wide));
    }
  },
  methods: {
    ...mapMutations('setting', ['setTheme', 'setWide']),
    setThemeColor(color) {
      this.color = color;
      this.setTheme({
        color: color,
        mode: this.theme.mode,
      });
    },
    wideChange(v) {
      this.setWide(v);
      localStorage.setItem('theme-wide', v);
    },
  },
};
</script>

<style scoped>
.theme-color {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 8px;
  text-align: center;
  color: #fff;
  font-weight: bold;
}
</style>
