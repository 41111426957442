import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

import Login from '../views/auth/Login';
import User from '../views/user/Index';
import Config from '../views/configs/Index';
import TdiCategory from '../views/tdi/category/Index';
import MappingConfig from '../views/mapping/config/Index';
import PoiGroup from '../views/poi/group/Index';
import Notice from '../views/notice/Index';
import Contact from '../views/contact/Index';
import TrendRequest from '../views/trend/request/Index';
import Sales from '../views/sales/statistics/Index';
import SalesDetail from '../views/sales/detail/Index';
import SubscriptionCancelReason from '../views/subscription/reason/Index';
import Mailing from '../views/mailing/Index';
import TrendNews from '../views/trend/news/Index';
import Transaction from '../views/transaction/Index';
import HotPlace from '../views/hotplace/Index';
import ErrorPage from '../views/error/Index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Main',
    component: User,
    meta: {
      auth: true
    }
  }, {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters['account/authenticated']) {
        return next({
          name: 'User'
        });
      }

      next();
    }
  }, {
    path: '/config',
    name: 'Config',
    component: Config,
    meta: {
      auth: true
    }
  }, {
    path: '/user',
    name: 'User',
    component: User,
    meta: {
      auth: true
    }
  }, {
    path: '/tdi/category',
    name: 'TdiCategory',
    component: TdiCategory,
    meta: {
      auth: true
    }
  }, {
    path: '/mapping/config',
    name: 'MappingConfig',
    component: MappingConfig,
    meta: {
      auth: true
    }
  }, {
    path: '/poi/group',
    name: 'PoiGroup',
    component: PoiGroup,
    meta: {
      auth: true
    }
  }, {
    path: '/notice',
    name: 'Notice',
    component: Notice,
    meta: {
      auth: true
    }
  }, {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      auth: true
    }
  }, {
    path: '/trend/request',
    name: 'TrendRequest',
    component: TrendRequest,
    meta: {
      auth: true
    }
  }, {
    path: '/trend/news',
    name: 'TrendNews',
    component: TrendNews,
    meta: {
      auth: true
    }
  }, {
    path: '/sales',
    name: 'Sales',
    component: Sales,
    meta: {
      auth: true
    }
  }, {
    path: '/sales/detail',
    name: 'SalesDetail',
    component: SalesDetail,
    meta: {
      auth: true
    }
  }, {
    path: '/subscription/reason',
    name: 'SubscriptionCancelReason',
    component: SubscriptionCancelReason,
    meta: {
      auth: true
    }
  }, {
    path: '/mailing',
    name: 'Mailing',
    component: Mailing,
    meta: {
      auth: true
    }
  }, {
    path: '/account/transaction',
    name: 'Transaction',
    component: Transaction,
    meta: {
      auth: true
    }
  }, {
    path: '/hotplace',
    name: 'HotPlace',
    component: HotPlace,
    meta: {
      auth: true
    }
  }, {
    path: '*',
    name: 'error',
    component: ErrorPage
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.auth)) {
    if (!localStorage.token || localStorage.token === 'null') {
      return next({
        name: 'Login'
      });
    }
  }

  next();
});

export default router;
