<template>
  <div>
    <div class="ant-transfer ant-transfer-customize-list">
      <div class="ant-transfer-list" :style="{ padding: '10px' }">
        <a-alert
          message="조건 검색"
          type="warning"
          show-icon
          :style="{ marginBottom: '20px' }"
        />

        <slot name="search" />

        <a-table
          :columns="columns"
          :data-source="source"
          :loading="loading"
          size="small"
          :pagination="!pagination"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
            getCheckboxProps: record => ({
              props: {
                disabled: record.disabled === 'Y',
              },
            }),
          }"
          :locale="{
            emptyText: '데이터가 없습니다.',
          }"
        >
          <span slot="index" slot-scope="text, record">
            {{ record.key * 1 + 1 }}
          </span>

          <span slot="classA" slot-scope="text, record">
            {{
              record.class_a
                ? record.class_a_name + ' (' + record.class_a + ')'
                : ''
            }}
          </span>

          <span slot="classB" slot-scope="text, record">
            {{
              record.class_b
                ? record.class_b_name + ' (' + record.class_b + ')'
                : ''
            }}
          </span>

          <span slot="classC" slot-scope="text, record">
            {{
              record.class_c
                ? record.class_c_name + ' (' + record.class_c + ')'
                : ''
            }}
          </span>

          <span slot="classD" slot-scope="text, record">
            {{
              record.class_d
                ? record.class_d_name + ' (' + record.class_d + ')'
                : ''
            }}
          </span>
        </a-table>

        <a-pagination
          v-if="pagination"
          :disabled="loading"
          v-model="pagination.current"
          :total="pagination.total"
          :defaultPageSize="pagination.limit"
          size="small"
          :style="{ textAlign: 'right', marginTop: '20px' }"
        />
      </div>

      <div class="ant-transfer-operation">
        <a-button
          @click="transfer()"
          :disabled="disabled"
          :type="disabled ? '' : 'primary'"
        >
          <a-icon type="arrow-right" />
        </a-button>
      </div>

      <div class="ant-transfer-list" :style="{ padding: '10px' }">
        <a-alert
          message="선택된 값"
          type="success"
          show-icon
          :style="{ marginBottom: '20px' }"
        />

        <a-table
          :columns="targetColumns"
          :data-source="target"
          size="small"
          :locale="{
            emptyText: '데이터가 없습니다.',
          }"
        >
          <span slot="index" slot-scope="text, record">
            {{ record.key + 1 }}
          </span>

          <span slot="except" slot-scope="text, record">
            <a-select placeholder="선택" v-model="record.except">
              <a-select-option value="Y"> 제외 </a-select-option>

              <a-select-option value="N"> 포함 </a-select-option>
            </a-select>
          </span>

          <span slot="classA" slot-scope="text, record">
            {{
              record.class_a
                ? record.class_a_name + ' (' + record.class_a + ')'
                : ''
            }}
          </span>

          <span slot="classB" slot-scope="text, record">
            {{
              record.class_b
                ? record.class_b_name + ' (' + record.class_b + ')'
                : ''
            }}
          </span>

          <span slot="classC" slot-scope="text, record">
            {{
              record.class_c
                ? record.class_c_name + ' (' + record.class_c + ')'
                : ''
            }}
          </span>

          <span slot="classD" slot-scope="text, record">
            {{
              record.class_d
                ? record.class_d_name + ' (' + record.class_d + ')'
                : ''
            }}
          </span>

          <span slot="actions" slot-scope="text, record">
            <a-button
              size="small"
              type="danger"
              ghost
              :style="{ marginRight: '10px' }"
              @click="onDelete(record.id)"
            >
              삭제
            </a-button>
          </span>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    columns: Array,
    source: Array,
    target: Array,
    loading: Boolean,
    pagination: {
      type: Object,
      default: null,
    },
    except: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    disabled() {
      return this.selectedItems.length === 0;
    },
    targetColumns() {
      let columns = _.cloneDeep(this.columns);

      if (this.except) {
        columns.push({
          dataIndex: 'except',
          title: '포함/제외',
          align: 'except',
          scopedSlots: { customRender: 'except' },
        });
      }

      columns.push({
        dataIndex: 'actions',
        title: '기능',
        align: 'center',
        scopedSlots: { customRender: 'actions' },
      });

      return columns;
    },
  },
  watch: {
    source() {
      this.selectedRowKeys = [];
    },
  },
  data() {
    return {
      selectedRowKeys: [],
      selectedItems: [],
    };
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedItems = [];

      selectedRowKeys.forEach(item => {
        this.selectedItems.push(this.source[item]);
      });
    },
    onDelete(id) {
      this.$emit('delete', id);
    },
    transfer() {
      this.$emit('transfer', this.selectedItems);

      this.selectedItems = [];
      this.selectedRowKeys = [];
    },
  },
};
</script>
