import axios from 'axios';

export default {
  namespaced: true,
  state: {
    user: null,
    token: '',
    permissions: null,
    roles: null,
    routesConfig: null
  },
  getters: {
    authenticated(state) {
      return state.token && state.user;
    },
    getUser: state => {
      return state.user;
    },
    permissions: state => {
      if (!state.permissions) {
        try {
          const permissions = localStorage.getItem(
            process.env.VUE_APP_PERMISSIONS_KEY
          );
          state.permissions = JSON.parse(permissions);
          state.permissions = state.permissions ? state.permissions : [];
        } catch (e) {
          console.error(e.message);
        }
      }
      return state.permissions;
    },
    roles: state => {
      if (!state.roles) {
        try {
          const roles = localStorage.getItem(process.env.VUE_APP_ROLES_KEY);
          state.roles = JSON.parse(roles);
          state.roles = state.roles ? state.roles : [];
        } catch (e) {
          console.error(e.message);
        }
      }
      return state.roles;
    },
    routesConfig: state => {
      if (!state.routesConfig) {
        try {
          const routesConfig = localStorage.getItem(
            process.env.VUE_APP_ROUTES_KEY
          );
          state.routesConfig = JSON.parse(routesConfig);
          state.routesConfig = state.routesConfig ? state.routesConfig : [];
        } catch (e) {
          console.error(e.message);
        }
      }
      return state.routesConfig;
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + localStorage.getItem('token');
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
      localStorage.setItem(
        process.env.VUE_APP_PERMISSIONS_KEY,
        JSON.stringify(permissions)
      );
    },
    setRoles(state, roles) {
      state.roles = roles;
      localStorage.setItem(
        process.env.VUE_APP_ROLES_KEY,
        JSON.stringify(roles)
      );
    },
    setRoutesConfig(state, routesConfig) {
      state.routesConfig = routesConfig;
      localStorage.setItem(
        process.env.VUE_APP_ROUTES_KEY,
        JSON.stringify(routesConfig)
      );
    }
  },
  actions: {
    async login({ dispatch }, credentials) {
      try {
        let response = await axios.post('auth/login', credentials);

        if (response.data.access_token) {
          dispatch('attempt', response.data.access_token);
        }

        return response;
      } catch (error) {
        console.log(error);
      }
    },

    async attempt({ commit, state }, token) {
      if (token) {
        commit('setToken', token);
      }

      if (!state.token) {
        return;
      }

      try {
        let response = await axios.get('auth/me');

        commit('setUser', response.data);
      } catch (error) {
        commit('setToken', null);
        commit('setUser', null);
      }
    },

    async logout({ commit }) {
      await axios.post('auth/logout').then(() => {
        commit('setToken', null);
        commit('setUser', null);
        localStorage.removeItem('token', null);
      });
    }
  }
};
