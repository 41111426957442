<template>
  <div>
    <div :style="{ marginBottom: '1rem', textAlign: 'right' }">
      <a-button @click='visible = true'>구독 변경(연장)</a-button>

      <a-modal
        title='구독 변경(연장)'
        :visible='visible'
        :confirm-loading='loading'
        @cancel='close'
        :width='650'
      >
        <template slot='footer'>
          <a-button key='back' @click='visible = false'> 닫기</a-button>
        </template>

        <div>
          <div
            :style="{
              width: '260px',
              display: 'inline-block',
              textAlign: 'right',
            }"
          >
            <a-tag color='pink'> 플레이스 구독</a-tag>
            <a-tag>
              현재 만료일 :
              {{
                user.place
                  ? $moment(user.place.ends_at).format('YYYY-MM-DD')
                  : '-'
              }}
            </a-tag>
          </div>

          <a-date-picker
            :style="{ marginRight: '10px' }"
            placeholder='만료일 선택'
            v-model='placeEndDate'
          />
          <a-button
            type='primary'
            :loading='loading'
            @click="setSubscription('place')"
          >
            변경하기
          </a-button>
        </div>

        <div :style="{ marginTop: '2rem' }">
          <div
            :style="{
              width: '260px',
              display: 'inline-block',
              textAlign: 'right',
            }"
          >
            <a-tag color='blue'> APP 구독</a-tag>
            <a-tag>
              현재 만료일 :
              {{
                user.app ? $moment(user.app.ends_at).format('YYYY-MM-DD') : '-'
              }}
            </a-tag>
          </div>

          <a-date-picker
            :style="{ marginRight: '10px' }"
            placeholder='만료일 선택'
            v-model='appEndDate'
          />
          <a-button
            type='primary'
            :loading='loading'
            @click="setSubscription('app')"
          >
            변경하기
          </a-button>
        </div>
      </a-modal>
    </div>

    <a-descriptions title='구독정보' bordered>
      <a-descriptions-item label='플레이스 구독'>
        <div :style="{ textAlign: 'center' }">
          <a-tag :color="user.place ? 'pink' : ''">
            {{ user.place ? '구독중' : '미구독' }}
          </a-tag>
        </div>
      </a-descriptions-item>

      <a-descriptions-item label='플레이스 구독 만료일' :span='2'>
        <div :style="{ textAlign: 'center' }">
          {{
            user.place ? $moment(user.place.ends_at).format('YYYY-MM-DD') : '-'
          }}
        </div>
      </a-descriptions-item>

      <a-descriptions-item label='APP 구독'>
        <div :style="{ textAlign: 'center' }">
          <a-tag :color="user.app ? 'pink' : ''">
            {{ user.app ? '구독중' : '미구독' }}
          </a-tag>
        </div>
      </a-descriptions-item>

      <a-descriptions-item label='APP 구독 만료일' :span='2'>
        <div :style="{ textAlign: 'center' }">
          {{ user.app ? $moment(user.app.ends_at).format('YYYY-MM-DD') : '-' }}
        </div>
      </a-descriptions-item>
    </a-descriptions>

    <a-descriptions title='결제내역' :style="{ marginTop: '3rem' }" />

    <custom-table
      :table-list='data'
      :columns='columns'
      :pagination='pagination'
      :loading='loading'
      @pagination-change='search'
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CustomTable from '@/views/components/CustomTable';

export default {
  props: {
    type: String,
    user: Object,
    tab: String
  },
  components: { CustomTable },
  computed: {
    ...mapGetters({
      isMobileDevice: 'setting/isMobileDevice'
    }),
    title() {
      return '사용자 관리';
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      data: [],
      placeEndDate: '',
      appEndDate: '',
      modalData: {},
      pagination: {
        current: 1,
        limit: 20,
        total: 0
      },
      columns: [
        {
          title: '#',
          dataIndex: 'key',
          key: 'key',
          scopedSlots: { customRender: 'key_index' },
          align: 'center',
          width: 50,
          ellipsis: true
        },
        {
          title: '결제일',
          dataIndex: 'created_at',
          key: 'created_at',
          align: 'center',
          ellipsis: true
        },
        {
          title: '구독상품',
          dataIndex: 'product_name',
          key: 'product_name',
          align: 'center',
          scopedSlots: { customRender: 'tag_text' },
          ellipsis: true
        },
        {
          title: '결제금액',
          dataIndex: 'price',
          key: 'price',
          align: 'center',
          scopedSlots: { customRender: 'price' },
          ellipsis: true
        }
      ]
    };
  },
  watch: {
    tab(v) {
      if (v === 'subscription') {
        this.getPayments();
      }
    }
  },
  mounted() {
    this.getPayments();
  },
  methods: {
    search(pagination) {
      if (!pagination && this.pagination.current !== 1) {
        this.pagination.current = 1;
        return;
      }

      this.pagination.current = pagination ? pagination.current : this.pagination.current;
      this.getPayments();
    },
    getPayments() {
      this.loading = true;
      this.data = [];

      let params = {
        page: this.pagination.current,
        page_rows: this.pagination.limit,
        user_id: this.user.id
      };

      try {
        this.$axios.get('subscription/order', { params: params }).then(res => {
          let rawData = res.data;

          this.pagination.total = rawData.total;
          this.pagination.current = rawData.current_page;

          this.data = rawData.data.map((item, index) => {
            return {
              key: index,
              id: item.id,
              created_at: item.created_at,
              product_name: item.product.name,
              price: item.price
            };
          });

          this.loading = false;
        });
      } catch (error) {
        console.log(error);
      }
    },
    setSubscription(type) {
      let products = '';
      let endsAt = '';

      if (type === 'allInOne') {
        products = '8,9';
      }

      if (type === 'place') {
        products = '3,4';
        endsAt = this.placeEndDate
          ? this.placeEndDate.format('YYYY-MM-DD')
          : '';
      }

      if (type === 'app') {
        products = '7,6';
        endsAt = this.appEndDate ? this.appEndDate.format('YYYY-MM-DD') : '';
      }

      if (!endsAt) {
        this.$notification['warning']({
          message: '필수값 입력',
          description: '만료일을 선택해주세요.'
        });

        return false;
      }

      let params = {
        user_id: this.user.id,
        products: products,
        ends_at: endsAt
      };

      this.loading = true;

      try {
        this.$axios.put('subscription/extend', params).then(res => {
          if (res.data.success) {
            this.$notification['success']({
              message: '구독 연장 완료',
              description: '구독이 연장 되었습니다.'
            });
          }

          if (type === 'place') {
            if (this.user.place) {
              this.user.place.ends_at = endsAt;
            } else {
              this.user.place = {
                ends_at: endsAt
              };
            }

            this.placeEndDate = '';
          }

          if (type === 'app') {
            if (this.user.app) {
              this.user.app.ends_at = endsAt;
            } else {
              this.user.app = {
                ends_at: endsAt
              };
            }

            this.appEndDate = '';
          }

          this.close();
          this.$emit('index');
          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    close() {
      this.visible = false;
    }
  }
};
</script>
