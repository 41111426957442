import { getOptions } from '@/utils/options';
import _ from 'lodash';
import axios from 'axios';

const default_pagination = {
  current: 1,
  total: 0,
  limit: 20
};

export default {
  namespaced: true,
  state: {
    user: {
      filters: {
        pagination: default_pagination
      },
      filterList: [
        {
          key: 'coalition_name',
          type: 'radio',
          options: getOptions('coalitionOption', true)
        },
        { name: '이메일', key: 'email' },
        { name: '이름', key: 'name' }
      ]
    },
    tdiCategory: {
      filters: {
        pagination: default_pagination
      },
      filterList: [
        { name: '카테고리명', key: 'name' }
      ]
    },
    mappingConfig: {
      filters: {
        pagination: default_pagination
      },
      filterList: [
        { name: '설정 이름', key: 'name' }
      ]
    },
    poiGroup: {
      filters: {
        pagination: default_pagination
      },
      filterList: [
        { name: '그룹명', key: 'name' }
      ]
    },
    notice: {
      filters: {
        pagination: default_pagination
      },
      filterList: [
        {
          key: 'company',
          type: 'radio',
          options: getOptions('coalitionOption', true)
        },
        {
          addon: 'select',
          addonOptions: [
            { id: 'title', text: '제목' },
            { id: 'sub_title', text: '부제목' }
          ],
          addonValue: 'title'
        }
      ]
    },
    contact: {
      filters: {
        pagination: default_pagination
      },
      filterList: [
        {
          key: 'coalition_name',
          type: 'radio',
          options: getOptions('coalitionOption', true)
        },
        { name: '이메일', key: 'email' },
        { name: '제목', key: 'title' }
      ]
    },
    trendRequest: {
      filters: {
        pagination: default_pagination
      },
      filterList: []
    },
    salesDetail: {
      filters: {
        pagination: default_pagination
      },
      filterList: [
        {
          name: '결제일',
          key: 'paymentDate',
          type: 'dateRange'
        },
        {
          name: '매출액',
          key: 'price',
          type: 'range'
        },
        { name: '결제 회원', key: 'customer' },
        { name: '결제 상품명', key: 'name' }
      ]
    },
    subscriptionCancelReason: {
      filters: {
        pagination: default_pagination
      },
      filterList: [
        {
          key: 'coalition_name',
          type: 'radio',
          options: getOptions('coalitionOption', true)
        },
        { name: '주문번호', key: 'order_no' },
        { name: '이메일', key: 'email' },
        { name: '개선내용', key: 'reason' }
      ]
    },
    mailing: {
      filters: {
        pagination: default_pagination
      },
      filterList: [
        {
          key: 'service',
          type: 'radio',
          options: getOptions('coalitionOption', true)
        },
        {
          name: '발송상태',
          key: 'status',
          type: 'select',
          options: getOptions('statusOption', true)
        },
        { name: '제목', key: 'title' }
      ]
    },
    trendNews: {
      filters: {
        pagination: default_pagination
      },
      filterList: [
        {
          key: 'news_type',
          type: 'radio',
          options: getOptions('trendCategoryOption', true)
        },
        {
          addon: 'select',
          addonOptions: [
            { id: 'title', text: '제목' },
            { id: 'sub_title', text: '부제목' }
          ],
          addonValue: 'title'
        }
      ]
    },
    transaction: {
      filters: {
        pagination: default_pagination
      },
      filterList: [
        {
          name: '상태',
          key: 'status',
          type: 'select',
          options: getOptions('transactionStatusOption', true)
        },
        { name: '이메일', key: 'email' }
      ]
    },
    hotPlace: {
      filters: {
        pagination: default_pagination
      },
      filterList: [
        {
          name: '카테고리',
          key: 'category',
          type: 'select',
          options: []
        },
        {
          name: '지역',
          key: 'area',
          type: 'select',
          options: getOptions('regionsOption', true)
        },
        { name: '플레이스명', key: 'name' }
      ]
    }
  },
  getters: {
    getStateFilters: (state) => (stateKey) => {
      return state[stateKey] ? state[stateKey].filters : {};
    },
    getStateFilterList: (state) => (stateKey) => {
      return state[stateKey] ? state[stateKey].filterList : [];
    }
  },
  actions: {
    async getHotPlaceCategory({ commit, state }, index) {
      try {
        let response = await axios.get('hotplace/categories');
        let resData = response.data
          ? response.data.map(item => {
            return { id: item.id, text: item.name };
          })
          : [];
        const hotplaceState = state.hotPlace.filterList;
        hotplaceState[index].options = resData = [{ id: '', text: '전체' }, ...resData];

        commit('setStateFilterList', { key: 'hotPlace', value: hotplaceState });

        return resData;
      } catch (error) {
        console.log(error);
      }
    }
  },
  mutations: {
    setStateFilters(state, { key, value }) {
      state[key].filters = _.cloneDeep(value);
    },
    setStateFilterList(state, { key, value }) {
      state[key].filterList = _.cloneDeep(value);
    }
  }
};
