<template>
  <div>
    <a-card :title='title' class='margin-top-md'>
      <div slot='extra' />

      <div class='margin-bottom-md'>
        <custom-filter :page='page' @search='search' />

        <a-alert
          :message='`검색결과 : ${filters.pagination.total} 개`'
          type='success'
          showIcon
          :style="{ width: '100%', maxWidth: '300px'}"
        />
      </div>

      <custom-table
        :table-list='data'
        :columns='columns'
        :pagination='filters.pagination'
        :loading='loading'
        :action-list="['detail']"
        @pagination-change='onPaginationChanged'
        @open-modal='openModal'
      />
    </a-card>

    <form-modal
      :modal='modal'
      :data='modalData'
      @index='getSubscriptionCancelReason'
      @close='modal = false'
    />
  </div>
</template>

<script>
import FormModal from './modals/Form/Index';
import { mapGetters } from 'vuex';
import { getOptions, getOptionByKey } from '@/utils/options';
import CustomTable from '@/views/components/CustomTable';
import CustomFilter from '@/views/components/CustomFilter';

export default {
  components: {
    FormModal,
    CustomTable,
    CustomFilter
  },
  computed: {
    ...mapGetters({
      isMobileDevice: 'setting/isMobileDevice',
      getFilters: 'filters/getStateFilters'
    }),
    title() {
      return '구독취소 사유';
    },
    filters() {
      return this.getFilters(this.page);
    }
  },
  data() {
    return {
      loading: false,
      mode: '',
      modal: false,
      modalData: {},
      data: [],
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          scopedSlots: { customRender: 'id' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '주문번호',
          dataIndex: 'order_no',
          key: 'order_no',
          scopedSlots: { customRender: 'order_no' },
          align: 'center',
          ellipsis: false
        },
        {
          title: '서비스사',
          dataIndex: 'coalition',
          key: 'coalition',
          scopedSlots: { customRender: 'info_obj' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '회원',
          dataIndex: 'user_email',
          key: 'user_email',
          scopedSlots: { customRender: 'user_email' },
          align: 'center',
          ellipsis: false
        },
        {
          title: '해지일',
          dataIndex: 'created_at',
          key: 'created_at',
          scopedSlots: { customRender: 'created_at' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '기능',
          dataIndex: 'actions',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
          align: 'center',
          ellipsis: false
        }
      ],
      page: 'subscriptionCancelReason',
      coalitionOption: getOptions('coalitionOption', true)
    };
  },
  mounted() {
    this.getSubscriptionCancelReason();
  },
  methods: {
    /** 페이지 변경시 **/
    onPaginationChanged(pagination) {
      this.filters.pagination.current = pagination ? pagination.current : this.filters.pagination.current;
      this.search();
    },
    /** 검색 **/
    search(filterData) {
      if (filterData && this.filters.pagination.current !== 1) {
        this.filters.pagination.current = 1;
        return;
      }

      this.getSubscriptionCancelReason();
    },
    /** 구독취소 목록 조회 **/
    getSubscriptionCancelReason() {
      this.loading = true;

      let params = {
        ...this.filters,
        page: this.filters.pagination.current,
        page_rows: this.filters.pagination.limit
      };

      try {
        this.$axios.get('cancel/reason', { params: params })
          .then((res) => {
            let rawData = res.data;

            this.filters.pagination.total = rawData.total;
            this.filters.pagination.current = rawData.current_page;

            this.data = rawData.data.map((item, index) => {
              return {
                key: index.toString(),
                ...item,
                user_email: item.user ? item.user.email : '-', // 회원 - 이메일
                coalition_name: item.user ? item.user.coalition_name : '-', // 서비스사
                coalition: getOptionByKey(this.coalitionOption, item.user.coalition_name),
                category_list: item.category.split(','), // 카테고리
                date_format: 'YYYY년 MM월 DD일'
              };
            });
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },
    /** 모달 띄우기 **/
    openModal(record = null) {
      this.modalData = record;
      this.modal = true;
    }
  }
};
</script>
