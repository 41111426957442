<template>
  <div>
    <a-card :title='title' :style="{ marginTop: '20px' }">
      <div slot='extra'></div>

      <div class='margin-bottom-md'>
        <custom-filter :page='page' @search='search' />

        <a-row>
          <a-col :span='12'>
            <a-alert
              :message="'검색결과 : ' + filters.pagination.total + ' 개'"
              type='success'
              showIcon
              :style="{ width: '300px' }"
            />
          </a-col>
          <a-col :span='12' :style="{ textAlign: 'right' }">
            <a-button type='primary' @click='openModal()'>
              등록
            </a-button>
          </a-col>
        </a-row>
      </div>

      <custom-table
        :table-list='data'
        :columns='columns'
        :pagination='filters.pagination'
        :loading='loading'
        :action-list="['detail']"
        @pagination-change='onPaginationChanged'
        @open-modal='openModal'
      />
    </a-card>

    <form-modal
      :modal='modal'
      :data='modalData'
      :mode='mode'
      @index='getCategories'
      @close='modal = false'
    />
  </div>
</template>

<script>
import FormModal from './modals/Form/Index';
import { mapGetters } from 'vuex';
import CustomTable from '@/views/components/CustomTable';
import CustomFilter from '@/views/components/CustomFilter';

export default {
  props: {
    type: String
  },
  components: { FormModal, CustomTable, CustomFilter },
  computed: {
    ...mapGetters({
      isMobileDevice: 'setting/isMobileDevice',
      getFilters: 'filters/getStateFilters'
    }),
    title() {
      return '카테고리 관리';
    },
    filters() {
      return this.getFilters(this.page);
    }
  },
  data() {
    return {
      loading: false,
      modal: false,
      data: [],
      modalData: {},
      mode: '',
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          align: 'center',
          width: 50,
          ellipsis: true
        },
        {
          title: '1차',
          dataIndex: 'depth1',
          key: 'depth1',
          align: 'center',
          width: 250,
          scopedSlots: { customRender: 'depth1' },
          ellipsis: true
        },
        {
          title: '2차',
          dataIndex: 'depth2',
          key: 'depth2',
          align: 'center',
          width: 250,
          scopedSlots: { customRender: 'depth2' },
          ellipsis: true
        },
        {
          title: '3차',
          dataIndex: 'depth3',
          key: 'depth3',
          align: 'center',
          width: 250,
          scopedSlots: { customRender: 'depth3' },
          ellipsis: true
        },
        {
          title: '업종 사용여부',
          dataIndex: 'is_industry',
          key: 'is_industry',
          align: 'center',
          scopedSlots: { customRender: 'use' },
          ellipsis: true
        },
        {
          title: '업종지수 사용여부',
          dataIndex: 'is_indicators',
          key: 'is_indicators',
          align: 'center',
          scopedSlots: { customRender: 'use' },
          ellipsis: true
        },
        {
          title: '사용여부',
          dataIndex: 'is_use',
          key: 'is_use',
          align: 'center',
          scopedSlots: { customRender: 'use' },
          ellipsis: true
        },
        {
          title: '재집계 완료일',
          dataIndex: 'recount_date',
          key: 'recount_date',
          scopedSlots: { customRender: 'created_at' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '등록일',
          dataIndex: 'created_at',
          key: 'created_at',
          align: 'center',
          scopedSlots: { customRender: 'created_at' },
          width: 300,
          ellipsis: true
        },
        {
          title: '기능',
          dataIndex: 'actions',
          key: 'actions',
          align: 'center',
          scopedSlots: { customRender: 'actions' },
          width: 150,
          ellipsis: true
        }
      ],
      page: 'mappingConfig'

    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    /** 페이지 변경시 **/
    onPaginationChanged(pagination) {
      this.filters.pagination.current = pagination ? pagination.current : this.filters.pagination.current;
      this.search();
    },
    /** 검색 **/
    search(filterData) {
      if (filterData && this.filters.pagination.current !== 1) {
        this.filters.pagination.current = 1;
        return;
      }

      this.getCategories();
    },
    getCategories() {
      this.loading = true;

      let params = {
        page: this.filters.pagination.current,
        page_rows: this.filters.pagination.limit,
        name: this.filters.name
      };

      try {
        this.$axios.get('tdi/categories', { params: params }).then(res => {
          let rawData = res.data;

          this.filters.pagination.total = rawData.total;
          this.filters.pagination.current = rawData.current_page;

          this.data = rawData.data.map((item, index) => {
            let depth1 = '-';
            let depth2 = '-';
            let depth3 = '-';

            if (item.depth === 1) {
              depth1 = item.name;
            }

            if (item.depth === 2) {
              depth1 = item.parent.name;
              depth2 = item.name;
            }

            if (item.depth === 3) {
              depth1 = item.parent.name;
              depth2 = item.middle.name;
              depth3 = item.name;
            }

            return {
              key: index.toString(),
              id: item.id,
              parent: item.parent_code,
              middle: item.middle_code,
              name: item.name,
              type: item.depth,
              depth1: depth1,
              depth2: depth2,
              depth3: depth3,
              is_industry: item.is_industry,
              is_indicators: item.is_indicators,
              is_use: item.is_use,
              configs: item.config_ids
                ? JSON.parse(item.config_ids).map(item => {
                  return item;
                })
                : [],
              created_at: item.created_at
            };
          });

          this.loading = false;
        });
      } catch (error) {
        console.log(error);
      }
    },

    openModal(record = null) {
      this.mode = record ? 'edit' : 'store';
      this.modalData = record;
      this.modal = true;
    }
  }
};
</script>
