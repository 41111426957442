<template>
  <a-modal
    v-model='visible'
    :on-cancel='close'
    :title='title'
    :bodyStyle="{ padding: '0px', maxHeight: '500px', minHeight: '200px' }"
    :dialogStyle="{ maxWidth: '450px' }"
  >
    <a-input
      v-model='searchEmail'
      placeholder='이메일'
      class='margin-bottom-sm'
      :style="{
        display: 'inline-block',
        borderRadius:0,
      }"
    />

    <div
      class='padding-x-sm padding-bottom-sm font-bold'
      :style="{
        borderBottom: '1px solid #d9d9d9',
        fontSize: '12.5px',
      }"
    >
      전체 {{ userList.length }}명 ({{ searchListCnt }})
    </div>
    <div class='overflow-auto'>
      <a-list item-layout='horizontal' :data-source='getFilteredUserList()'>
        <a-list-item slot='renderItem' slot-scope='item' class='padding-sm'>
          {{ item }}
        </a-list-item>
      </a-list>
    </div>

    <template slot='footer'>
      <a-row>
        <a-col :span='24' :style="{ textAlign: 'right' }">
          <a-button key='back' @click='close'>닫기</a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'Filters',
  props: {
    modal: Boolean,
    group: String,
    service: String
  },
  watch: {
    modal(v) {
      this.userList = [];
      this.searchEmail = '';
      this.searchListLeng = 0;
      this.visible = v;
      if (v) {
        this.getGroupUsers();
      }
    },
    visible(v) {
      if (!v) {
        this.$emit('close');
      }
    }
  },
  computed: {
    title() {
      return `발송 회원 리스트`;
    }
  },
  data() {
    return {
      visible: false,
      userList: [],
      searchEmail: '',
      searchListCnt: 0,
      loading: false
    };
  },
  methods: {
    getGroupUsers() {
      this.loading = true;
      let params = {
        group: this.group,
        service: this.service
      };

      try {
        this.$axios.get('mailing/group', { params: params }).then(res => {
          let rawData = res.data;
          this.userList = rawData;
          this.loading = false;
        });
      } catch (error) {
        this.sendLoading = false;
      }
    },
    getFilteredUserList() {
      const me = this;
      const res = me.userList.filter((item) => item.includes(me.searchEmail));
      me.searchListCnt = res.length;
      return res;
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
