<template>
  <a-layout class="dd-layout" style="justify-content: center">
    <router-view />
  </a-layout>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
