<template>
  <div>
    <a-card :title='title' class='margin-top-md'>
      <div slot='extra' />

      <div class='row justify-center margin-bottom-md'>
        <div class='col-grow'>
          <a-alert
            :message="'검색결과 : ' + filters.pagination.total + ' 개'"
            type='success'
            showIcon
            :style="{ maxWidth: '300px' }"
          />
        </div>
      </div>

      <custom-table
        :table-list='data'
        :columns='columns'
        :pagination='filters.pagination'
        :loading='loading'
        :action-list="['detail']"
        @pagination-change='onPaginationChanged'
        @open-modal='openModal'
      />
    </a-card>

    <form-modal
      :modal='modal'
      :data='modalData'
      :mode='mode'
      @index='getTrendRequest'
      @close='modal = false'
    />
  </div>
</template>

<script>
import FormModal from './modals/Form/Index';
import { mapGetters } from 'vuex';
import CustomTable from '@/views/components/CustomTable';

export default {
  components: {
    FormModal,
    CustomTable
  },
  computed: {
    ...mapGetters({
      getFilters: 'filters/getStateFilters'
    }),
    title() {
      return '트렌드 요청 목록';
    },
    filters() {
      return this.getFilters(this.page);
    }
  },
  data() {
    return {
      loading: false,
      mode: '',
      modal: false,
      modalData: {},
      data: [],
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          scopedSlots: { customRender: 'id' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '카테고리',
          dataIndex: 'category_info',
          key: 'category_info',
          scopedSlots: { customRender: 'info_obj' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '제목',
          dataIndex: 'title',
          key: 'title',
          scopedSlots: { customRender: 'title' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '회원',
          dataIndex: 'user_email',
          key: 'user_email',
          scopedSlots: { customRender: 'user_email' },
          align: 'center',
          ellipsis: false
        },
        {
          title: '이메일 수신 여부',
          dataIndex: 'notice',
          key: 'notice',
          scopedSlots: { customRender: 'notice' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '요청일',
          dataIndex: 'created_at',
          key: 'created_at',
          scopedSlots: { customRender: 'created_at' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '기능',
          dataIndex: 'actions',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
          align: 'center',
          ellipsis: false
        }
      ],
      page: 'trendRequest',
    };
  },
  mounted() {
    this.getTrendRequest();
  },
  methods: {
    /** 페이지 변경시 **/
    onPaginationChanged(pagination) {
      this.filters.pagination.current = pagination ? pagination.current : this.filters.pagination.current;
      this.search();
    },
    /** 검색 **/
    search(filterData) {
      if (filterData && this.filters.pagination.current !== 1) {
        this.filters.pagination.current = 1;
        return;
      }

      this.getTrendRequest();
    },
    /** 트렌드 요청 목록 조회 **/
    getTrendRequest() {
      this.loading = true;
      // 검색 항목이 추가되면 좋을 것 같은뎀..
      // 카테고리, 제목 혹은 사용자 명으로 검색
      let params = {
        page: this.filters.pagination.current,
        page_rows: this.filters.pagination.limit
      };

      try {
        this.$axios.get('trend/request', { params: params })
          .then((res) => {
            let rawData = res.data;

            this.filters.pagination.total = rawData.total;
            this.filters.pagination.current = rawData.current_page;

            this.data = rawData.data.map((item, index) => {

              return {
                key: index.toString(),
                ...item,
                user_email: item.user ? item.user.email : '-', // 회원 - 이메일
                date_format: 'YYYY년 MM월 DD일',
                category_info: { text: item.category.name }
              };
            });
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },
    /** 모달 띄우기 **/
    openModal(record = null) {
      this.modalData = record;
      this.modal = true;
    }
  }
};
</script>
