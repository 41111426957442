<template>
  <a-table
    :columns='columns'
    :data-source='generateKey(items)'
    :loading='loading'
    bordered
    :scroll='{ x: true }'
    scrollToFirstRowOnChange
    size='small'
    :pagination='false'
    class='margin-top-sm'
  >
      <span slot='date' slot-scope='text, record'>
        {{ `${record.year}년 ${record.month}월` }}
      </span>

    <div slot='amount' slot-scope='text, record' class='text-right margin-right-md'>
      {{ (record.amount * 1).toLocaleString() }} 원
    </div>

    <span slot='change' slot-scope='text, record'>
      <span :style="'color:' + comparedPreviousMonth(record).color">
          <a-icon
            v-if='comparedPreviousMonth(record).icon'
            :color='comparedPreviousMonth(record).color'
            :type='comparedPreviousMonth(record).icon'
          >
          </a-icon>
          {{ comparedPreviousMonth(record).percent }}
      </span>
    </span>
  </a-table>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    items: Array,
    type: String,
    title: String
  },
  computed: {},
  data() {
    return {
      columns: [
        {
          title: '날짜',
          dataIndex: 'date',
          key: 'date',
          scopedSlots: { customRender: 'date' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '매출 합계',
          dataIndex: 'amount',
          key: 'amount',
          scopedSlots: { customRender: 'amount' },
          align: 'center',
          width: 350,
          ellipsis: true
        },
        {
          title: '전월대비(매출)',
          dataIndex: 'change',
          key: 'change',
          scopedSlots: { customRender: 'change' },
          align: 'center',
          ellipsis: true
        }
      ]
    };
  },
  methods: {
    generateKey(listData) {
      return listData.map((item, idx) => {
        return {
          ...item,
          key: idx.toString()
        };
      });
    },
    comparedPreviousMonth(now) {
      let date = now.year + '-' + now.month;
      const subtractDate = this.$moment(new Date(date)).subtract(1, 'months');
      // now 기준 1달전 연도, 월
      let year = subtractDate.format('YYYY');
      let month = subtractDate.format('M');

      let result = {
        color: 'grey',
        percent: '-'
      };
      const subtractItemIdx = this.items.findIndex((item) => `${item.year}` === year && `${item.month}` === month);
      // 1달전 data가 없는 경우는 '-' 로 표시
      if (subtractItemIdx >= 0) {
        result = this.comparedPreviousMonthCalc((now.amount * 1), (this.items[subtractItemIdx].amount * 1));
      }

      return result;
    },
    comparedPreviousMonthCalc(now, prev) {
      let difference = now - prev;
      let sign = difference == 0 ? '' : (difference > 0 ? '+' : '-');

      let result = {
        color: sign == '' ? 'grey' : (sign == '+' ? '#f50' : '#108ee9'),
        icon: sign == '' ? '' : (sign == '+' ? 'caret-up' : 'caret-down'),
        percent: ((Math.abs(difference / prev)) * 100).toFixed(2).toLocaleString() + ' %'
      };

      return result;
    }
  }
};
</script>
