<template>
  <div>
    <a-card :title='title' class='margin-top-md'>
      <div slot='extra' />

      <div class='margin-bottom-md'>
        <custom-filter :page='page' @search='search' />
        
        <a-alert
          :message='`검색결과 : ${filters.pagination.total} 개`'
          type='success'
          showIcon
          :style="{ width: '100%', maxWidth: '300px'}"
        />
      </div>

      <custom-table
        :table-list='data'
        :columns='columns'
        :pagination='filters.pagination'
        :loading='loading'
        :action-list="['detail']"
        @pagination-change='onPaginationChanged'
        @open-modal='openModal'
      />
    </a-card>

    <form-modal
      :modal='isOpenModal'
      :data='modalData'
      @index='getSalesHistory'
      @close='isOpenModal = false'
    />
  </div>
</template>

<script>
import FormModal from './modals/Form/Index';
import { mapGetters } from 'vuex';
import CustomTable from '@/views/components/CustomTable';
import CustomFilter from '@/views/components/CustomFilter';

export default {
  components: { FormModal, CustomTable, CustomFilter },
  computed: {
    ...mapGetters({
      getFilters: 'filters/getStateFilters'
    }),
    title() {
      return '매출 상세내역';
    },
    filters() {
      return this.getFilters(this.page);
    }
  },
  data() {
    return {
      loading: false,
      isOpenModal: false,
      modalData: {},
      data: [],
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          align: 'center',
          ellipsis: true
        },
        {
          title: '주문번호',
          dataIndex: 'order_no',
          key: 'order_no',
          width: 250,
          align: 'center',
          ellipsis: true
        },
        {
          title: '서비스사',
          dataIndex: 'coalition_name',
          key: 'coalition_name',
          scopedSlots: { customRender: 'company' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '결제 상품명',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' },
          width: 200,
          align: 'center',
          ellipsis: true
        },
        {
          title: '결제 회원',
          dataIndex: 'user_email',
          key: 'user_email',
          align: 'center',
          ellipsis: true
        },
        {
          title: '매출액',
          dataIndex: 'price',
          key: 'price',
          scopedSlots: { customRender: 'price' },
          width: 200,
          align: 'center',
          ellipsis: false
        },
        {
          title: '결제일',
          dataIndex: 'payed_date',
          key: 'payed_date',
          width: 200,
          align: 'center',
          ellipsis: true
        },
        {
          title: '기능',
          dataIndex: 'actions',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
          align: 'center',
          ellipsis: false
        }
      ],
      page: 'salesDetail'
    };
  },
  mounted() {
    this.getSalesHistory();
  },
  methods: {
    /** 페이지 변경시 **/
    onPaginationChanged(pagination) {
      this.filters.pagination.current = pagination ? pagination.current : this.filters.pagination.current;
      this.search();
    },
    /** 검색 **/
    search(filterData) {
      if (filterData && this.filters.pagination.current !== 1) {
        this.filters.pagination.current = 1;
        return;
      }

      this.getSalesHistory();
    },
    /** 매출내역 조회 **/
    getSalesHistory() {
      this.loading = true;

      const startDate = this.filters.paymentDate ? this.filters.paymentDate[0] : this.$moment(new Date()).subtract(1, 'year').format('YYYY-MM-DD');
      const endDate = this.filters.paymentDate ? this.filters.paymentDate[1] : this.$moment(new Date()).format('YYYY-MM-DD');

      let params = {
        page: this.filters.pagination.current,
        page_rows: this.filters.pagination.limit,
        payment: {
          startDate: startDate,
          endDate: endDate
        },
        minPrice: this.filters.price ? this.filters.price[0] : '',
        maxPrice: this.filters.price ? this.filters.price[1] : '',
        name: this.filters.name,
        customer: this.filters.customer
      };

      try {
        this.$axios.get('/sales/history', { params: params })
          .then((res) => {
            let rawData = res.data;

            this.filters.pagination.total = rawData.total;
            this.filters.pagination.current = rawData.current_page;

            this.data = rawData.data.map((item, index) => {
              return {
                key: index.toString(),
                ...item,
                price: (item.price * 1).toLocaleString(),
                coalition_name: item.user ? (item.user.coalition_name ?? '') : '',
                user_email: item.user ? item.user.email : '-', // 회원 - 이메일
                payed_date: this.$moment(item.created_at).format('YYYY년 MM월 DD일'),
                created_at: this.$moment(item.created_at).format('YYYY-MM-DD  HH:mm:ss')
              };
            });
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },
    /** 모달 띄우기 **/
    openModal(record = null) {
      this.modalData = record;
      this.isOpenModal = true;
    }
  }
};
</script>
