<template>
  <div>
    <a-card :title='title' class='margin-top-md'>
      <div slot='extra'>
        <a-radio-group
          v-model='type'
          :class="`margin-right-sm ${loading ? 'readonly' : ''}`"
          :disabled='loading'
        >
          <a-radio-button value=''>전체</a-radio-button>
          <a-radio-button value='product'>상품별</a-radio-button>
        </a-radio-group>
      </div>

      <a-row :gutter='{ sm: 8, lg: 15, xl: 30 }'>
        <a-col
          v-for='(item, index) in cardInfoList'
          :key='index'
          :sm='{ span: 12 }'
          :lg='{ span: 6 }'
          class='padding-top-sm'
        >
          <a-card
            class='col'
            :headStyle="{ borderBottomColor: '#f5222d' }"
            :style="{ borderColor: '#f5222d' }"
          >
            <template slot='title'>
              <a-avatar :icon='item.icon' class='margin-right-sm ant-btn-primary' />
              {{ item.title }}
            </template>

            <div class='row justify-center text-right border-top-primary'>
              <div v-if='item.useGraph' class='self-center'>
                <a-icon
                  v-if='item.graphIcon'
                  :type='item.graphIcon'
                  class='self-center'
                  :style="{
                    marginLeft: '-8px',
                    color: item.graphColor
                  }"
                />

                <b :style="{
                  fontSize: '0.8rem',
                  color: item.graphColor
                }"
                >
                  {{ item.graphPercent }} %
                </b>
              </div>

              <b class='col'>{{ item.content }}</b>
            </div>
          </a-card>
        </a-col>
      </a-row>

      <a-tabs
        default-active-key='graph'
        v-model='selectedTab'
        class='margin-y-md'
      >
        <a-tab-pane key='graph' tab='통계 그래프' class='padding-top-md'>
          <div ref='statistics-chart' id='statistics-chart' :style="{ display: `${loading ? 'block' : 'block'}`  }">
            <a-skeleton
              active
              :loading='loading'
              :paragraph="{ width: '100px', rows: 9}"
            />
          </div>
        </a-tab-pane>

        <a-tab-pane key='calendar' tab='매출 달력' class='padding-top-md'>
          <a-skeleton
            active
            :loading='loading'
            :paragraph="{ width: '100px', rows: 16}"
          >
            <calendar :product-list="uniqueByKey(products, 'name')" />
          </a-skeleton>
        </a-tab-pane>
      </a-tabs>

      <a-tabs default-active-key='product-0'>
        <a-tab-pane
          v-for='(product, idx, rowKey) in productData'
          :key='`product-${rowKey}`'
          :tab='idx'
        >
          <SalesTable :items='product' :type='type' :title='`${idx}`' :loading='loading' />
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </div>
</template>

<script>
import Calendar from './Calendar';
import SalesTable from './Table';
import * as echarts from 'echarts';

export default {
  components: { Calendar, SalesTable },
  computed: {
    title() {
      return '매출 통계';
    },
    cardInfoList() {
      let comparedDashboardSign = null;
      if (this.cardListInfo.currentMonthlySalesAmount && this.cardListInfo.prevMonthlySalesAmount) {
        comparedDashboardSign = this.comparedPreviousMonthCalc(this.cardListInfo.currentMonthlySalesAmount, this.cardListInfo.prevMonthlySalesAmount);
      }
      return [
        {
          title: '구독 중',
          content: `${this.cardListInfo.subscriptionCount} 명`,
          icon: 'user'
        },
        {
          title: '상품 수',
          content: `${this.cardListInfo.productsCnt} 개`,
          icon: 'appstore'
        },
        {
          title: '지난달 매출 금액',
          content: `${(this.cardListInfo.prevMonthlySalesAmount).toLocaleString()} 원`,
          icon: 'credit-card'
        },
        {
          title: `${this.cardListInfo.currentMonth}월 매출 금액`,
          content: `${(this.cardListInfo.currentMonthlySalesAmount).toLocaleString()} 원`,
          icon: 'credit-card',
          useGraph: !!comparedDashboardSign,
          graphIcon: comparedDashboardSign ? comparedDashboardSign.icon : '',
          graphColor: comparedDashboardSign ? comparedDashboardSign.color : '',
          graphPercent: comparedDashboardSign ? comparedDashboardSign.percent : ''
        }
      ];
    }
  },
  data() {
    return {
      loading: true,
      selectedTab: 'graph',
      typeOption: [
        { id: '', text: '전체' },
        { id: 'product', text: '상품별' }
      ],
      type: '',
      productTableShow: false,
      // ChartData
      productData: [],
      products: [],
      chart: null,
      chartOption: {},
      showMonth: 6, // 최근 N개월수 표시 
      xAxisData: [], // chart 가로 
      chartLegendData: [],
      cardListInfo: {
        subscriptionCount: 0,
        productsCnt: 0,
        prevMonth: this.$moment(new Date()).subtract(1, 'month').format('M'),
        prevMonthlySalesAmount: '',
        currentMonth: this.$moment(new Date()).format('M'),
        currentMonthlySalesAmount: ''
      }
    };
  },
  watch: {
    selectedTab() {
      this.$root.$emit('get-calendar', { type: this.type });
    },
    type() {
      this.getStatisticsData();
      this.$root.$emit('get-calendar', { type: this.type });
    }
  },
  mounted() {
    this.getStatisticsData();
  },
  methods: {
    initData() {
      this.loading = true;
      this.productData = [];
      this.products = [];
      this.productTableShow = false;
    },
    /** 통계 데이터 조회 **/
    getStatisticsData() {
      this.initData();

      try {
        this.$axios.get('/sales/statistics', { params: { type: this.type } })
          .then((response) => {
            this.productData = response.data.data;
            this.products = response.data.products;

            // 전체일때
            if (!this.type) {
              this.productData = {
                '전체': response.data.data
              };
            }
            // 상단 Card Data
            const cardListInfo = this.cardListInfo;
            this.cardListInfo = {
              ...cardListInfo,
              subscriptionCount: response.data.subscriptionCount,
              currentMonthlySalesAmount: (response.data.monthly['current'] * 1),
              prevMonthlySalesAmount: (response.data.monthly['previous'] * 1),
              productsCnt: Object.keys(this.products).length
            };
            // 하단 Table Data
            this.productTableShow = true;

            this.initChart();
            this.loading = false;
          });

      } catch (error) {
        console.error(error);
      }
    },
    initChart() {
      const chartDom = this.$refs['statistics-chart'];
      // chart 기존 데이터 날림
      echarts.dispose(chartDom);
      // chart 초기화
      this.chart = echarts.init(chartDom);
      // chart 우측 툴팁 기능
      const magicTypeList = ['line', 'bar'];
      // type이 product일 경우
      if (this.type) {
        magicTypeList.push('stack');
      }
      // 차트 옵션 설정
      this.chartOption = {
        title: {
          show: true,
          text: `최근 ${this.showMonth}개월 매출금액`,
          textStyle: { lineHeight: 10, height: 80 }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function(params) {
            const param = params[0];
            const value = (param.value * 1).toLocaleString();

            return `${param.name}<br />
              ${param.marker} ${param.seriesName} <b class='margin-left-sm'>${value} 원</b> `;
          }
        },
        legend: {
          data: []
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            // dataView: { show: true, readOnly: false },
            magicType: { show: true, type: magicTypeList },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: {
          type: 'category',
          axisTick: { show: false },
          data: []
        },
        yAxis: {
          type: 'value'
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 50
          }
        ],
        series: []
        // // 차트 무늬 디자인
        // aria: {
        //   enabled: true,
        //   decal: {
        //     show: true
        //   }
        // }
      };
      this.chartLegendData = this.type !== '' ? this.uniqueByKey(this.products, 'name') : ['전체'];
      // chart 데이터 설정
      this.setChartData();
    },
    /** Object Array 에서 key값을 uniqueArray로 반환 **/
    uniqueByKey(array, key) {
      const res = array.map((x) => x[key]);
      return [...new Set(res)];
    },
    /** chart Series 기본 Object 반환 **/
    getDefaultSeriesObj(settingData, idx) {
      const colorMapp = [
        { bottom: '234, 241, 250', top: '21, 101, 192' },
        { bottom: '255, 255, 204', top: '185, 43, 39' },
        { bottom: '255, 243, 230', top: '178, 10, 44' },
        { bottom: '255, 243, 230', top: '35, 21, 87' },
        { bottom: '255, 243, 230', top: '255, 102, 102' },
        { bottom: '248, 211, 218', top: '177, 42, 91' },
        { bottom: '209, 253, 255', top: '38, 93, 166' },
        { bottom: '254, 249, 215', top: '131, 96, 195' }
      ];

      return new Object({
        name: settingData.name ?? '',
        type: settingData.chartType ?? 'bar', // 'bar', 'line'
        barGap: settingData.barGap ?? 0,
        emphasis: {
          focus: 'series'
        },
        data: settingData.data ?? [],
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: `rgb(${colorMapp[idx].top})`
            },
            {
              offset: 1,
              color: `rgb(${colorMapp[idx].bottom})`
            }
          ]),
          opacity: 0.8

        }
      });
    },
    /** 통계 데이터 조회 **/
    setChartData() {
      // series 세팅
      this.xAxisData = [];
      this.chartOption.series = [];

      this.chartLegendData.forEach((item, idx) => {
        this.chartOption.series[idx] = this.getDefaultSeriesObj({
          name: item,
          chartType: 'bar'
        }, idx);
      });

      let min = 0;

      // 최근 6개월
      for (let i = 0; i < this.showMonth; i++) {
        let productValue = '';
        const subtractDate = this.$moment(new Date()).subtract(i, 'months');
        let year = subtractDate.format('YYYY');
        let month = subtractDate.format('M');

        for (let idx in this.productData) {
          const itemGroup = this.productData[idx];
          const productItem = itemGroup.find((item) => `${item.year}` === year && `${item.month}` === month);

          if (productItem) {
            productValue = productItem.amount;
            if (min > productItem.amount) {
              min = productItem.amount;
            }
            // 전체일때
            const productItemName = !this.type ? '전체' : productItem.name;

            const seriesIdx = this.chartOption.series.findIndex((item) => item.name === productItemName);
            this.chartOption.series[seriesIdx].data.push(productValue);
          }
        }

        this.xAxisData.push(year + '년 ' + month + '월');
      }
      this.chartOption.xAxis.data = this.xAxisData;
      this.chartOption.legend.data = this.chartLegendData;

      if (this.chart) {
        this.chart.setOption(this.chartOption);
      }
    },
    comparedPreviousMonthCalc(now, prev) {
      let difference = now - prev;
      let sign = difference === 0 ? '' : (difference > 0 ? '+' : '-');

      return {
        color: !sign ? 'grey' : (sign === '+' ? '#f50' : '#108ee9'),
        icon: !sign ? '' : (sign === '+' ? 'caret-up' : 'caret-down'),
        percent: ((Math.abs(difference / prev)) * 100).toFixed(2).toLocaleString(),
        sign: sign
      };
    }
  }
};
</script>

<style scoped>
#statistics-chart {
  width: 100%;
  height: 400px;
}

</style>