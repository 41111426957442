<template>
  <a-modal
    v-model='visible'
    :on-cancel='close'
    :width="'80%'"
    :title='title'
    :bodyStyle="{ position: 'relative' }"
    :dialogStyle="{ maxWidth: '1300px' }"
    class='trend-news-modal'
  >
    <a-form-model
      v-if='modal'
      layout='horizontal'
      ref='form'
      :model='form'
      :rules='rules'
      v-bind='formItemLayout'
    >
      <a-descriptions title='기본정보' />

      <div class='row row-inline'>
        <a-form-model-item
          label='사용여부'
          prop='is_delete'
          :colon='false'
          class='margin-right-md'
        >
          <a-radio-group v-model='form.is_delete'>
            <a-radio-button :value='0'> 사용</a-radio-button>
            <a-radio-button :value='1'> 미사용</a-radio-button>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item
          label='구분'
          prop='news_type'
          :colon='false'
          class='margin-x-md'
        >
          <a-radio-group v-model='form.news_type' @change='onNewsTypeChange'>
            <a-radio-button
              v-for='(item, index) in trendTypeOption'
              :key='index'
              :value='item.id'
            >
              {{ item.text }}
            </a-radio-button>
          </a-radio-group>

          <!-- NEWS -->
          <template v-if="form.news_type === 'T'">
            <a-button
              type='primary'
              @click='openModal()'
              class='margin-left-md'
            >
              뉴스 선택
            </a-button>
          </template>
        </a-form-model-item>
      </div>

      <a-form-model-item prop='title' :wrapperCol='{ span: 24 }'>
        <a-input addon-before='제목' v-model='form.title' />
      </a-form-model-item>

      <a-form-model-item prop='sub_title' :wrapperCol='{ span: 24 }'>
        <a-input addon-before='부제목' v-model='form.sub_title' />
      </a-form-model-item>

      <a-descriptions title='코멘트' class='margin-top-md' />

      <a-form-model-item prop='comment' :wrapperCol='{ span: 24 }'>
        <a-textarea v-model='form.comment' :rows='3' />
      </a-form-model-item>

      <a-descriptions>
        <template slot='title'>
          <div class='margin-top-md'>
            내용
            <!-- <a-checkbox
              v-model="form.is_file"
              name="is_file"
              class="margin-left-md"
              @change="onUseFileChange"
            >
              PDF 사용
            </a-checkbox> -->
          </div>
        </template>
      </a-descriptions>

      <a-upload-dragger
        v-if='form.is_file'
        name='file'
        accept='.pdf'
        :beforeUpload='() => { return false;}'
        :fileList='newPdfFile'
        :remove='removeFile'
        @change='uploadFile'
      >
        <p class='ant-upload-drag-icon'>
          <a-icon type='inbox' />
        </p>
        <p class='ant-upload-text'>Click or drag file to this area to upload</p>
      </a-upload-dragger>

      <editor
        v-else
        ref='trendNewsEditor'
        :initialValue='editorText'
        :options='editorOptions'
        height='500px'
        initialEditType='wysiwyg'
        previewStyle='vertical'
      />
    </a-form-model>

    <template slot='footer'>
      <a-row>
        <a-col :span='24' :style="{ textAlign: 'right' }">
          <a-button key='back' @click='close'>닫기</a-button>
          <a-button type='primary' @click='submit()' :loading='loading'>
            {{ modeText }}
          </a-button>
        </a-col>
      </a-row>
    </template>

    <div v-if='loading' class='fit dim'>
      <a-spin />
    </div>

    <newsDialog
      :modal='showNewsModal'
      @set-news-info='setNewsInfo'
      @close='showNewsModal = false'
    />
  </a-modal>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';

import { Editor } from '@toast-ui/vue-editor';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import NewsDialog from './News';

export default {
  props: {
    modal: Boolean,
    mode: String,
    data: Object,
    trendTypeOption: Array
  },
  components: {
    editor: Editor,
    newsDialog: NewsDialog
  },
  data() {
    return {
      loading: false,
      visible: false,
      showNewsModal: false,
      newPdfFile: [],
      orgPdfFile: [],
      form: {
        title: '',
        sub_title: '',
        news_id: '',
        news_type: 'O',
        tag: '',
        comment: '',
        contents: '',
        is_file: 0,
        is_delete: 1,
        created_at: this.$moment().format('YYYY-MM-DD')
      },
      formItemLayout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 10 }
      },
      editorText: '',
      editorOptions: {
        plugins: [colorSyntax],
        hideModeSwitch: true
      },

      rules: {
        title: [
          {
            required: true,
            message: '제목은 필수입니다.',
            trigger: 'change'
          }
        ],
        is_delete: [
          {
            required: true,
            message: '사용여부는 필수입니다.',
            trigger: 'change'
          }
        ],
        news_type: [
          {
            required: true,
            message: '구분은 필수입니다.',
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    async modal(v) {
      this.reset();

      if (this.data) {
        this.form = this._.cloneDeep(this.data);

        if (this.data.file_path) {
          const file = {
            uid: `pdf-file-${this.id}`,
            name: `${this.data.file_name}`,
            status: 'done',
            url: this.data.file_path
          };

          this.orgPdfFile = this.newPdfFile = [file];
        }

        this.form.is_file = this.data.is_file ? true : false;
        this.editorText = this.data.contents;
      }

      this.visible = v;
    },
    visible(v) {
      if (!v) {
        this.$emit('close');
      }
    }
  },
  computed: {
    title() {
      return `트랜드 게시물 ${this.modeText}`;
    },
    modeText() {
      return this.mode === 'edit' ? '변경' : '등록';
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    reset() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }

      this.newPdfFile = this.orgPdfFile = [];
      this.loading = false;
      this.editorText = '';

      this.form = {
        title: '',
        sub_title: '',
        news_id: null,
        news_type: 'O',
        tag: '',
        comment: '',
        contents: '',
        is_file: false,
        file_path: '',
        is_delete: 0
      };
    },
    /** NewsType 변경시  **/
    onNewsTypeChange() {
      if (!this._.isNull(this.form.news_id)) {
        this.form.news_id = null;
      }
    },
    /** PDF파일 사용 변경시  **/
    onUseFileChange({ target }) {
      const val = target.checked;

      if (val) {
        this.editorText = '';
      } else {
        this.newPdfFile = [];
        this.form.file_path = '';

        if (this.$refs.trendNewsEditor) {
          this.$refs.trendNewsEditor.invoke('setMarkdown', this.editorText);
        }
      }
    },
    uploadFile(info) {
      const status = info.file.status;
      if (status !== 'uploading') {
        this.newPdfFile = [info.file];
      }
    },
    removeFile() {
      this.loading = true;

      try {
        this.$axios.delete('trends/news-file/' + this.form.id).then(res => {
          let rawData = res.data;

          let type = rawData.success ? 'success' : 'error';
          let message = rawData.success ? '성공' : '실패';
          let description = rawData.success
            ? '완료되었습니다'
            : '실패하였습니다.';

          this.$notification[type]({
            message: `파일 삭제 ${message}`,
            description: `파일 삭제가 ${description}`
          });

          this.newPdfFile = [];
          this.orgPdfFile = [];
          this.data.file_path = '';
          this.form.file_path = '';

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.'
        });
      }
    },
    setNewsInfo(item) {
      if (!item) return;

      // pdf 사용중인 경우
      if (this.form.is_file) {
        this.form.is_file = false;
      }

      this.form.news_id = item.id;
      this.form.title = item.title;

      this.editorText = item.contents;
      if (this.$refs.trendNewsEditor) {
        this.$refs.trendNewsEditor.invoke('setMarkdown', this.editorText);
      }
    },
    updateNews(formData) {
      try {
        formData.append(`_method`, 'PUT');

        this.$axios
          .post('trends/news/' + this.data.id, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(res => {
            let rawData = res.data;

            const type = rawData.success ? 'success' : 'error';
            const message = `변경 ${rawData.success ? '완료' : '실패'}`;
            const description = res.data.message;

            this.$notification[type]({
              message: message,
              description: description
            });

            if (rawData.success) {
              this.$emit('index');
              this.close();
            }
            this.loading = false;
          });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.'
        });
      }
    },
    storeNews(formData) {
      try {
        this.$axios
          .post('trends/news', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(res => {
            let rawData = res.data;

            let type = 'error';
            let message = '등록 실패';
            let description = '등록이 실패하였습니다.';

            if (rawData.success) {
              type = 'success';
              message = '등록 완료';
              description = '등록이 완료되었습니다.';
            }

            this.$notification[type]({
              message: message,
              description: description
            });

            if (rawData.success) {
              this.$emit('index');
              this.close();
            }
            this.loading = false;
          });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.'
        });
      }
    },
    async submit() {
      const me = this;
      let validate = false;

      await me.$refs['form'].validate(valid => {
        if (!valid) return;
        validate = true;
      });

      if (!validate) return;

      me.loading = true;

      if (!me.form.is_file) {
        this.form.contents = this.$refs.trendNewsEditor.invoke('getHTML');
      }

      let formData = new FormData();

      for (let idx in me.form) {
        let data = this._.isNull(me.form[idx]) ? '' : me.form[idx];

        if (idx === 'file_path') {
          let file = me.newPdfFile.length > 0 ? me.newPdfFile[0] : '';

          if (me.orgPdfFile.length > 0) {
            file = me.newPdfFile[0] === me.orgPdfFile[0] ? '' : file;
            formData.append(
              'org_file_path',
              me.newPdfFile[0] === me.orgPdfFile[0]
                ? file
                : me.orgPdfFile[0].url
            );
          }

          data = file;
        }

        if (idx === 'is_file') {
          data = data ? 1 : 0;
        }

        formData.append(`${idx}`, data);
      }

      this.form.id ? this.updateNews(formData) : this.storeNews(formData);
    },
    openModal() {
      this.showNewsModal = true;
    }
  }
};
</script>
<style>
.row-inline .ant-form-item-label,
.row-inline .ant-form-item-control-wrapper {
  width: auto !important;
}

.trend-news-modal .dim {
  position: absolute;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  top: 0;
  left: 0;
  z-index: 999;
}

.trend-news-modal .dim > * {
  position: absolute;
  top: calc(50% - 25px);
}
</style>
