<template>
  <div>
    <custom-transfer
      :columns="columns"
      :source="filters.source"
      :target="filters.target"
      :loading="loading"
      except
      @transfer="transfer"
      @delete="deleteTarget"
    >
      <template v-slot:search>
        <a-row :gutter="[24, 24]">
          <a-col :span="24">
            <a-input-search
              v-model="searchText"
              addon-before="카테고리명"
              :style="{ margin: '10px 0 0 0' }"
              @pressEnter="getCategories()"
            />
          </a-col>
        </a-row>

        <div :style="{ textAlign: 'center', marginBottom: '20px' }">
          <a-button type="primary" :loading="loading" @click="getCategories()">
            검색
          </a-button>
        </div>
      </template>

      <template v-slot:render> </template>
    </custom-transfer>
  </div>
</template>
<script>
import CustomTransfer from '@/views/components/CustomTransfer';
import _ from 'lodash';

export default {
  props: {
    filters: Object,
    modal: Boolean,
  },
  components: { CustomTransfer },
  data() {
    return {
      loading: false,
      searchText: '',
      columns: [
        {
          dataIndex: 'key',
          title: 'No.',
          align: 'center',
          scopedSlots: { customRender: 'index' },
        },
        {
          dataIndex: 'class_a_name',
          title: 'Class A',
          scopedSlots: { customRender: 'classA' },
        },
        {
          dataIndex: 'class_b_name',
          title: 'Class B',
          scopedSlots: { customRender: 'classB' },
        },
        {
          dataIndex: 'class_c_name',
          title: 'Class C',
          scopedSlots: { customRender: 'classC' },
        },
        {
          dataIndex: 'class_d_name',
          title: 'Class D',
          scopedSlots: { customRender: 'classD' },
        },
      ],
    };
  },
  watch: {
    modal(v) {
      if (v) {
        this.getCategories();
      }
    },
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    transfer(selected) {
      let sourceItems = _.cloneDeep(this.filters.source);
      let targetItems = _.cloneDeep(this.filters.target);
      let diffItems = _.differenceBy(selected, targetItems, 'id');

      selected.forEach(select => {
        let index = _.findIndex(sourceItems, function (o) {
          return o.id == select.id;
        });

        sourceItems[index].disabled = 'Y';
      });

      this.filters.source = sourceItems;

      diffItems.forEach(item => {
        targetItems.push(item);
      });

      this.filters.target = targetItems.map((target, index) => {
        return {
          key: index,
          id: target.id,
          class_a_name: target.class_a_name,
          class_b_name: target.class_b_name,
          class_c_name: target.class_c_name,
          class_d_name: target.class_d_name,
          class_a: target.class_a,
          class_b: target.class_b,
          class_c: target.class_c,
          class_d: target.class_d,
          except: target.except,
        };
      });
    },
    deleteTarget(id) {
      let sourceItems = _.cloneDeep(this.filters.source);

      sourceItems.forEach(soruce => {
        if (soruce.id === id) {
          soruce.disabled = 'N';
        }
      });

      this.filters.source = sourceItems;
      this.filters.target = this.filters.target.filter(item => item.id !== id);
    },
    getCategories() {
      this.loading = true;

      let params = {
        params: {
          name: this.searchText,
        },
      };

      try {
        this.$axios.get('poi/categories', params).then(res => {
          if (res.status === 200) {
            let rawData = res.data.classD;

            this.filters.source = rawData.map((item, index) => {
              let disabled = 'N';

              this.filters.target.forEach(target => {
                if (this.generateId(item) === target.id) {
                  disabled = 'Y';
                }
              });

              let temp = {
                key: index.toString(),
                id: this.generateId(item),
                class_a: item.class_a,
                class_b: null,
                class_c: null,
                class_d: null,
                class_a_name: item.class_a_name,
                class_b_name: null,
                class_c_name: null,
                class_d_name: null,
                except: 'N',
                disabled: disabled,
              };

              if (item.class_b) {
                temp.class_b = item.class_b;
                temp.class_b_name = item.class_b_name;
              }

              if (item.class_c) {
                temp.class_c = item.class_c;
                temp.class_c_name = item.class_c_name;
              }

              if (item.class_d) {
                temp.class_d = item.class_d;
                temp.class_d_name = item.class_d_name;
              }

              return temp;
            });
          }

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
      }
    },
    generateId(item) {
      let id = 'class' + item.class_a;

      if (item.class_b) {
        id += item.class_b;
      }

      if (item.class_c) {
        id += item.class_c;
      }

      if (item.class_d) {
        id += item.class_d;
      }

      return id;
    },
  },
};
</script>
