<template>
  <a-modal v-model="visible" :title="title" :on-cancel="close" :width="'90%'">
    <a-alert
      message="Tmap 데이터를 가져오기 위한 필터를 정의합니다. 카테고리는 1개 이상 필수이며, 지역과 특정 POI 를 포함/제외 시킬 수 있습니다."
      type="info"
      show-icon
    />

    <a-descriptions title="기본 정보" :style="{ marginTop: '2rem' }" />

    <a-form-model ref="form" :model="form" layout="inline" :rules="rules">
      <a-form-model-item label="사용여부" prop="use" :colon="false">
        <a-radio-group v-model="form.use">
          <a-radio-button value="Y"> 사용 </a-radio-button>
          <a-radio-button value="N"> 미사용 </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="설정 이름" prop="name" :colon="false">
        <a-input v-model="form.name" />
      </a-form-model-item>
    </a-form-model>

    <a-descriptions :style="{ marginTop: '2rem' }" title="필터 정의" />

    <a-tabs type="card">
      <a-tab-pane key="1">
        <div slot="tab">
          <a-badge status="error" />
          카테고리
          <a-tag>
            {{ filters.category ? filters.category.target.length : 0 }}
          </a-tag>
        </div>
        <category-class :filters="filters.category" :modal="modal" />
      </a-tab-pane>
      <a-tab-pane key="2" v-if="false">
        <div slot="tab">
          지역
          <a-tag>
            {{ filters.region ? filters.region.target.length : 0 }}
          </a-tag>
        </div>
        <Region :filters="filters.region" :modal="modal" />
      </a-tab-pane>
      <a-tab-pane key="3">
        <div slot="tab">
          POI
          <a-tag>
            {{ filters.poi ? filters.poi.target.length : 0 }}
          </a-tag>
        </div>
        <Poi :filters="filters.poi" :modal="modal" except />
      </a-tab-pane>
    </a-tabs>

    <template slot="footer">
      <a-row>
        <a-col :span="12" :style="{ textAlign: 'left' }">
          <a-popconfirm
            v-if="mode === 'edit'"
            :visible="confirm"
            title="해당 설정을 삭제하시겠습니까 ? 삭제시 복구가 불가능합니다."
            ok-text="삭제"
            cancel-text="취소"
            @confirm="deleteConfig"
            @cancel="confirm = false"
          >
            <a-button
              type="danger"
              @click="confirm = true"
              ghost
              :loading="loading"
            >
              삭제
            </a-button>
          </a-popconfirm>
        </a-col>
        <a-col :span="12" :style="{ textAlign: 'right' }">
          <a-button key="back" @click="close"> 닫기 </a-button>
          <a-button type="primary" @click="submit()" :loading="loading">
            {{ modeText }}
          </a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
import CategoryClass from './Category';
import Region from './Region';
import Poi from './Poi';

export default {
  props: {
    modal: Boolean,
    mode: String,
    data: Object,
  },
  components: { CategoryClass, Region, Poi },
  data() {
    return {
      loading: false,
      visible: false,
      confirm: false,
      form: {},
      filters: {},
      params: {
        depth: '',
        parent_code: '',
        name: '',
      },
      rules: {
        use: [
          {
            required: true,
            trigger: 'change',
          },
        ],
        name: [
          {
            required: true,
            message: '제목은 필수입니다.',
            trigger: 'change',
          },
        ],
      },
    };
  },
  watch: {
    modal(v) {
      this.reset();

      this.visible = v;

      if (this.mode === 'edit') {
        this.form.name = this.data.name;
        this.form.use = this.data.use;
        this.filters.category.target = this.data.categories;
        this.filters.poi.target = this.data.pois;
        this.filters.region.target = this.data.regions;
      }
    },
    visible(v) {
      if (!v) {
        this.$emit('close');
      }
    },
  },

  computed: {
    title() {
      return '검색 그룹 ' + this.modeText;
    },
    modeText() {
      return this.mode === 'edit' ? '변경' : '등록';
    },
  },
  mounted() {},
  methods: {
    close() {
      this.$emit('close');
    },
    reset() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }

      this.confirm = false;

      this.form = {
        name: '',
        use: 'N',
      };

      this.filters = {
        category: {
          source: [],
          target: [],
        },
        region: {
          source: [],
          target: [],
        },
        poi: {
          source: [],
          target: [],
          pagination: {
            current: 1,
            total: 1,
            limit: 30,
          },
        },
      };
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.filters.category.target.length === 0) {
            this.$notification['error']({
              message: '필수값',
              description: '카테고리는 1개 이상 선택해야 합니다.',
            });

            return;
          }

          this.params = {
            name: this.form.name,
            use: this.form.use,
            category: this.filters.category.target,
            region: this.filters.region.target,
            poi: this.filters.poi.target,
          };

          if (this.mode === 'edit') {
            this.updateConfig();
          } else {
            this.setConfig();
          }
        } else {
          return false;
        }
      });
    },
    setConfig() {
      this.loading = true;

      try {
        this.$axios.post('mapping/config', this.params).then(res => {
          if (res.status === 200) {
            if (res.data.success) {
              this.$notification['success']({
                message: '등록 완료',
                description: res.data.message,
              });

              this.$emit('index');
              this.close();
            } else {
              this.$notification['error']({
                message: '등록 실패',
                description: res.data.message,
              });
            }
          }

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.',
        });
      }
    },
    updateConfig() {
      this.loading = true;

      try {
        this.$axios
          .put('mapping/config/' + this.data.id, this.params)
          .then(res => {
            if (res.status === 200) {
              if (res.data.success) {
                this.$notification['success']({
                  message: '변경 완료',
                  description: res.data.message,
                });

                this.$emit('index');
                this.close();
              } else {
                this.$notification['error']({
                  message: '변경 실패',
                  description: res.data.message,
                });
              }
            }

            this.loading = false;
          });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.',
        });
      }
    },
    deleteConfig() {
      this.loading = true;

      try {
        this.$axios.delete('mapping/config/' + this.data.id).then(res => {
          if (res.status === 200) {
            if (res.data.success) {
              this.$notification['success']({
                message: '삭제 완료',
                description: res.data.message,
              });

              this.$emit('index');
              this.close();
            } else {
              this.$notification['error']({
                message: '삭제 실패',
                description: res.data.message,
              });
            }
          }

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.$notification['error']({
          message: '통신 오류',
          description: '다시 시도해주세요.',
        });
      }
    },
  },
};
</script>
